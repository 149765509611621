import { Fragment, useState,useEffect } from "react";
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, ReferenceInput, SelectInput } from 'react-admin';
import dataProvider from '../dataProvider/DataProvider'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import checkAuthentication from './../common/Authenticate'

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.location.trim()) {
        errors.location = 'The CoE Site Location is required';
    }
    if (!values.coeSiteId) {
        errors.coeSiteId = 'The CoE Site is required';
    }
    return errors;
};
export type location = {
    location: string,
    coeSiteId:number
};

const LocationCreate = () => {
    const [show, setStatus] = useState(true);
    const notify = useNotify();
    const navigate = useNavigate();
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);
     
    const postSave = (data: any) => {
        dataProvider.create('addCoeLocation', { data }).then((result: any) => {
            if (result.data.status === 'error') {
               notify(`CoE Site Location ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Created CoE Site Location Successfully`, { type: 'success' });
                navigate("/coeSitesLocation");
           }
        });   
    };
    const CreateToolbar = () => { 
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(!show)
        navigate("/coeSitesLocation");
    }
    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} > 
                
                <DialogTitle>
                    Create New CoE Site Location
                </DialogTitle>

                <DialogContent>
                    <Create>
                        <SimpleForm id="post_create_form" onSubmit={postSave} sx={{ maxWidth: 600 }} toolbar={<CreateToolbar />} validate={validateForm}>
                            <TextInput
                                autoFocus
                                source="location"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                            />     
                            
                            <ReferenceInput label="CoE Site" source="coeSiteId" reference="coeSites">
                                <SelectInput optionText="name" label="CoE Site" sx={{ minWidth: 550 }} emptyText='Please select CoE Site' emptyValue='' defaultValue="" />
                            </ReferenceInput>                           
                        </SimpleForm>
                    </Create>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};
const LocationEdit = (props: any) => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const notify = useNotify();
    const { id } = useParams();
    
    const postUpdate = (data: any) => {
        dataProvider.update('coeSitesLocation', { id: id, data, previousData: {} }).then((result: any) => {  
            if (result.data.status === 'error') {
               notify(`CoE Site Location ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Updated CoE Site Location Successfully`, { type: 'success' });
                navigate("/coeSitesLocation");
           }
        });   
    };

    const UpdateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(!show)
        navigate("/coeSitesLocation");
    }
    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} >

                <DialogTitle>
                    Update CoE Site Location
                </DialogTitle>

                <DialogContent>
                    <Edit title = {"CoE Site Location"}>
                        <SimpleForm onSubmit={postUpdate} id="post_create_form" sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar />} validate={validateForm}>
                             <TextInput
                                autoFocus
                                source="location"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                            />

                            <ReferenceInput label="CoE Site" source="coeSiteId" reference="coeSites">
                                <SelectInput optionText="name" label="CoE Site" sx={{ minWidth: 550 }} emptyText='Select CoE Site' emptyValue="" defaultValue="coeSiteId" />
                            </ReferenceInput>
                        </SimpleForm>
                    </Edit>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};

export {
    LocationCreate,
    LocationEdit
}