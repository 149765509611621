interface IImageResize {
    readonly url: string;
    readonly width: number;
    readonly height: number;
}

const mainService = {
    getDataFromUniformResource: (url: string, apiURL: string) => {
        return new Promise( (resolve, reject) => {
            const postData = {
                "url": url
            };
            fetch(apiURL, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            })
                .then((res) => res.json())
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    resolve(null);
                });
        });
    },
    resizeImage: (param: IImageResize, apiURL: string) => {
        return new Promise( (resolve, reject) => {
            const postData = {
                "url": param.url,
                "width": param.width,
                "height": param.height,
            };
            fetch(apiURL, {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(postData),
            }).then((res) => {
                return res.text();
            }).then((response) => {
                resolve(response);
            }).catch((error) => {
                resolve(null);
            });
        });
    },
    
    
};
export default mainService;