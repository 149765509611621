import { ContentList } from './ContentList';
import { ContentCreate } from './ContentCreate';
import { ContentEdit } from './ContentCreate';

const resource = {
    list: ContentList,
    create: ContentCreate,
    edit: ContentEdit,
};

export default resource;
