
import{useEffect} from "react";
import { List, Datagrid, TextField, SearchInput, Filter, EditButton, CreateButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import './../common/style.css'
import checkAuthentication from './../common/Authenticate';
import { useNavigate } from 'react-router-dom';

const PostFilter = (props: unknown) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

const ListActions = () => (
    <CreateButton label="ADD CoE SITE LOCATION" className='create-button-display'/>
);
const EditActions = () => (
    <EditButton label="" style={{ float: 'right' }}/>
);

const LocationList = (props: any) => {
    const navigate = useNavigate()
       useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);
    return(
        <List
            {...props}
            title={'CoE Site Location'}
            actions={<ListActions />}
            filters={<PostFilter />}
            perPage={50}
            pagination={false}
            sort={{ field: 'location', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="location" label="CoE Site Location" />
                <TextField source="coeSite" label="CoE Site" />
                <EditActions />
                <DeleteActions />
            </Datagrid>
        </List>
    )
};

export {
    LocationList,
}