import { Fragment, useState } from "react";
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, regex } from 'react-admin';
import dataProvider from '../dataProvider/DataProvider';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';
import { useNotify} from 'react-admin';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.name.trim()) {
        errors.name = 'The CoE Site is required';
    }
    if (!validateUrl(values.url.trim())) {
        errors.url = 'Must be a valid URL'
    }
    return errors;
};
const validateUrl = (str: any) => {
        let flag: any;
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    }

const COECreate = () => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const notify = useNotify();
    const postSave= (data: any) => {
        dataProvider.create('coeAdd', { data }).then((result: any) => {
            if (result.data.status === 'error') {
               notify(`CoE Site ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Created CoE Site Successfully`, { type: 'success' });
                 navigate("/coeSites");
           }            
        });
       
    };
    
    const CreateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };
    const handleCloseClick = () => {
        setStatus(false)
        navigate("/coeSites");
    }
   
    return (
        <Fragment>

            <Dialog fullWidth open={show} onClose={handleCloseClick} >
                
                <DialogTitle>
                    Create New CoE Site
                </DialogTitle>

                <DialogContent>

                    <Create>
                        <SimpleForm id="post_create_form" onSubmit={postSave} sx={{ maxWidth: 600 }} toolbar={<CreateToolbar />} validate={validateForm}>
                            <TextInput
                                autoFocus
                                source="name"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="CoE Site"
                            />

                            <TextInput
                                autoFocus
                                source="url"
                                sx={{ minWidth: 550 }}
                            />
                        </SimpleForm>
                    </Create>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};
const CoeEdit = (props: any) => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const { id } = useParams();
    const notify = useNotify();
    const postUpdate = (data: any) => {
        dataProvider.update('coeSites', { id: Number(id), data, previousData: {} }).then((result: any) => {  
            if (result.data.status === 'error') {
               notify(`CoE Site ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Updated CoE Site Successfully`, { type: 'success' });
                navigate("/coeSites");
           }
        });   
    };

    const UpdateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(false)
        navigate("/coeSites");
    }

    return (
        <Fragment>

            <Dialog fullWidth open={show} onClose={handleCloseClick} >

                <DialogTitle>
                    Update CoE Site
                </DialogTitle>
                <DialogContent>
                    <Edit title = {"CoE Site"}>
                        <SimpleForm onSubmit={postUpdate} id="post_create_form" sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar />} validate={validateForm}>
                             <TextInput
                                autoFocus
                                source="name"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="CoE Site"
                            />
                            <TextInput
                                autoFocus
                                source="url"
                                sx={{ minWidth: 550 }}
                            />
                        </SimpleForm>
                    </Edit>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};

export {
    COECreate,
    CoeEdit
}