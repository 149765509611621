import React, { FC, useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import {  useAuthenticated, Confirm,useNotify,Title } from 'react-admin';
import { Grid, Button, Card, CardContent, Box, CircularProgress, TextField, MenuItem, Input, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import permissions from '../services/permissions';
import { required, useRefresh } from 'react-admin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useParams } from "react-router-dom";
import dataProvider from './../dataProvider/DataProvider';
import { useNavigate } from "react-router-dom";
import './../Curation/curation.css';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const moment = require('moment');

const PocnUserCreate: FC = () => {
    useAuthenticated();
    const [show, setStatus] = useState(true);
    const { id } = useParams();
    const refresh = useRefresh();
    const notify = useNotify();
    const [showDialog, setShowDialog] = useState(false);
    const [npi, setNpi] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [leadSource, setLeadSource] = useState('');
    const [graduationYear, setGraduationYear] = useState('');
    const [notes, setNotes] = useState('');
    const [mailId, setMailId] = useState(null);
    const [providertype, setProvidertype] = useState(null);
    const [city, setCity] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [pocnMember, setPocnMember] = useState('yes');
    const [allResult, setAllResult] = useState([]);
    const [specialty, setSpecialty] = useState(null);
    const [specialtyList, setSpecialtyList] = useState([]);
    const [stateList, setStatesList] = useState([]);
    const [state, setStates] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [invalidFirstName, setInvalidFirstName] = useState(false);
    const [invalidLastName, setInvalidLastName] = useState(false);
    const [invalidState, setInvalidState] = useState(false);
    const [invalidProviderType, setInvalidProviderType] = useState(false);
    const [invalidLName, setInvalidLName] = useState(false);
    const [invalidFName, setInvalidFName] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [invalidNpi, setInvalidNpi] = useState(false);
    const [invalidPswd, setInvalidPswd] = useState(false);
    const [invalidGradYear, setInvalidGradYear] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const [hidePswdIcon, setHidePswdIcon] = useState(true);
    const [pswd, setPswd] = useState('');
    const [confirmpswd, setConfirmPswd] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
    const [npiLookupClicked, setNpiClick] = useState(false);

     useEffect(() => {
         getSpecialtyData();
         getStateData();
     }, []);
    
    const getDropList = () => {
        const year = new Date().getFullYear();
        return (
            Array.from( new Array(73), (v,i) =>
            <MenuItem key={i} value={year-i}>{year-i}</MenuItem>
        )
        );
    };
    
    const getSpecialtyData = () => {
        dataProvider.getMany('pocnSpeciality', {ids:[]}).then((result: any) => {
            if (result?.data) {
                setSpecialtyList(result.data);
            } else {
                setSpecialtyList([]);
            }
        })
    }
    const getStateData = () => {
        dataProvider.getMany('states', {ids:[]}).then((result: any) => {
            if (result?.data) {
                setStatesList(result.data);
            } else {
                setStatesList([]);
            }
        })
    }

    const handleCloseClick = () => {
        setStatus(false)
        navigate("/pocnUsers");
    }
    
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleLookupClick = () => {
        
        if (firstName.length === 0) {
            setInvalidFirstName(true);
        } else if (lastName.length === 0) {
            setInvalidLastName(true);
        } else if (state === null) {
            setInvalidState(true);
        }

        setNpiClick(true);
        setShowLoader(true);
            dataProvider.getList('providerInfos', {
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'npi', order: 'ASC' },
                filter: { firstName: firstName, lastName: lastName, state: state, providertype: providertype, city: city },
                // filter: {state: state},
            }).then((result: any) => {

                if (result.data) {
                    setAllResult(result.data);
                }
                setShowLoader(false);
            })
    };
   
    const handleCloseButton = () => {
        setShowDialog(false);
    };
    const handleClose = () => {
        navigate("/pocnUsers/create");
    }
    const handleNameClick = (data: any) => {
        setShowDialog(false);
        setNpi(data.npi);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setMailId(data.mailId);
    }

    const handleSaveNpiClick = () => {
            const data = {
                firstName: firstName,
                lastName: lastName,
                npi: npi,
                emailId: mailId,
                specialty: specialty,
                leadSource: leadSource,
                notes: notes,
                graduationYear: graduationYear,
                password: confirmpswd
            }

        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (pswd !== confirmpswd) {
            setErrorMsg(true);
        }
        if (firstName === "") {
            setInvalidFName(true);
        } else if (lastName === "") {
            setInvalidLName(true);
        } else if (npi === "") {
            setInvalidNpi(true);
        } else if (regexp.test(mailId) === false || mailId === 'undefined' || mailId === null) {
            setInvalidEmail(true);
        } else if (graduationYear === "") {
            setInvalidGradYear(true);
        } else if (pswd === "") {
            setInvalidPswd(true);
        }
            dataProvider.create('pocnSignupUserIam', { data }).then((res: any) => {
                
                if (res.data.status === 'Error') {
                    notify(`User ${res.data.message}`, { type: 'error' });
                } else {
                    notify(`Created POCN Provider Successfully`, { type: 'success' });
                    navigate("/pocnUsers");
                }
            });
        
    }

    const handleSaveClick = () => {

            const data = {
                firstName: firstName,
                lastName: lastName,
                emailId: mailId,
                notes: notes,
                password: confirmpswd
            }

        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (pswd !== confirmpswd) {
            setErrorMsg(true);
        }
        if (firstName === "") {
            setInvalidFName(true);
        } else if (lastName === "") {
            setInvalidLName(true);
        } else if (regexp.test(mailId) === false || mailId === 'undefined' || mailId === null) {
            setInvalidEmail(true);
        } else if (pswd === "") {
            setInvalidPswd(true);
        }
            dataProvider.create('pocnRegisterUser', { data }).then((res: any) => {
                
                if (res.data.status === 'Error') {
                    notify(`User ${res.data.message}`, { type: 'error' });
                } else {
                    notify(`Created POCN Non Provider Successfully`, { type: 'success' });
                    navigate("/pocnUsers");
                }
            });
        
    }
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        if(showPassword==="password")
        {
            setShowPassword("text")
            setHidePswdIcon(false)
            return;
            
        }
        setShowPassword("password")
        setHidePswdIcon(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }

    const onHandleChange = (evt: { target: { value: any; }; }) => {
        setSelectedYear( evt.target.value );
    };
    const year = () => {
        const minOffset = 0;
        const maxOffset = 60;
        const thisYear = (new Date()).getFullYear();
        const options = [];
    
    for (let i = minOffset; i <= maxOffset; i++) {
      const year = thisYear - i;
      options.push(<option value={year}>{year}</option>);
    }
        let yearSelect = '';
        for (let i=1950; i<=2022; i++) {
            yearSelect += '<option val=' + i + '>' + i + '</option>';
        }
    }

    return (
        
        <Card>
            
            <CardContent>
                
            <Box className='header-button'>
                <h3>New User</h3>
            </Box>

            <Box component="div" mb={2}>
                <Grid container >
                <Grid item xs={2} mt={2} ml={5} >
                    <label>Is NPI User</label>
                </Grid>
                <Grid item xs={3}>

                        <TextField
                            id="outlined-select-currency"
                            select
                            value={pocnMember}
                            onChange={(e: any) => setPocnMember(e.target.value)}
                            fullWidth
                        >
                            <MenuItem key="status-yes" value="yes">Yes</MenuItem>
                            <MenuItem key="status-no" value="no">No</MenuItem>

                        </TextField>
                        
                </Grid>
                </Grid>
            </Box>

                    <Box component="div">
                        <Grid container >

                            <Grid item xs={2} mt={2} ml={5}>
                                <label>Name</label><label className="pocn-required">*</label>
                            </Grid>
                            <Grid item xs={9}>
                                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextField
                                            id="firstName"
                                            variant='outlined'
                                            fullWidth
                                            label="First Name"
                                            value={firstName}
                                            onChange={(e: any) => {
                                                setFirstName(e.target.value);
                                                if (e.target.value.trim().length !== 0) {
                                                    setInvalidFName(false);
                                                }
                                            }}
                                    />
                                    {invalidFName ? <span className="coe-validation-error">First Name cannot be empty</span> : null}
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                        <TextField
                                            id="lastName"
                                            variant='outlined'
                                            fullWidth
                                            label="Last Name"
                                            value={lastName}
                                            onChange={(e: any) => {
                                                setLastName(e.target.value);
                                                if (e.target.value.trim().length !== 0) {
                                                    setInvalidLName(false);
                                                }
                                            }}
                                        />
                                     {invalidLName ? <span className="coe-validation-error">Last Name cannot be empty</span> : null}
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                {(pocnMember === permissions.SELECTED_VALUE) ? (
                    <Box component="div" mt={2}>
                        <Grid container >
                            <Grid item xs={2} mt={2} ml={5}>
                                <label>NPI#</label><label className="pocn-required">*</label>
                            </Grid>
                            <Grid item xs={9}>
                                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                        <TextField
                                            id="npi"
                                            variant='outlined'
                                            fullWidth
                                            label="NPI Number"
                                            value={npi}
                                            onChange={(e: any) => {
                                                setNpi(e.target.value);
                                                if (e.target.value.trim().length !== 0) {
                                                    setInvalidNpi(false);
                                                }
                                            }}
                                        />
                                        {invalidNpi ? <span className="coe-validation-error">NPI Number cannot be empty</span> : null}
                                    </Box>
                                    <Box flex={1} ml={{ xs: 0, sm: '4em' }} mt={1}>
                                        <Button variant="contained" sx={{ minWidth: 280 }} onClick={handleClick}>Look up your NPI# here</Button>
                                    
                                        <Dialog fullWidth open={showDialog} onClose={handleClose} >
                                            <DialogTitle>
                                                <Grid container >
                                                    <Grid item xs={10} mt={0} ml={4} mr={1} >
                                                        <Typography variant="h4" align="center">Enter details and click look up</Typography>
                                                    </Grid>
                                                    <Grid item xs={1} mt={0}><Button onClick={handleCloseButton}><CancelIcon className="svg_icons-cancel" /></Button></Grid>
                                                    
                                                </Grid>
                                                
                                            </DialogTitle>
                                            <DialogContent>
                                                <Grid container >
                                                    <Grid item xs={8} mt={1}>
                                                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                                                <TextField
                                                                    id="firstName"
                                                                    variant='outlined'
                                                                    fullWidth
                                                                    required
                                                                    label="First Name"
                                                                    value={firstName}
                                                                    onChange={(e: any) => {
                                                                        setFirstName(e.target.value);
                                                                        if (e.target.value.trim().length !== 0) {
                                                                            setInvalidFirstName(false);
                                                                        }
                                                                    }}
                                                                    sx={{ minWidth: 260 }}
                                                                />
                                                                {invalidFirstName ? <span className="coe-validation-error">First Name cannot be empty</span> : null}
                                                              
                                                            </Box>
                                                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                                               
                                                                <TextField
                                                                    id="lastName"
                                                                    variant='outlined'
                                                                    fullWidth
                                                                    required
                                                                    label="Last Name"
                                                                    value={lastName}
                                                                    onChange={(e: any) => {
                                                                        setLastName(e.target.value);
                                                                        if (e.target.value.trim().length !== 0) {
                                                                            setInvalidLastName(false);
                                                                        }
                                                                    }}
                                                                    sx={{ minWidth: 260 }}
                                                                />
                                                                {invalidLastName ? <span className="coe-validation-error">Last Name cannot be empty</span> : null}
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            <Box mt={2}>
                                                <TextField
                                                    id="city"
                                                    variant='outlined'
                                                    label="City"
                                                    value={city}
                                                    onChange={(e: any) => setCity(e.target.value)}
                                                    sx={{ minWidth: 540 }}
                                                />
                                            </Box>

                                            <Box mt={2}>
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    required
                                                    label="State"
                                                    value={state}
                                                    onChange={(e: any) => { setStates(e.target.value);  setInvalidState(false);}}
                                                    sx={{ minWidth: 540 }}
                                                >
                                                    {
                                                        stateList.map((element: any) => (
                                                            <MenuItem key={element.id} value={element.statevalue}>{element.statename}</MenuItem>))
                                                    }
                                                    </TextField>
                                                    {invalidState? <span className="coe-validation-error">State cannot be empty</span> : null}
                                            </Box>
                                                
                                            <Box mt={2}>
                                                <TextField
                                                    id="outlined-select-currency"
                                                    select
                                                    label={"Provider Type"}
                                                    value={providertype}
                                                        onChange={(e: any) => { setProvidertype(e.target.value); setInvalidProviderType(false); }}
                                                    sx={{ minWidth: 540 }}
                                                >
                                                    <MenuItem key="status-np" value="NP/CNS">NP</MenuItem>
                                                    <MenuItem key="status-pa" value="PA">PA</MenuItem>
                                                    <MenuItem key="status-md" value="MD">MD</MenuItem>
                                                    <MenuItem key="status-do" value="DO">DO</MenuItem>
                                                    <MenuItem key="status-others" value="Others">Others</MenuItem>

                                                    </TextField>
                                                    {invalidProviderType? <span className="coe-validation-error">Provider Type cannot be empty</span> : null}
                                            </Box>

                                                <DialogActions>
                                                    <Button variant="contained" sx={{ minWidth: 280 }} onClick={handleLookupClick}>Lookup NPI#</Button>
                                                   
                                                </DialogActions>
                                                
                                                {showLoader ? (
                                                    <Box component="div" className="press-release-loader">
                        <CircularProgress />
                    </Box> ):
                                               ( (allResult.length !== 0) ? 
                                                (<span>
                                                    <h3>Click on the name to select</h3>
                                                    <ul className="list-group">
                                                                {allResult.map(allResult => <li className="list-group-item" onClick={() => handleNameClick(allResult)}>
                                                                    {allResult.firstName}
                                                                    <span className="list-separation">&nbsp;|&nbsp;</span>
                                                                    {allResult.lastName} &nbsp; {allResult.primarySpecialityGroupCode}</li>)}
                                                             </ul>
                                                </span>)
                                                        : (npiLookupClicked === false)? null : "no results found" )
                                                }  
                                            </DialogContent>
                                        </Dialog>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                ) : null}
            
            <Box component="div" ml={1} mt={2}>
                <Grid container >
                <Grid item xs={2} mt={2} ml={4}>
                    <label>Email</label><label className="pocn-required">*</label>
                </Grid>
                <Grid item xs={9}>
                    <TextField
                        id="email"
                        variant='outlined'
                        fullWidth
                        label="Email Id"
                        value={mailId}
                        onChange={(e: any) => {
                            setMailId(e.target.value);
                            if (e.target.value.trim !== null) {
                                setInvalidEmail(false);
                            }
                        }}
                    />
                    {invalidEmail ? <span className="coe-validation-error">Please enter valid email address</span> : null}
                </Grid>
                </Grid>
                </Box>

                <Box component="div" mt={2}>
                    <Grid container >
                        <Grid item xs={2} mt={2} ml={5}>
                                <label>Password</label><label className="pocn-required">*</label>
                            </Grid>
                            <Grid item xs={9}>
                                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <FormControl sx={{ width: '38ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        fullWidth
                                        type={showPassword}
                                        value={pswd}
                                            onChange={(e: any) => { setPswd(e.target.value); setInvalidPswd(false); }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                            {hidePswdIcon ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    </FormControl>   
                                {invalidPswd? <span className="coe-validation-error">Password cannot be empty</span> : null}    
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>

                                <FormControl sx={{ width: '37.5ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        fullWidth
                                        type={showPassword}
                                        value={confirmpswd}
                                            onChange={(e: any) => { setConfirmPswd(e.target.value); setErrorMsg(false);}}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                            {hidePswdIcon ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />
                                </FormControl>
                                    {errorMsg? <Box component="span" mt={1} className="error-message">The passwords doesnt match</Box> : null}
                            </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                
                {(pocnMember === permissions.SELECTED_VALUE) ? (
                    <Box component="div" ml={1} mt={2}>
                        <Grid container >
                            <Grid item xs={2} mt={2} ml={4}>
                                <label>Graduation Year</label><label className="pocn-required">*</label>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    id="outlined-select-graduationYear"
                                    select
                                    fullWidth
                                    label={"Graduation Year"}
                                    value={graduationYear}
                                    onChange={(e: any) => {
                                                setGraduationYear(e.target.value);
                                                if (e.target.value.trim !== "") {
                                                    setInvalidGradYear(false);
                                                }
                                            }}
                                >
                                     {getDropList()}
                                  
                                </TextField>
                                {invalidGradYear ? <span className="coe-validation-error">Graduation Year cannot be empty</span> : null}
                            </Grid>
                        </Grid>
                    </Box>) : null}
                
                {(pocnMember === permissions.SELECTED_VALUE) ? (
                    <Box component="div" ml={1} mt={2}>
                        <Grid container >
                            <Grid item xs={2} mt={2} ml={4}>
                                <label>Lead Source</label>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    fullWidth
                                    label={"Lead Source"}
                                    value={leadSource}
                                    onChange={(e: any) => setLeadSource(e.target.value)}
                                >
                                    <MenuItem key="status-socialmedia" value="socialmedia">Social Media</MenuItem>
                                    <MenuItem key="status-news" value="news">News</MenuItem>
                                    <MenuItem key="status-friends" value="friends">Friends</MenuItem>
                                    <MenuItem key="status-others" value="others">Others</MenuItem>

                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>) : null}

                <Box component="div" ml={1} mt={2}>
                    <Grid container >
                            <Grid item xs={2} mt={1} ml={4} >
                                <label>Notes</label>
                            </Grid>
                            <Grid item xs={9}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={notes}
                                    onChange={(event, editor) => {
                                        let data = editor.getData();
                                        data = data.trim();
                                        setNotes(data)
                                    }}

                                />
                            </Grid>
                        </Grid>
                </Box>
                {(pocnMember === permissions.SELECTED_VALUE) ? (
                    <Box>
                        <Grid container>
                            <Grid item xs={2} mt={2} ml={4}>
                                <Button variant="contained" color="primary" size="large" className="curate-update-right" onClick={handleSaveNpiClick}>Save</Button>
                            </Grid>
                        </Grid>
                    </Box>
                ) : (<Box>
                        <Grid container>
                            <Grid item xs={2} mt={2} ml={4}>
                                <Button variant="contained" color="primary" size="large" className="curate-update-right" onClick={handleSaveClick}>Save</Button>
                            </Grid>
                        </Grid>
                    </Box>)}
            </CardContent>
        </Card>
    );
}
export default PocnUserCreate;