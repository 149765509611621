import{useEffect} from "react";
import { List, Datagrid, TextField, EmailField, SearchInput, Filter, CreateButton, FunctionField, EditButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import checkAuthentication from './../common/Authenticate';
import { useNavigate } from 'react-router-dom';

const PostFilter = (props: JSX.IntrinsicAttributes) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);

const ListActions = () => (
    <CreateButton label="Add user"/>
);
const EditActions = () => (
    <EditButton label="" style={{ float: 'right' }}/>
);

const LinkedField = (record: { label: string; }) => {

    return (
        <div> 
            <FunctionField render={(record: { status: boolean; }) => `${record.status === true ? "Active" : "Inactive"}`} /></div>
    )
}
const UserList = (props: any) => {
    const navigate = useNavigate()
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);
    return (
        <List
            {...props}
            actions={<ListActions />}
            title={'Users'}
            filters={<PostFilter />}
            perPage={50}
            pagination={false}
            sort={{ field: 'type', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false}>
    
                <TextField source="firstName" />
                <TextField source="lastName" />
                <EmailField source="emailId" />
                <TextField source="type" label="User Type" />
                {/* <TextField source="coeSites" label="CoE Site" /> */}
                <LinkedField label="Status" />
                <EditActions />
                <DeleteActions />
            </Datagrid>
        </List>
    )
};



export {
    UserList,
}