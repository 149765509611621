import{useEffect} from "react";
import { List, Datagrid, TextField, EmailField, SearchInput, Filter, CreateButton, FunctionField, EditButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import checkAuthentication from './../common/Authenticate';
import { useNavigate } from 'react-router-dom';
import CustomDateField from '../common/CustomDateField';
import UserDetails from './UserDetails';
import Button from '@mui/material/Button';


const PostFilter = (props: JSX.IntrinsicAttributes) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);

const ListActions = () => {
    const navigate = useNavigate();
    return(
    <Button variant="contained"  onClick={() => { navigate('/pocnUsers/create'); }} sx={{  mb: '10px'}}>
        New User
    </Button>
    )
}
const EditActions = () => (
    <EditButton label="" style={{ float: 'right' }}/>
);

const LinkedField = (record: { label: string; }) => {

    return (
        <div> 
            <FunctionField render={(record: { status: boolean; }) => `${record.status === true ? "Active" : "Inactive"}`} /></div>
    )
}
const PocnUserList = (props: any) => {
    // const navigate = useNavigate()
    // useEffect(() => {        
    //     const auth = checkAuthentication();
    //     if (auth === false) {
    //         navigate("/")
    //     }
    // },[]);
    return (
        <List
            {...props}
            actions={<ListActions />}
            title={'POCN Users'}
            filters={<PostFilter />}
            perPage={50}
            pagination={false}
            sort={{ field: 'createdAt', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false} rowClick="edit">
    
                <TextField source="firstName" label="First Name" />
                <TextField source="lastName" label="Last Name"/>
                <TextField source="providerId" label="Provider Id" />
                <TextField source="npi" label="NPI Number" />
                <TextField source="userId" label="POCN User Id" />
                {/* <TextField source="userType" label="Type" /> */}
                <EmailField source="emailId" />
                {/* <TextField source="type" label="Type" /> */}
                
                <TextField source="primarySpecialtyGroup" label="Primary Specialty" />
                <TextField source="engagementScore" label="POCN Engagement Score" />
                <CustomDateField source="engagementDate" label="Last Engagement Date" sortBy='engagementDate' />
                <TextField source="connections" label="#Connections" />
                <TextField source="Videos" label="#Videos" />
                <TextField source="totalEngagements" label="Total Engagements" />
                <TextField source="keyEngagements" label="#Key Engagements" />
                <CustomDateField source="createdAt" label="Registered Date" sortBy='createdAt' />

            </Datagrid>
        </List>
    )
};



export {
    PocnUserList,
}