import{useEffect} from "react";
import { List, Datagrid, TextField, EmailField, SearchInput, Filter, CreateButton, FunctionField, EditButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import checkAuthentication from './../common/Authenticate';
import { useNavigate } from 'react-router-dom';
import CustomDateField from '../common/CustomDateField';
import Button from '@mui/material/Button';


const PostFilter = (props: JSX.IntrinsicAttributes) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);

const ListActions = () => {
    const navigate = useNavigate();
    return(
    <Button variant="contained"  onClick={() => { navigate('/pocnUsers/create'); }} sx={{  mb: '10px'}}>
        New User
    </Button>
    )
}

const PocnUserList = (props: any) => {
    // const navigate = useNavigate()
    // useEffect(() => {        
    //     const auth = checkAuthentication();
    //     if (auth === false) {
    //         navigate("/")
    //     }
    // },[]);
    return (
        <List
            {...props}
            actions={<ListActions />}
            title={'POCN Users'}
            filters={<PostFilter />}
            perPage={50}
            pagination={false}
            sort={{ field: 'npi', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false} rowClick="edit">
    
                <TextField source="firstName" label="First Name" />
                <TextField source="lastName" label="Last Name"/>
                {/* <TextField source="providerId" label="Provider Id" />
                <TextField source="npi" label="NPI Number" />
                <TextField source="userId" label="POCN User Id" /> */}
                <EmailField source="emailId" />
                {/* <TextField source="type" label="Type" /> */}
                <CustomDateField source="createdAt" label="Registered Date" sortBy='createdAt' />

            </Datagrid>
        </List>
    )
};



export {
    PocnUserList,
}