import * as React from "react";
import {  useState, useEffect } from 'react';
import dataProvider from './../dataProvider/DataProvider';
import ViewListIcon from '@mui/icons-material/ViewList';
import './../common/style.css'
import { Card, CardContent, CircularProgress, Table, TableHead, TableRow, TableBody, TableCell, Box } from '@mui/material';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
const moment = require('moment');

const Dashboard = (props: any) => {

    const [content, setContent] = useState([]);
    const [curatedCount, setCuratedCount] = useState("");
    const [rejectedCount, setRejectedCount] = useState("");
    const [publishCount, setPublishCount] = useState("");
    const [showLoader, setShowLoader] = useState(true);

    useEffect(() => {
        getLatestCuratedArticles();
        const type = 'weekly';
        const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
        const endDate = moment().format('YYYY-MM-DD');
        getCuratedCounts(startDate, endDate, type);
        getPublishedCounts(startDate, endDate, type);
        const status = 'rejected';
        getRejectedCounts(startDate, endDate, status);
    }, []);

    const getCuratedCounts = (startDate: string, endDate: string, type: string) => {
        const ListData = dataProvider.getList('viewCuration', {
            pagination: {
                page: 1, perPage: 5,
            },
            sort: { field: 'createdAt', order: 'DESC' },
            filter: {  startDate: startDate, endDate: endDate },
            
        }).then((result: any) => {
            if (result?.data) {
                setCuratedCount(result.total);
                setShowLoader(false);
            } 
        })
    }
const getLatestCuratedArticles = () => {
        const ListData = dataProvider.getList('viewCuration', {
            pagination: {
                page: 1, perPage: 5,
            },
            sort: { field: 'createdAt', order: 'DESC' },
            filter: { },
            
        }).then((result: any) => {
            if (result?.data) {
                setContent(result.data);
                setShowLoader(false);
            } else {
                setContent([]);
            }
        })
    }

    const getRejectedCounts = (startDate: string, endDate: string, status: string) => {
        const ListData = dataProvider.getList('viewCuration', {
            pagination: {
                page: 1, perPage: 10,
            },
            sort: { field: 'createdAt', order: 'DESC' },
            filter: {  startDate: startDate, endDate: endDate, status: status },
            
        }).then((result: any) => {
            if (result?.data) {
                setRejectedCount(result.total);
                setShowLoader(false);
            } else {
                setContent([]);
            }
        })
    }

    const getPublishedCounts = (startDate: string, endDate: string, type: string) => {
        const ListData = dataProvider.getList('publishedCurations', {
            pagination: {
                page: 1, perPage: 5,
            },
            sort: { field: 'publishedAt', order: 'DESC' },
            filter: {  startDate: startDate, endDate: endDate},
            
        }).then((result: any) => {
            if (result?.data) {
                setPublishCount(result.total);
                setShowLoader(false);
            } 
        })
    }

    return (
        
        <Card>
            <CardContent>
                <Box mb={2}>
                    {/* <Card sx={{ padding: '10px' }}> */}
                        <h3 className="card-title">Latest Curated Articles</h3>
                        <Table sx={{ padding: 2 }} size="small">
                            <TableHead className="header-curation">
                                <TableRow>
                                    <TableCell><b>URL</b></TableCell>
                                    <TableCell><b>CoE Site</b></TableCell>
                                </TableRow>
                            </TableHead>
                            { showLoader ? <Box component="div" className="press-release-loader">
                                <CircularProgress />
                            </Box> :
                            <TableBody>
                                {content.map(e => (
                                <TableRow key={e.id}>
                                    <TableCell>{e.contentUrl}</TableCell>
                                    <TableCell>{e.coeSiteName}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>
                            }
                        </Table>
                    {/* </Card> */}
                </Box>

                <Box>
                    <Box sx={{ display: 'flex' }}>
                        <Card sx={{ padding: '15px', mr: '6px', mt: '10px' }} className="curation-list-flex" >
                            
                            <Box className="svg_icons-curate-count"><ListAltOutlinedIcon className="article-count-icon"/></Box>
                            <Box className="article-count-title">Last Week Curated Article Count</Box>
                            { showLoader ? <Box component="div" className="press-release-loader">
                                <CircularProgress />
                            </Box> :
                                <Box className="article-count">{curatedCount}</Box>
                            }
                        </Card>

                        <Card sx={{ padding: '15px' }} className="curation-list-flex"  >
                            <Box className="svg_icons-curate-count icon-bg2"><BallotOutlinedIcon className="article-count-icon"/></Box>
                            <Box className="article-count-title">Last Week Published Article Count</Box>
                            { showLoader ? <Box component="div" className="press-release-loader">
                                <CircularProgress />
                            </Box> :
                                <Box className="article-count">{publishCount}</Box>
                            }
                        </Card>

                        <Card sx={{ padding: '15px', ml: '6px'}} className="curation-list-flex"  >
                            <Box className="svg_icons-curate-count icon-bg3"><FactCheckOutlinedIcon className="article-count-icon"/></Box>
                            <Box className="article-count-title">Last Week Rejected Article Count</Box>
                            { showLoader ? <Box component="div" className="press-release-loader">
                                <CircularProgress />
                            </Box> :
                                <Box className="article-count">{rejectedCount}</Box>
                            }
                        </Card>
                    </Box>
               
                </Box>
            </CardContent>
       </Card>
    );
}
export {
    Dashboard
}