import UserIcon from '@mui/icons-material/People';
import { UserEdit } from './UserCreate'
import { UserList } from './UserList';
import { UserCreate } from './UserCreate';

const resource = {
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
    icon: UserIcon,
};

export default resource;
