import React, { FC, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {  useAuthenticated, Confirm,useNotify,Title } from 'react-admin';
import { Grid, Button, Card, CardContent, Box, CircularProgress, TextField, MenuItem, Input } from '@mui/material';
import Switch from '@mui/material/Switch';
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, ReferenceInput, SelectInput, Form } from 'react-admin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
    useParams,
} from "react-router-dom";
import dataProvider from './../dataProvider/DataProvider';
import { useNavigate } from "react-router-dom";
import './../Curation/curation.css';
import { useRecordContext } from 'react-admin';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-react';

const moment = require('moment');

const PocnVideoCreate: FC = () => {
    useAuthenticated();
    const { id } = useParams();
    const [showLoader, setShowLoader] = useState(true);
    const [npi, setNpi] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [specialty, setSpecialty] = useState(null);
    const [specialtyList, setSpecialtyList] = useState([]);
    const [therapeuticArea, setTherapeuticArea] = useState(null);
    const [therapeuticAreaList, setTherapeuticAreaList] = useState([]);
    const [birth, setBirth] = useState('');
    const [checked, setChecked] = React.useState(true);
    const [commentEnabled, setCommentEnabled] = React.useState(false);
    const [likeEnabled, setLikeEnabled] = React.useState(false);
    const [shareEnabled, setShareEnabled] = React.useState(false);
    const [downloadEnabled, setDownloadEnabled] = React.useState(false);
    const [subcategory, setSubCategory] = useState([]);
    const [emailId, setEmailId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [createdBy, setCreatedBy] = useState('');
    const [prefix, setPrefix] = useState(null);
    const [suffix, setSuffix] = useState(null);
    const [state, setState] = useState('');
    const [member, setMember] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [workPhone, setWorkPhone] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [twitterProfile, setTwitterProfile] = useState('');
    const [linkedInProfile, setLinkedInProfile] = useState('');
    const [fbProfile, setFbProfile] = useState('');
    const [value, setValue] = React.useState('profile');
    const [tabName, setTabName] = React.useState('profileinfo');
    const [articleImage, setArticleImage] = useState(null);
    const [customImage, setCustomImage] = useState(null);
    const [openArticleDelete, setArticleDeleteOpen] = useState(false);
    const [openCustomDelete, setCustomDeleteOpen] = useState(false);
    const [imageType,setImageType]= useState('');
    const [curationUpdateState, setUpdateCurationState] = useState({
        showLoader: false,
        curatableDescription: '',
        curatableTitle:'',
        cleanUrl: '',
        title:'',
        description: '',
        metaData:null,
        ogImage: null,
        images:[],
        showAlert: true,
        showError: true,
        notificationMessage: '',
        readableTitle: '',
        readableDescription: '',
        mozillaReadableDescription: '',
        enableReadable: true,
        isArticleImage: false,
        imgUrl: '',
        articleImage:null,
        customImage: null,
        isCustomImage:false,
        invalidCustomFile: false,
        invalidCustomFileMessage: '',
        articleImageId: 0,
        customImageId: 0,
        statusList:[]
    });

     useEffect(() => {
         getUserData();
         getSpecialtyData();
         getTherapeuticAreaData();
     }, []);
    
    const getSpecialtyData = () => {
        dataProvider.getMany('speciality', {ids:[]}).then((result: any) => {
            if (result?.data) {
                setSpecialtyList(result.data);
            } else {
                setSpecialtyList([]);
            }
        })
    }
    const getTherapeuticAreaData = () => {
        dataProvider.getMany('therapeutic_area', {ids:[]}).then((result: any) => {
            if (result?.data) {
                setTherapeuticAreaList(result.data);
            } else {
                setTherapeuticAreaList([]);
            }
        })
    }
    
    const getUserData = () => {
        dataProvider.getOne('pocnUsers', { id: id }).then((result: any) => {
            if (result.data) {
                
                setNpi(result.data.npi);
                setFirstName(result.data.firstName);
                setLastName(result.data.lastName);
                setMiddleName(result.data.middleName);
                setEmailId(result.data.emailId);
                setProviderId(result.data.providerId);
                setCreatedAt(result.data.createdAt);               
                setCreatedBy(result.data.createdBy);
              
            }
            setShowLoader(false);
            console.log(result.data);
        })
    }
    const VideoField = () => {
        const record = useRecordContext();
        return <video
            // src={`${record.video}`} 
            src="https://jsoncompare.org/LearningContainer/SampleFiles/Video/MP4/Sample-MP4-Video-File-for-Testing.mp4"
            controls width="900" height="240"></video>;
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };
    const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCommentEnabled(event.target.checked)
    };
    const handleLike = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLikeEnabled(event.target.checked)
    };
    const handleShare = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShareEnabled(event.target.checked)
    };
    const handleDownload = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDownloadEnabled(event.target.checked)
    };


    const navigate = useNavigate();
    const handleCloseClick = () => {
        navigate(`/pocnVideos_all`);
    }
    const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleCustomImages = async (event: any) => {
        const files = event.target.files;
        const fileInfo: { fileName: any; base64Data: any; }[] = [];
        const allowedTypes = ['.jpeg', '.jpg', '.png'];
        let flag = true;
        for (let i = 0; i < files.length; i++) {
            if (flag) {
                const fileName = files[i].name;
                const fileExtension = '.' + fileName.substr(fileName.lastIndexOf('\\') + 1).split('.')[1];
                if (allowedTypes.includes(fileExtension.toLowerCase())) {
                    const base64Data = await toBase64(files[i]);
                    setCustomImage(base64Data);
                    setUpdateCurationState((curationUpdateState) => ({
                        ...curationUpdateState,
                        customImage: base64Data,
                        isCustomImage:true
                    }));
                } else {
                    flag = false;
                }
            }
        }
        if (flag === false) {
            setUpdateCurationState((curationUpdateState) => ({
            ...curationUpdateState,
            invalidCustomFile: true,
            invalidCustomFileMessage:"Only [.jpeg,.jpg,.png] files are allowed",
        }));
        }
    }
    const deleteImage = () => {
        setArticleDeleteOpen(false);
        setCustomDeleteOpen(false);
        let imgId = 0;
        if (imageType === 'article') {
           imgId= curationUpdateState.articleImageId
        } else {
            imgId= curationUpdateState.customImageId
        }
        dataProvider.delete('deleteCurationImage', { id: imgId }).then((result:any) => {
            if (imageType === 'article') {
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                    isArticleImage: false,
                    articleImage:null
                }));
            } else {
            setUpdateCurationState((curationUpdateState) => ({
                ...curationUpdateState,
                isCustomImage: false,
                customImage:null
                 }));
                
            }
        });
       
 
    }
    const handleDeleteImageClose = () => {
        setArticleDeleteOpen(false); 
        setCustomDeleteOpen(false); 
    } 

    return (
        
        <Card>
            <Box ml={2} component="div">
                <h3>Video Details</h3>
            </Box>
            
            <CardContent>
            <Box ml={2} component="div">
                    <label className="pocn-text">What is Azure? | Microsoft Azure Tutorials For Beginners | Microsoft Azure Training | Simplilearn</label>
                </Box>
                <Box ml={2} mt={4} component="div">
                    Submitted By : Ratheesh K R &nbsp;
                    Submitted at : 05/08/2022 &nbsp;
                    Status : Published &nbsp;
                    Video Id : 1025863354654654 &nbsp;
                    Published On : 05/08/2022 
                </Box>
                <Box ml={2} mt={2} component="div">{VideoField()}</Box>
                <Box ml={2} mt={2} component="div">
                    <Grid container >
                            <Grid item xs={2} mt={1} mr={3} >
                                <label>Description</label>
                            </Grid>
                            <Grid item xs={9} ml={1}>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={firstName}
                                    onChange={(event, editor) => {
                                        let data = editor.getData();
                                        data = data.trim();
                                        setFirstName(event.target.firstName)
                                    }}

                                />
                            </Grid>
                        </Grid>
                </Box>
                <Grid container spacing={0} >
                 <Grid container item xs={7.8}  direction="column" >
                <Box mt={2}>
                    <Grid container >
                                <Grid item xs={3} mt={2} ml={2} mr={4} >
                                    <label>Category</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        value={subcategory}
                                        
                                        onChange={(e: any) => {
                                                setSubCategory(e.target.value);
                                            }}
                                        fullWidth
                                    >
                                        {
                                            subcategory.map((element: any) => (
                                                <MenuItem key={element.id} value={element.id}>
                                                    {element.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={1.5} mt={2} ml={3} mr={2}>
                                </Grid>
                            </Grid>
                </Box>
                
                        <Box>
                    <Grid container >
                                <Grid item xs={3} mt={2} ml={2} mr={4} >
                                    <label>Sub Category</label>
                                </Grid>
                                <Grid item xs={6}>
                                   <TextField
                                        id="outlined-select-currency"
                                        select
                                        value={subcategory}
                                        
                                        onChange={(e: any) => {
                                                setSubCategory(e.target.value);
                                            }}
                                        fullWidth
                                    >
                                        {
                                            subcategory.map((element: any) => (
                                                <MenuItem key={element.id} value={element.id}>
                                                    {element.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={1.5} mt={2} ml={3} mr={2}>
                                   
                                </Grid>
                                
                            </Grid>
                </Box>
                <Box>
                        <Grid container>
                            <Grid item xs={3} mt={1} ml={2} mr={4}>
                                <label>Custom Image</label>
                            </Grid>
                            <Grid item xs={6}>
                                    <Input onChange={handleCustomImages} type="file" id="cqa-custom-image" name="cqa-custom-image" inputProps={{ multiple: false, accept: '.jpeg,.jpg,.png' }} />
                                    {curationUpdateState.invalidCustomFile ? <span className="cqa-validation-error">{curationUpdateState.invalidCustomFileMessage}</span> : null}
                            </Grid>
                        </Grid>
                </Box>
                 {(curationUpdateState.isCustomImage)?
                    (<Box>
                        <Grid container>
                            <Grid item xs={3} mt={2} ml={2} mr={4}>
                                {/* <label>Custom Image</label> */}
                            </Grid>
                            <Grid item xs={6}>
                                <Box component="div" mt={1} className='curation-images-outer curation-image'>
                                    <img src={customImage} alt="Article Image" className="curation-images" />
                                </Box>
                                <Box className='button-delete'>
                                    <Button variant="outlined" color="primary" size="small" onClick={() => { setCustomDeleteOpen(true); setImageType('custom') }} >
                                        delete
                                    </Button>
                                    <Confirm
                                        isOpen={openCustomDelete}
                                        title="Delete"
                                        content="Are you sure you want to delete this Image? "
                                        onConfirm={deleteImage}
                                        onClose={handleDeleteImageClose}
                                    />
                                </Box>
                            </Grid>
                            
                        </Grid>
                    </Box>):null
                }
                <Box mt={2}>
                    <Grid container >
                                <Grid item xs={3} mt={2} ml={2} mr={4} >
                                    <label>Tags</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="city"
                                        variant='outlined'
                                        fullWidth
                                        label=""
                                        value={city}
                                        // onChange={(e: any) => setCity(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={1.5} mt={2} ml={3} mr={2}>
                                   
                                </Grid>
                                
                            </Grid>
                </Box>
                <Box>
                    <Grid container >
                                <Grid item xs={3} mt={2} ml={2} mr={4} >
                                    <label>Specialty</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        id="outlined-select-currency"
                                        select
                                        // label="Speciality"
                                        value={specialty}
                                        onChange={(e: any) => {
                                            setSpecialty(e.target.value);
                                        }}
                                        fullWidth
                                    >
                                        {
                                            specialtyList.map((element: any) => (
                                                <MenuItem key={element.id} value={element.id}>
                                                    {element.title}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={1.5} mt={2} ml={3} mr={2}>
                                </Grid>
                            </Grid>
                </Box>
                <Box>
                    <Grid container >
                                <Grid item xs={3} mt={2} ml={2} mr={4} >
                                    <label>Therapeutic Area</label>
                                </Grid>
                                <Grid item xs={6}>
                                   <TextField
                                        id="outlined-select-currency"
                                        select
                                        // label="Therapeutic area"
                                        value={therapeuticArea}
                                        onChange={(e: any) => setTherapeuticArea(e.target.value)}
                                        fullWidth
                                    >
                                        {
                                            therapeuticAreaList.map((element: any) => (
                                                <MenuItem key={element.id} value={element.id}>
                                                    {element.title}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={1.5} mt={2} ml={3} mr={2}>
                                </Grid>
                                
                            </Grid>
                </Box>
                <Box>
                    <Grid container >
                                <Grid item xs={3} mt={1} ml={2} mr={3} >
                                    <label>Is CME Video</label>
                                </Grid>
                                <Grid item xs={6}>
                                    <Switch
                                        checked={checked}
                                        onChange={handleChange}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>
                                
                            </Grid>
                    </Box>
                    </Grid>

                    <Grid container item xs={4} direction="column" >
                        <Box className="preferences-box" mt={2}>
                            <label>Preferences</label>
                     <Box ml={4} mt={2}>
                    <Grid container >
                                <Grid item xs={6} mt={1} mr={2}>
                                    <label>Enable Comments</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={commentEnabled}
                                        onChange={handleComment}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                         <Box ml={4} mt={1}>
                    <Grid container >
                                <Grid item xs={6} mt={1} mr={2} >
                                    <label>Enable Likes</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={likeEnabled}
                                        onChange={handleLike}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                         <Box ml={4} mt={1}>
                    <Grid container >
                                <Grid item xs={6} mt={1} mr={2} >
                                    <label>Enable Shares</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={shareEnabled}
                                        onChange={handleShare}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>
                                
                            </Grid>
                        </Box>
                         <Box ml={4} mt={1}>
                    <Grid container >
                                <Grid item xs={6} mt={1} mr={2} >
                                    <label>Enable Download</label>
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={downloadEnabled}
                                        onChange={handleDownload}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                </Grid>
                                
                            </Grid>
                            </Box>
                            </Box>
                    </Grid>
                    </Grid>
                    <Box>
                            <Grid container>
                                <Grid item xs={1} mt={2} mr={4} ml={2}>
                                    <Button variant="contained" color="primary" size="large" className="curate-cancel-right" onClick={handleCloseClick}>Back</Button>  
                                </Grid>
                                <Grid item xs={2} mt={2}>
                                    <Button variant="contained" color="primary" size="large" className="curate-update-right" onClick={handleCloseClick}>Update</Button>
                                </Grid>
                            </Grid>
                </Box>
                {/* <video width="750" height="500" controls >
      <source src={firstName} type="video/mp4"/>
     </video> */}
            </CardContent>
        </Card>
    );
}
export default PocnVideoCreate;