import { Fragment, useState } from "react";
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, useNotify } from 'react-admin';
import dataProvider from '../dataProvider/DataProvider'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.title.trim()) {
        errors.title = 'The Speciality is required';
    }
    return errors;
};
const ListCreate = () => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const notify = useNotify();  
    const postSpecialty = (data: any) => {
        dataProvider.create('addSpeciality', { data }).then((result: any) => {
            if (result.data.status === 'error') {
                notify(`Speciality ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Created Speciality Successfully`, { type: 'success' });
                    navigate("/speciality");
            }            
        });        

    };

    const CreateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };
    const handleCloseClick = () => {
        setStatus(!show)
        navigate("/speciality");
    }
    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} >                
                <DialogTitle>
                    Create New Speciality
                </DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm id="post_create_form" onSubmit={postSpecialty} sx={{ maxWidth: 600 }} toolbar={<CreateToolbar />} validate={validateForm}>
                            <TextInput
                                autoFocus
                                source="title"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="speciality"
                            />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
};
const ListEdit = (props: any) => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const { id } = useParams();
    const notify = useNotify();       
    const postUpdate = (data: any) => {
        dataProvider.update('speciality', { id: Number(id), data, previousData: {} }).then((result: any) => {  
            if (result.data.status === 'error') {
               notify(`Speciality ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Updated Speciality Successfully`, { type: 'success' });
                navigate("/speciality");
           }
        });   
    };    

    const UpdateToolbar = () => { 
        return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(false)
        navigate("/speciality");
    }

    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} >
                <DialogTitle>
                    Update Speciality
                </DialogTitle>
                <DialogContent>
                    <Edit title={"Speciality"}>
                        <SimpleForm onSubmit={postUpdate} id="post_create_form" sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar />} validate={validateForm}>
                            <TextInput
                                autoFocus
                                source="title"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="speciality"
                            />
                        </SimpleForm>
                    </Edit>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};


export {
    ListCreate,
    ListEdit
}