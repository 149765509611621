import UserIcon from '@mui/icons-material/People';
import { CoeList } from './CoeList';
import { COECreate } from './COEcreate';
import { CoeEdit } from './COEcreate';

const resource = {
    list: CoeList,
    create: COECreate,
    edit: CoeEdit,
    icon: UserIcon,
};

export default resource;
