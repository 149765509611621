import React, { FC, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {  useAuthenticated } from 'react-admin';
import { Grid, Button, Card, CardContent, Box, CircularProgress, TextField } from '@mui/material';
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, ReferenceInput, SelectInput, Form } from 'react-admin';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import {
    useParams,
} from "react-router-dom";
import dataProvider from './../dataProvider/DataProvider';
import { useNavigate } from "react-router-dom";
import './../Curation/curation.css';
// import { Show, TabbedShowLayout, Tab, TextField } from 'react-admin';

const moment = require('moment');

const UserDetailPage: FC = () => {
    useAuthenticated();
    const { id } = useParams();
    const [showLoader, setShowLoader] = useState(true);
    const [npi, setNpi] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [birth, setBirth] = useState('');
    const [address, setAddress] = useState('');
    const [emailId, setEmailId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [createdBy, setCreatedBy] = useState('');
    const [prefix, setPrefix] = useState(null);
    const [suffix, setSuffix] = useState(null);
    const [state, setState] = useState('');
    const [member, setMember] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [workPhone, setWorkPhone] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [twitterProfile, setTwitterProfile] = useState('');
    const [linkedInProfile, setLinkedInProfile] = useState('');
    const [fbProfile, setFbProfile] = useState('');
    const [value, setValue] = React.useState('profile');
    const [tabName, setTabName] = React.useState('profileinfo');
    const [disableField, setDisableField] = useState({
        firstName: false,
        lastName: false,
        middleName: false,
        suffix: false,
        birth: false,
        emailId: false,
        npi: false,
        twitterProfile: false,
        linkedInProfile: false,
        fbProfile: false,
        address: false,
        city: false,
        state: false,
        zipCode: false,
        timeZone: false,
        workPhone: false,
        mobilePhone: false,
    }); 

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    };

    const handleTabChange = (event: React.SyntheticEvent, newVal: string) => {
    setTabName(newVal);
    };

     useEffect(() => {
         getUserData();
     }, []);
    
    const getUserData = () => {
        dataProvider.getOne('pocnUsers', { id: id }).then((result: any) => {
            if (result.data) {
                
                setNpi(result.data.npi);
                setFirstName(result.data.firstName);
                setLastName(result.data.lastName);
                setMiddleName(result.data.middleName);
                setEmailId(result.data.emailId);
                setProviderId(result.data.providerId);
                setCreatedAt(result.data.createdAt);               
                setCreatedBy(result.data.createdBy);
                setPrefix(result.data.prefix);
                setSuffix(result.data.suffix);
                setMember(result.data.pocnMember);
                setState(result.data.state);
                setCity(result.data.city);
                setTwitterProfile(result.data.twitterProfile);
                setLinkedInProfile(result.data.linkedInProfile);
                setFbProfile(result.data.fbProfile);
                // setZipCode(result.data.zipCode);
            }
            setShowLoader(false);
            console.log(result.data);
        })
}

    const navigate = useNavigate();
    const handleCloseClick = () => {
        navigate(`/pocnNonProviders`);
    }

    return (
        
        <Card>
            <CardContent>
        
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="profile" label="Profile" />
                        <Tab value="connections" label="Connections" />
                        <Tab value="videos" label="Videos" />
                        <Tab value="groups" label="Groups" />
                    </Tabs>
                    {value === 'profile' ?
                        <Tabs
                            value={tabName}
                            onChange={handleTabChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="profileinfo" label="Profile Information" />
                            <Tab value="bookmark" label="Bookmark" />
                            <Tab value="messages" label="Messages" />
                            <Tab value="notifications" label="Notifications" />
                            <Tab value="enghistory" label="Engagement History" />
                        </Tabs>
                        : null}
                    {( tabName === 'profileinfo' && value === 'profile') ?
                        <Box component="div">
                            <Grid container > 
                                <Grid item xs={1} mb={1} mr={4} ml={2}>
                                
                                   <Box className="img-profile" >
                                {/* <img src="profileimg.jpg" /> */}
                                <img className="img-profile" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJEAkQMBIgACEQEDEQH/xAAbAAEAAwEBAQEAAAAAAAAAAAAABAUGAwIBB//EADUQAAICAQIDBAcHBQEAAAAAAAABAgMEBRESITEGQVFxEyIyUmGBwSMzQmKRobEUQ3LR8OH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A/cQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD5KSim5NJLq2RdQz6cGvise8n7MF1Zls3UL82Tdsmob8oLov8AYF9l69i0txpTumvd5L9Ssu17Mn92oVeS3/kqgBLlqmdLrk2Ly2QjqedF7/1VnzZEAFnVrubB+vKFi/NHb+CyxO0FFj2yIOl+K9ZGaAG7rshbFSrkpRfRp7nsxGJl34lnFRPh8Y9z80afTNUqzo8PsXLrBvr8UBYAAAAAAAAAAARNSzoYOO5y5zfKEfFkqTUYuUnslzbMZqWY83KlY9+BcoLwQHHIvsyLpW3S4py/Y5gAAAAB7rptt+6qsn/jFs+zx7q1vZTZFeLgwOYAAH2E5VzU65OMk9013HwAa3SNRjnVcMtldD2l4rxRYmGxcieNkQur9qL6eK70bXHuhfTC2t7xmt0B0AAAAAAABU9osr0OGqovaVr2+XeZcte0dvHnqHdXFL5vmVQAAAFzL7TdJhCKsy48U3zUH0XmQdDx1fm8UvZrXF8+40gBJJJJcl3Dz5oACtz9KqvTnRFV2rol0kZ6UZQk4zW0k9mvA2ZQ9oMdRtrvj/c5S80BUgAAaDsxktwsxpP2fWgvh3mfJujW+h1Kh+9Lgfz/AOQGxAAAAAAABjNXlxankP8ANt+xEJerLbUslP3yIAAAFz2ba4sjx2j9S7MvpOSsbMjxvaE/Vk/D4moAAAAVfaLb+kr8XYtv0ZaGe17JVuRGmL3VXXzArAAAOmPLhyKpLqpxa/U5nuhb31pdXNL9wN2AAAAAAADKdoquDUZT7rIqX0+hWGl7S4zsxYXx61Pn5MzQAAAC207V/QxVOVu4LlGa5tfAj4Ol35SU/u63+J9/ku8tFouL6Phbsc/f4vp0Am1ZFNyTquhLykerLqq1vZZCH+Ukils0KxP7O6DX5k0z5DQrW/XurS+CbA65+srhlXib7v8AuPu8ik8zRQ0XEVbjJ2Sk/wAfFtt8iuzdIuoTnU/S1rwXrL5AVwAAEvSKvTalRHwlxP5cyIXvZjH3lbkyXJepH6/QDQgAAAAAAA8W1xtrlXNbxktmjF5uNPEyp0z7vZfiu5m3K/V9PWdT6uyuhzhLx+AGRLjSNMViWRkx9X8EH3/FkTTsGV+b6K2LUa+dif8ABp+nJLbyAAAAAAAAAqdW0xWRlfjx+06yivxeXxKE2hndbxPQ5Csri+G3uXvf+gQKap32xqrW8pPZI2mHjxxcaumHSK6+L8SBoemvFh6a9fbSXJe4v9lsAAAAAAAAAAAHOVMHJySSm+r26nJpx6kk+NJrZgRgdZVe6zw4SXcB5A2fgNn4AAelCT7j2qveYHNLd7I6KmLcXOKbT3W/czoopdEfQGwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/9k=" />
                                {/* <AccountCircleIcon className="article-profile-icon"/> */}
                        </Box>
                                </Grid>
                                
                                <Grid item xs={9} mb={1} ml={8} mt={2} >
                                <Grid container > 
                                    <Grid item xs={5} mb={1} mr={6} ml={4} mt={2}>
                                            <Box className="pocn-text">POCN User Id : {}</Box> 
                                            <Box className="pocn-text" mt={1}>POCN Engagement Score : {}</Box>
                                            <Box className="pocn-text" mt={1}>Total Engagements : {}</Box>
                                            <Box className="pocn-text" mt={1}>Last Engagement Date : {}</Box>    
                                    </Grid>
                                    <Grid item xs={4} mb={1} mr={2} ml={6} mt={2}>
                                            <Box className="pocn-text">#Connections : {}</Box>
                                            <Box className="pocn-text" mt={1}>#Videos : {}</Box>
                                            <Box className="pocn-text" mt={1}>#Key Engagements : {}</Box>
                                    </Grid>
                                </Grid>
                                </Grid>
                            </Grid>
                        
                        <Box ml={4} component="div">
                                <h4>User Information</h4>
                        </Box>
                            <Grid container >
                                
                                <Grid item xs={5} mb={2} mr={8} ml={4} mt={2}>
                                
                                    <TextField
                                    id="firstName"
                                    variant='outlined'
                                    fullWidth
                                    label='First Name'
                                    value={firstName}
                                    // onChange={(e: any) => setFirstName(e.target.value)}
                                    disabled={disableField.firstName}
                                />
                                </Grid>
                                
                                <Grid item xs={5} mb={2} ml={4} mt={2} >
                                    <TextField
                                    id="suffix"
                                    variant='outlined'
                                    fullWidth
                                    label="Suffix"
                                    value={suffix}
                                    // onChange={(e: any) => setSuffix(e.target.value)}
                                    disabled={disableField.suffix}
                                />
                                </Grid>
                            </Grid>
                            
                             <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                    id="middleName"
                                    variant='outlined'
                                    fullWidth
                                    label="Middle Name"
                                    value={middleName}
                                    // onChange={(e: any) => setMiddleName(e.target.value)}
                                    disabled={disableField.middleName}
                                />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            
                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="lastName"
                                        variant='outlined'
                                        fullWidth
                                        label="Last Name"
                                        value={lastName}
                                        // onChange={(e: any) => setLastName(e.target.value)}
                                        disabled={disableField.lastName}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="birth"
                                        variant='outlined'
                                        fullWidth
                                        label="Date of Birth"
                                        value={birth}
                                        // onChange={(e: any) => setBirth(e.target.value)}
                                        disabled={disableField.birth}
                                    />
                                </Grid>
                            </Grid>
                            
                             <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="emailId"
                                        variant='outlined'
                                        fullWidth
                                        label="Email"
                                        value={emailId}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={true} 
                                        // disabled={disableField.emailId}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="npi"
                                        variant='outlined'
                                        fullWidth
                                        label="NPI#"
                                        value={npi}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={true} 
                                        // disabled={disableField.npi}
                                    />
                                </Grid>
                            </Grid>
                                
                        <Box ml={4} component="div">
                            <h4>Contact Information</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="address"
                                        variant='outlined'
                                        fullWidth
                                        label="Address Line 1"
                                        value={address}
                                        // onChange={(e: any) => setAddress(e.target.value)}
                                        disabled={disableField.address}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="address"
                                        variant='outlined'
                                        fullWidth
                                        label="Address Line 2"
                                        value={address}
                                        // onChange={(e: any) => setAddress(e.target.value)}
                                        disabled={disableField.address}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="city"
                                        variant='outlined'
                                        fullWidth
                                        label="City"
                                        value={city}
                                        // onChange={(e: any) => setCity(e.target.value)}
                                        disabled={disableField.city}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="state"
                                        variant='outlined'
                                        fullWidth
                                        label="State"
                                        value={state}
                                        // onChange={(e: any) => setState(e.target.value)}
                                        disabled={disableField.state}
                                    />
                                </Grid>
                            </Grid>

                          <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="zipCode"
                                        variant='outlined'
                                        fullWidth
                                        label="Zip Code"
                                        value={zipCode}
                                        // onChange={(e: any) => setZipCode(e.target.value)}
                                        disabled={disableField.zipCode}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="timeZone"
                                        variant='outlined'
                                        fullWidth
                                        label="Time Zone"
                                        value={timeZone}
                                        // onChange={(e: any) => setTimeZone(e.target.value)}
                                        disabled={disableField.timeZone}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="workPhone"
                                        variant='outlined'
                                        fullWidth
                                        label="Work Phone"
                                        value={workPhone}
                                        // onChange={(e: any) => setWorkPhone(e.target.value)}
                                        disabled={disableField.workPhone}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="mobilePhone"
                                        variant='outlined'
                                        fullWidth
                                        label="Mobile Phone"
                                        value={mobilePhone}
                                        // onChange={(e: any) => setMobilePhone(e.target.value)}
                                        disabled={disableField.mobilePhone}
                                    />
                                </Grid>
                            </Grid>

                            
                        <Box ml={4} component="div">
                            <h4>Professional Profile</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Designation"
                                        value={workPhone}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={disableField.workPhone}
                                    />
                                </Grid>
                                <Grid item xs={1} mt={2} ml={3}>
                                    <label>Resume</label>
                                </Grid>
                                <Grid item xs={4} mb={2} ml={1} >
                                    <TextField type="file" />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Case Manager"
                                        value={workPhone}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={disableField.workPhone}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Addiction Medicine"
                                        value={mobilePhone}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={disableField.mobilePhone}
                                    />
                                </Grid>
                            </Grid>

                            <Box ml={4} mr={4} component="div">
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Pofessional Profile/Bio"
                                        multiline
                                        rows={4}
                                        value={mobilePhone}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={disableField.mobilePhone}
                                    />
                               </Box>

                            
                        <Box ml={4} component="div">
                            <h4>Social Profile</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="fbProfile"
                                        variant='outlined'
                                        fullWidth 
                                        label="Facebook"
                                        value={fbProfile}
                                        // onChange={(e: any) => setFbProfile(e.target.value)}
                                        disabled={disableField.fbProfile}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="twitterProfile"
                                        variant='outlined'
                                        fullWidth
                                        label="Twitter"
                                        value={twitterProfile}
                                        // onChange={(e: any) => setTwitterProfile(e.target.value)}
                                        disabled={disableField.twitterProfile}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="linkedInProfile"
                                        variant='outlined'
                                        fullWidth
                                        label="LinkedIn"
                                        value={linkedInProfile}
                                        // onChange={(e: any) => setLinkedInProfile(e.target.value)}
                                        disabled={disableField.linkedInProfile}
                                    />
                                </Grid>
                            </Grid>

                        <Box>
                            <Grid container>
                                <Grid item xs={1} mt={2} mr={4} ml={4}>
                                    <Button variant="contained" color="primary" size="large" className="curate-cancel-right" onClick={handleCloseClick}>Back</Button>  
                                </Grid>
                                {/* <Grid item xs={2} mt={2}>
                                    <Button variant="contained" color="primary" size="large" className="curate-update-right" onClick={handleCloseClick}>Update</Button>
                                </Grid> */}
                            </Grid>
                        </Box>
                     
                    </Box>  
                : null} 

                </Box>
            </CardContent>
        </Card>
    );
}
export default UserDetailPage;