import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import LabelIcon from '@mui/icons-material/Label';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import dataProvider from '../dataProvider/DataProvider';

import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    useSidebarState,
    NumberFieldProps,
} from 'react-admin';

import users from '../users';
import SubMenu from './SubMenu';
import { StringLiteral } from 'typescript';

type MenuName = 'menuContentAdmin' | 'menuContent' | 'menuCoeSites' | 'menuPocnUsers' | 'menuCoe' | 'menuCoeContent' | 'menuAdminUsers' | 'menuPocnVideos';


const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuContentAdmin: false,
        menuContent: false,
        menuCoeSites: false,
        menuPocnUsers: false,
        menuCoe: false,
        menuCoeContent: false,
        menuAdminUsers: false,
        menuPocnVideos: false,
        coeSiteName: '',
        curatedContent: [],
        menuStatusList:[]
    });
    const translate = useTranslate();
    const [open] = useSidebarState();
    const [curateMenu, setCurateMenu] = useState({});

    const handleToggle = (menu: string) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));    
    };
    const handleCurateMenu = (menu: string) => {    
        setCurateMenu(curateMenu => ({ ...curateMenu, [menu]: !curateMenu[menu] }));      
    };
   

    useEffect(() => {
        curatedList();
        getCurationStatus();
    }, []);
    function curatedList() {
        let userType: string = JSON.parse(localStorage.getItem('auth'))?.userTypeId;
        if (JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_MICROSITE_ADMIN) {
            userType = process.env.REACT_APP_MICROSITE_ADMIN;
        }
        const curatedResponse: any = dataProvider.getMany('coeSites', {ids:[]});
        curatedResponse.then((response: any) => {
            if (response.data) {
                response.data.map((coeSite: any) => {
                    setCurateMenu((curateMenu) => ({
                        ...curateMenu,
                        [`menu${coeSite.name.charAt(0).toUpperCase() + coeSite.name.substring(1).replace(" ",'')}`]: false
                    }))
                });

                if (userType === process.env.REACT_APP_KOL_USER || userType === process.env.REACT_APP_CURATOR ) {
                    let sites = [];
                    response.data.map((coesite: { name: string; id: number }, index: any) => {
                        if ((JSON.parse(localStorage.getItem('auth')).coeSites).includes(coesite.id)) {
                            sites.push(coesite)
                        }
                    })
                    setState((state) => ({ ...state, curatedContent: sites }));
                } else {
                    setState((state) => ({ ...state, curatedContent: response.data }));
                }


            }
        });

    }
    const getCurationStatus = () => {
        dataProvider.getMany('status', { ids: [] }).then((result: any) => {
            if (result.data) {
                             
                setState((state) => ({
                    ...state,
                    menuStatusList: result.data
                }));
                
            }
        });
    }    

    return (
        <Box
            sx={{
                width: open ? 200 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            <DashboardMenuItem />
            {(JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_POCN_ADMIN || JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_SUPER_ADMIN) ?
            (<SubMenu
                    handleToggle={() => handleToggle('menuPocnUsers')}
                    isOpen={state.menuPocnUsers}
                    name="POCN Users"
                    icon={<users.icon />}
                    dense={dense}
                    key={`sub-menu-pocn`}
            >
                <MenuItemLink
                to="/pocnUsers"
                state={{ _scrollToTop: true }}
                primaryText={translate(`Providers`, {
                    smart_count: 2,
                })}
                leftIcon={<ImportContactsIcon />}
                dense={dense}
                key="main-menu-pocn_users"
                />
                <MenuItemLink
                to="/pocnNonProviders"
                state={{ _scrollToTop: true }}
                primaryText={translate(`Non Providers`, {
                    smart_count: 2,
                })}
                leftIcon={<ImportContactsIcon />}
                dense={dense}
                key="main-menu-pocn_users1"
                />
                <SubMenu
                    handleToggle={() => handleToggle('menuPocnVideos')}
                    isOpen={state.menuPocnVideos}
                    name="Videos"
                    icon={<VideoCameraFrontIcon />}
                    dense={dense}
                    key={`sub-menu-pocn-videos`}
                >
                    <MenuItemLink
                        to="/pocnVideos_all"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`All`, {
                            smart_count: 2,
                        })}
                        leftIcon={<VideoFileIcon />}
                        dense={dense}
                        key="main-menu-pocn_video_all"
                    />
                    <MenuItemLink
                        to="/pocnVideos_submitted"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`Submitted`, {
                            smart_count: 2,
                        })}
                        leftIcon={<VideoFileIcon />}
                        dense={dense}
                        key="main-menu-pocn_video_submitted"
                    />
                    <MenuItemLink
                        to="/pocnVideos_approved"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`Approved`, {
                            smart_count: 2,
                        })}
                        leftIcon={<VideoFileIcon />}
                        dense={dense}
                        key="main-menu-pocn_video_approved"
                    />
                    <MenuItemLink
                        to="/pocnVideos_rejected"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`Rejected`, {
                            smart_count: 2,
                        })}
                        leftIcon={<VideoFileIcon />}
                        dense={dense}
                        key="main-menu-pocn_video_rejected"
                    />
                    <MenuItemLink
                        to="/pocnVideos_standBy"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`Stand By`, {
                            smart_count: 2,
                        })}
                        leftIcon={<VideoFileIcon />}
                        dense={dense}
                        key="main-menu-pocn_video_standBy"
                    />
                    <MenuItemLink
                        to="/pocnVideos_published"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`Published`, {
                            smart_count: 2,
                        })}
                        leftIcon={<VideoFileIcon />}
                        dense={dense}
                        key="main-menu-pocn_video_published"
                    />
                    {/* {state.curatedContent ? state.curatedContent.map((coesite: { name: string; id: number }, index: any) => (
                <SubMenu
                    handleToggle={() => handleCurateMenu(`menu${coesite.name.charAt(0).toUpperCase() + coesite.name.substring(1).replace(" ",'')}`)}
                    name={coesite.name}
                    icon={<users.icon />}
                    isOpen={curateMenu[`menu${coesite.name.charAt(0).toUpperCase() + coesite.name.substring(1).replace(" ",'')}`]}
                    dense={dense}
                    key={`sub-menu-${coesite.name}`}
                >
                    <MenuItemLink
                        to={'curated/' + coesite.name.replace(" ", '') + '/all'}
                        state={{ _scrollToTop: true }}
                        primaryText={translate('All', {
                            smart_count: 2,
                        })}
                        leftIcon={<ImportContactsIcon />}
                        dense={dense}
                        key="sub-menu-curated-all"
                    />
                    {state.menuStatusList.length > 0 ? state.menuStatusList.map((statusResult: { status: string; displayStatus: StringLiteral }) => (
                        <MenuItemLink
                            to={'curated/' + coesite.name.replace(" ", '') + '/'+statusResult.status}
                            state={{ _scrollToTop: true }}
                            primaryText={translate(`${statusResult.displayStatus}`, {
                                smart_count: 2,
                            })}
                            leftIcon={<ImportContactsIcon />}
                            dense={dense}
                            key={`sub-menu-curated-${statusResult.status}`}
                        />)) : null}
                    
                </SubMenu>
            )) : null} */}
                    
                </SubMenu>
                </SubMenu>) : null}





            {(JSON.parse(localStorage.getItem('auth'))?.userTypeId !== process.env.REACT_APP_POCN_ADMIN) ? (
                <SubMenu
                    handleToggle={() => handleToggle('menuCoe')}
                    isOpen={state.menuCoe}
                    name="CoE"
                    icon={<users.icon />}
                    dense={dense}
                    key={`sub-menu-coe`}
                >
                    <MenuItemLink
                        to="/curation"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`Upload Content`, {
                            smart_count: 2,
                        })}
                        leftIcon={<ImportContactsIcon />}
                        dense={dense}
                        key="main-menu-curation"
                    />
                    <SubMenu
                        handleToggle={() => handleToggle('menuCoeContent')}
                        isOpen={state.menuCoeContent}
                        name="Content"
                        icon={<users.icon />}
                        dense={dense}
                        key={`sub-menu-coe_content`}
                    >
                        {state.curatedContent.length > 0 ? state.curatedContent.map((coesite: { name: string; id: number }, index: any) => (
                            <SubMenu
                                handleToggle={() => handleCurateMenu(`menu${coesite.name.charAt(0).toUpperCase() + coesite.name.substring(1).replace(" ", '')}`)}
                                name={coesite.name}
                                icon={<users.icon />}
                                isOpen={curateMenu[`menu${coesite.name.charAt(0).toUpperCase() + coesite.name.substring(1).replace(" ", '')}`]}
                                dense={dense}
                                key={`sub-menu-${coesite.name}`}
                            >
                                <MenuItemLink
                                    to={'curated/' + coesite.name.replace(" ", '') + '/all'}
                                    state={{ _scrollToTop: true }}
                                    primaryText={translate('All', {
                                        smart_count: 2,
                                    })}
                                    leftIcon={<ImportContactsIcon />}
                                    dense={dense}
                                    key="sub-menu-curated-all"
                                />
                                {state.menuStatusList.length > 0 ? state.menuStatusList.map((statusResult: { status: string; displayStatus: StringLiteral }) => (
                                    <MenuItemLink
                                        to={'curated/' + coesite.name.replace(" ", '') + '/' + statusResult.status}
                                        state={{ _scrollToTop: true }}
                                        primaryText={translate(`${statusResult.displayStatus}`, {
                                            smart_count: 2,
                                        })}
                                        leftIcon={<ImportContactsIcon />}
                                        dense={dense}
                                        key={`sub-menu-curated-${statusResult.status}`}
                                    />)) : null}

                            </SubMenu>
                        )) : null}
                    </SubMenu>
                    {(JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_MICROSITE_ADMIN || JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_SUPER_ADMIN) ?
                        (<SubMenu
                            handleToggle={() => handleToggle('menuContentAdmin')}
                            isOpen={state.menuContentAdmin}
                            name="Site Configuration"
                            icon={<users.icon />}
                            dense={dense}
                            key={`sub-menu-content_admin`}
                        >

                            <MenuItemLink
                                to="/mediaTypes"
                                state={{ _scrollToTop: true }}
                                primaryText={translate(`Media Types`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<LabelIcon />}
                                dense={dense}
                                key="sub-menu-media"
                            />

                            <MenuItemLink
                                to="/speciality"
                                state={{ _scrollToTop: true }}
                                primaryText={translate('Speciality', {
                                    smart_count: 2,
                                })}
                                leftIcon={<LabelIcon />}
                                dense={dense}
                                key="sub-menu-speciality"
                            />
                            <MenuItemLink
                                to="/therapeutic_area"
                                state={{ _scrollToTop: true }}
                                primaryText={translate('Therapeutic Area', {
                                    smart_count: 2,
                                })}
                                leftIcon={<LabelIcon />}
                                dense={dense}
                                key="sub-menu-therapeutic_area"
                            />
                            <MenuItemLink
                                to="/coeSites"
                                state={{ _scrollToTop: true }}
                                primaryText={translate(`CoE Site`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<LabelIcon />}
                                dense={dense}
                                key="sub-menu-coeSites"
                            />
                            <MenuItemLink
                                to="/coeSitesLocation"
                                state={{ _scrollToTop: true }}
                                primaryText={translate(`CoE Site Location`, {
                                    smart_count: 2,
                                })}
                                leftIcon={<LabelIcon />}
                                dense={dense}
                                key="sub-menu-coe-sites-location"
                            />
                        </SubMenu>) : null}
                    </SubMenu>
            ) : null}
            



    
            {(JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_MICROSITE_ADMIN || JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_POCN_ADMIN || JSON.parse(localStorage.getItem('auth'))?.userTypeId === process.env.REACT_APP_SUPER_ADMIN) ?
            (<SubMenu
                    handleToggle={() => handleToggle('menuAdminUsers')}
                    isOpen={state.menuAdminUsers}
                    name="Admin"
                    icon={<users.icon />}
                    dense={dense}
                    key={`sub-menu-admin`}
            >
                    <MenuItemLink
                        to="/users"
                        state={{ _scrollToTop: true }}
                        primaryText={translate('Users', {
                            smart_count: 2,
                        })}
                        leftIcon={<users.icon />}
                        dense={dense}
                        key="sub-menu-user"
                    />
                    <MenuItemLink
                        to="/types"
                        state={{ _scrollToTop: true }}
                        primaryText={translate(`User Types`, {
                            smart_count: 2,
                        })}
                        leftIcon={<LabelIcon />}
                        dense={dense}
                        key="sub-menu-type"
                    />
                    </SubMenu>) : null}
            

            

           
        </Box>
    );
};

export default Menu;
