import { LocationList } from './LocationList';
import { LocationCreate } from './locationCreate';
import { LocationEdit } from './locationCreate';

const resource = {
    list: LocationList,
    create: LocationCreate,
    edit: LocationEdit
};

export default resource;
