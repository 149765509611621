import { Fragment, useState } from "react";
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, useNotify } from 'react-admin';
import dataProvider from '../dataProvider/DataProvider'
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';

type updateTypes = {
    id: number;
    previousData: string;
    type: string;
};

const TypeCreate = () => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const postSave = (data: any) => {
        dataProvider.create('addUserType', { data });
        navigate("/types");
    };

    const CreateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(false)
        navigate("/types");
    }

    return (
        <Fragment>

            <Dialog fullWidth open={show} onClose={handleCloseClick} >
              
                <DialogTitle>
                    Create New User Type
                </DialogTitle>

                <DialogContent>

                    <Create>
                        <SimpleForm id="post_create_form" onSubmit={postSave} sx={{ maxWidth: 600 }} toolbar={<CreateToolbar />}>
                            <TextInput
                                autoFocus
                                source="type"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                            />
                        </SimpleForm>
                    </Create>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.type.trim()) {
        errors.type = 'The User Type is required';
    }
    return errors;
};

const TypeEdit = (props: updateTypes) => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const notify = useNotify();
    const { id } = useParams();
    
    const postUpdate = (data: any) => {
        dataProvider.update('types', { id: id, data, previousData: {} }).then((result: any) => {  
            if (`User Type ${result.data.status}` === 'error') {
               notify(result.data.error, { type: 'error' });
            } else {
                notify(`Updated User type Successfully`, { type: 'success' });
                navigate("/types");
           }
        });   
    };

    const UpdateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(false)
        navigate("/types");
    }

    return (
        <Fragment>

            <Dialog fullWidth open={show} onClose={handleCloseClick} >

                <DialogTitle>
                    Update User Type
                </DialogTitle>

                <DialogContent>

                    <Edit title={"User Type"}>
                        <SimpleForm  onSubmit={postUpdate} id="post_create_form"
                            sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar />} validate={validateForm}>
                            <TextInput
                                autoFocus
                                source="type"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                            />
                        </SimpleForm>
                    </Edit>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};

export {
    TypeCreate,
    TypeEdit
}