import { useState,useEffect } from "react";
import dataProvider from '../dataProvider/DataProvider'
import {  ReferenceInput, SelectInput, PasswordInput, BooleanInput, Create,Edit, TextInput, SimpleForm, Toolbar, SaveButton, useGetOne, AutocompleteArrayInput } from 'react-admin';
import { Grid, Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotify } from 'react-admin';
import permissions from '../services/permissions';
import checkAuthentication from './../common/Authenticate';
import '../Curation/curation.css';


export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.firstName.trim()) {
        errors.firstName = 'The First Name is required';
    }
    if (!values.lastName.trim()) {
        errors.lastName = 'The Last Name is required';
    }
    if (!validateEmail(values.emailId.trim())) {
        errors.emailId = 'You have entered an invalid email address!'
    }
    if (values.password && values.password !== values.confirm_password) {
        errors.confirm_password =
            'password_mismatch';
    }
    if (!values.password.trim()) {
        errors.password = 'The Password is required';
    }
    if (!values.id) {
        errors.id = 'The UserType is required';
    }
    if ((values.id === permissions.CURATOR_ROLE || values.id === permissions.KOL_USER_ROLE) && values.name.length === 0) {
        errors.name = 'The CoE Site is required';
    } 
    return errors;
};
    const validateEmail = (str: any) => {
        let flag: any;
        const regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (regexp.test(str)) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    }

type newUser = {
    firstName: string;
    lastName: string;
    emailId: string;
    password: string;
    id: number;
    type: string;
};

const UserCreate = (props: newUser) => {
    const [show, setStatus] = useState(true);
    const [userType, setUserType] = useState('');
    const [coeSitesList, setCoeSites] = useState([]);
    const navigate = useNavigate();
    const notify = useNotify();
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
        dataProvider.getList('coeSites', {
            pagination: {
                page: 1, perPage: 1000
            },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then((result: any) => {
            if (result?.data) {
                setCoeSites(result.data);
            }
        });
    },[]);

    const postSave = (data: any) => {
        dataProvider.create('createNewUser', { data }).then((res: any) => {
            if (res.data.status === 'error') {
               notify(`${res.data.error}`, { type: 'error' });
            } else {
                notify(`Created User Successfully`, { type: 'success' });
                navigate("/users");
            }
        });
    };

    const CreateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

   const handleCloseClick = () => {
        setStatus(false)
        navigate("/users");
   }

    return (
        <Create>
        <SimpleForm id="post_create_form" onSubmit={postSave} sx={{ maxWidth: 800 }}
            validate={validateForm}
                toolbar={<CreateToolbar />}
        >
            <Box className='header-button'>
                <h3>New User</h3>
            </Box>
            <Box component="div">
                <Grid container >
                <Grid item xs={2} mt={4} ml={4} mr={2} >
                    <label>Name</label>
                </Grid>
                <Grid item xs={8} mt={2}>
                    <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                        <TextInput source="firstName" sx={{ minWidth: 300 }} fullWidth />
                    </Box>
                    <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                        <TextInput source="lastName" sx={{ minWidth: 300 }} fullWidth />
                    </Box>
                    </Box>
                </Grid>
                </Grid>
            </Box>
            <Box component="div">
                <Grid container >
                <Grid item xs={2} mt={4} ml={4} mr={2} >
                    <label>Email</label>
                </Grid>
                <Grid item xs={8} mt={2}>
                    <TextInput type="email" sx={{ minWidth: 620 }} source="emailId" fullWidth />
                </Grid>
                </Grid>
            </Box>
           
            <Box component="div">
                <Grid container >
                    <Grid item xs={2} mt={4} ml={4} mr={2} >
                        <label>Password</label>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <PasswordInput source="password" sx={{ minWidth: 300 }} fullWidth />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <PasswordInput source="confirm_password" sx={{ minWidth: 300 }} fullWidth />
                        </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box component="div">
                <Grid container >
                    <Grid item xs={2} mt={4} ml={4} mr={2} >
                        <label>User Type</label>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <ReferenceInput label="User Type" source="id" reference="types">
                            <SelectInput optionText="type" label="User Type" sx={{ minWidth: 620 }} emptyText='Select User Type' onChange={(e: any) => setUserType(e.target.value)} />            
                        </ReferenceInput>
                    </Grid>
                </Grid>
                </Box>

               
            {(userType === permissions.CURATOR_ROLE || userType === permissions.KOL_USER_ROLE) ?
                    (
                        <Box component="div">
                        <Grid container >
                            <Grid item xs={2} mt={4} ml={4} mr={2} >
                                <label>CoE Site</label>
                            </Grid>
                            <Grid item xs={8} mt={2}>
                                <AutocompleteArrayInput source="name" label="CoE Site" choices={coeSitesList} sx={{ minWidth: 620 }}/>
                            </Grid>
                        </Grid>
                        </Box>                        
                    )
            : null}      
        </SimpleForm>
    </Create>
    )
};

export const editvalidateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.firstName.trim()) {
        errors.firstName = 'The First Name is required';
    }
    if (!values.lastName.trim()) {
        errors.lastName = 'The Last Name is required';
    }
    if (values.password && values.password !== values.confirm_password) {
        errors.confirm_password =
            'password_mismatch';
    }
    if (!values.typeId) {
        errors.typeId = 'The UserType is required';
    }
    if (!values.coeSites) {
        errors.coeSites = 'The CoE Site is required';
    }
    return errors;
};

const UserEdit = () => {
    const [show, setStatus] = useState(true);
    const [userType, setUserType] = useState('');
    const [selectedCoeSites, setSelectedCoeSites] = useState([]);
    const [coeSitesList, setCoeSites] = useState([]);
   
    const navigate = useNavigate();
    const notify = useNotify();
    const { id } = useParams();  
    const { data } = useGetOne("users", { id });    
    useEffect(() => {  
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
        if (data) {
            setUserType(data.typeId);
            setSelectedCoeSites(data.coeSites)
        }
        dataProvider.getList('coeSites', {
            pagination: {
            page: 1, perPage: 1000
        },
         sort: { field: 'name', order: 'ASC' },
         filter: {},
        }).then((result: any) => {
        if (result?.data) {
        setCoeSites(result.data);
        }
        });
    },[]);
   
    const PostEdit = (data: any) => {
        if (data.typeId === process.env.REACT_APP_MICROSITE_ADMIN || data.typeId === process.env.REACT_APP_CONTENT_ADMINISTRATOR || data.typeId === process.env.REACT_APP_SUPER_ADMIN) {
            data.coeSites = [];
        }
        dataProvider.update('users', { id: id, data, previousData: {} }).then((res: any) => {  
            if (res.data.status === 'error') {
               notify(`${res.data.error}`, { type: 'error' });
            } else {
                notify(`Updated User Successfully`, { type: 'success' });
                navigate("/users");
            }
        });
    };

    const UpdateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(false)
        navigate("/users");
    }

    return (
         <Edit title={"Users"}>
            <SimpleForm id="post_create_form" onSubmit={PostEdit} sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar/>} validate={editvalidateForm}>
                <Box className='header-button'>
                    <h3>Update User</h3>
                </Box>
                <Box component="div">
                    <Grid container >
                    <Grid item xs={2} mt={4} ml={4} mr={2} >
                        <label>Name</label>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="firstName" sx={{ minWidth: 300 }} fullWidth />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <TextInput source="lastName" sx={{ minWidth: 300 }} fullWidth />
                        </Box>
                        </Box>
                    </Grid>
                    </Grid>
                </Box>

                <Box component="div">
                    <Grid container >
                    <Grid item xs={2} mt={4} ml={4} mr={2} >
                        <label>Email</label>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <TextInput type="email" sx={{ minWidth: 620 }} source="emailId"  fullWidth disabled={true} />
                    </Grid>
                    </Grid>
                </Box>

                <Box component="div">
                <Grid container >
                    <Grid item xs={2} mt={4} ml={4} mr={2} >
                        <label>Password</label>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <Box display={{ xs: 'block', sm: 'flex' }}>
                        <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                            <PasswordInput source="password" sx={{ minWidth: 300 }} fullWidth />
                        </Box>
                        <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                            <PasswordInput source="confirm_password" sx={{ minWidth: 300 }} fullWidth />
                        </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

                <Box component="div">
                    <Grid container >
                    <Grid item xs={2} mt={4} ml={4} mr={2} >
                        <label>User Type</label>
                    </Grid>
                    <Grid item xs={8} mt={2}>
                        <ReferenceInput source="typeId" reference="types">
                                <SelectInput optionText="type" label="User Type" sx={{ minWidth: 620 }} emptyText='Select User Type'  onChange={(e: any) => {
                                    setUserType(e.target.value);
                                }} />
                        </ReferenceInput>
                    </Grid>
                    </Grid>
                </Box>

               
                {
                    (userType === permissions.KOL_USER_ROLE || userType === permissions.CURATOR_ROLE) ?
                        (<Box component="div">
                        <Grid container >
                            <Grid item xs={2} mt={4} ml={4} mr={2} >
                                <label>CoE Site</label>
                            </Grid>
                            <Grid item xs={8} mt={2}>
                                    <AutocompleteArrayInput source="coeSites" choices={coeSitesList} sx={{ minWidth: 620 }}
                                    setFilter={(filter) => {
                                        return {
                                            siteName: filter
                                        }
                                        }} 
                                    defaultValue={selectedCoeSites}
                                    />
                            </Grid>
                        </Grid>
                        </Box>)
                : null}
                    <Grid component="div" ml={4}>
                        <BooleanInput label="Status" source="status" />
                    </Grid>
            </SimpleForm>
        </Edit>
    )
};

export {
    UserCreate,
    UserEdit
}
