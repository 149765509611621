import UserIcon from '@mui/icons-material/People';
import  UserDetailPage  from './UserDetailPage'
import { PocnVideoList } from './PocnVideosList';
import  PocnVideoCreate  from './PocnVideosCreate';

const resource = {
    list: PocnVideoList,
    create: PocnVideoCreate,
    edit: UserDetailPage,
    icon: UserIcon,
};

export default resource;
