import UserIcon from '@mui/icons-material/People';
import  UserDetailPage  from './UserDetailPage'
import { PocnUserList } from './PocnUserList';
import PocnUserCreate from '../pocnUsers/PocnUserCreate';

const resource = {
    list: PocnUserList,
    create: PocnUserCreate,
    edit: UserDetailPage,
    icon: UserIcon,
};

export default resource;
