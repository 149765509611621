import { AuthProvider } from 'react-admin';
import getJwtToken from '../services/getToken';
const authProvider: AuthProvider = {
    login: async ({ username, password }) => {
        const request = new Request(process.env.REACT_APP_GRAPHQL_URL_ANONYMOUS, {
            method: 'POST',
            body: JSON.stringify({
                query: `mutation  {
                    authenticateCoeUser(input: {username: "${username}", pwd: "${password}"}) {
                        authResponse {
                        status
                        authtoken
                        userdetails {
                            userId
                            firstName
                            lastName
                            email,
                            userType,
                            userTypeId,
                            coeSites

                        }
                        }
                    }
                    }
                    `,
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        const response = await fetch(request);
        const userData = await response.json();        
       
        if (userData.data.authenticateCoeUser.authResponse.status === 'success') {
            await getJwtToken().then(() => {
                localStorage.setItem('userName', username);
                localStorage.setItem('auth', JSON.stringify(userData.data.authenticateCoeUser.authResponse.userdetails));
                localStorage.setItem('user', `${userData.data.authenticateCoeUser.authResponse.userdetails.firstName} ${userData.data.authenticateCoeUser.authResponse.userdetails.lastName}`);
                return Promise.resolve();
            })
        } else {
            throw new Error(
                "Please enter a valid username and password"
            );
        }
 
    }, 
    logout: () => {
        localStorage.removeItem('userName');
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
        localStorage.removeItem('jwtToken');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
        getJwtToken();
        return localStorage.getItem('userName') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => {
        getJwtToken();
        return Promise.resolve();
        // Promise.reject('Unknown method')
    },   
    getIdentity: () =>
        Promise.resolve({
            id: 'user',
            fullName: localStorage.getItem('user'),
      }),
};

export default authProvider;