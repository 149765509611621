import UserIcon from '@mui/icons-material/People';

import { TherapeuticAreaList } from './TherapeuticAreaList';
import { TherapeuticAreaCreate } from './TherapeuticAreaCreate';
import { TherapeuticAreaEdit } from './TherapeuticAreaCreate';

const resource = {
    list: TherapeuticAreaList,
    create: TherapeuticAreaCreate,
    icon: UserIcon,
    edit: TherapeuticAreaEdit
};

export default resource;
