import {  useEffect } from "react";
import { List, Datagrid, TextField, SearchInput, Filter, EditButton, CreateButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import checkAuthentication from './../common/Authenticate'
import { useNavigate } from 'react-router-dom';
import './../common/style.css'

const PostFilter = (props: JSX.IntrinsicAttributes) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);
export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
} 
const ListActions = () => (
    <CreateButton label="ADD CoE SITE" className='create-button-display'/>
);
const EditActions = () => (
    <EditButton label="" style={{ float: 'right' }}/>
);

const CoeList = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);
    return (
        <List
            {...props}
            title={'CoE Site'}
            actions={<ListActions />}
            filters={<PostFilter />}
            perPage={50}
            pagination={false}
            sort={{ field: 'name', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="name" label="CoE Site" />
                <TextField source="url" label="URL" />
                <EditActions />
                <DeleteActions />
            </Datagrid>
        </List>
    )
};

export {
    CoeList,
}