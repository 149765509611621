import UserIcon from '@mui/icons-material/People';

import { CuratedContentList } from './CuratedContentList';
import {Curate} from './Curate';
// import VisitorEdit from './VisitorEdit';

const resource = {
    list: CuratedContentList,
    create: Curate,
    // edit: VisitorEdit,
    icon: UserIcon,
};

export default resource;
