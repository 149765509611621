import { Fragment, useState } from "react";
import { Create, Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, useNotify, SelectInput, ReferenceInput } from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import dataProvider from '../dataProvider/DataProvider';
import { useNavigate,useParams } from 'react-router-dom';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.title.trim()) {
        errors.title = 'The Therapeutic Area is required';
    }
    if (!values.id) {
        errors.id = 'The Speciality is required';
    }
    return errors;
};


const TherapeuticAreaCreate = (props: any) => {
    const [show, setStatus] = useState(true);
    const navigate = useNavigate();
    const notify = useNotify();

    const postSave = (data: any) => {
       dataProvider.create('addTherapeuticArea', { data }).then((result: any) => {
            if (result.data.status === 'error') {
               notify(`Therapeutic Area ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Created Therapeutic Area Successfully`, { type: 'success' });
                navigate("/therapeutic_area");
           }
        });   

    };
    const CreateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };
    const handleCloseClick = () => {
        setStatus(false)
        navigate("/therapeutic_area");
    }

    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} >
                <DialogTitle>
                    Create New Therapeutic Area
                </DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm id="post_create_form" onSubmit={postSave} sx={{ maxWidth: 600 }} toolbar={<CreateToolbar />} validate={validateForm}>
                            <TextInput
                                autoFocus
                                source="title"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="Therapeutic Area"
                            /> 
                            <ReferenceInput label="Speciality" source="id" reference="speciality">
                                <SelectInput optionText="title" label="Speciality" sx={{ minWidth: 550 }} emptyText='Select Specialty' emptyValue=""  />
                            </ReferenceInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
};
export const editvalidateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.title.trim()) {
        errors.title = 'The Therapeutic Area is required';
    }
    if (!values.specialtyId) {
        errors.specialtyId = 'The Speciality is required';
    }
    return errors;
};
const TherapeuticAreaEdit = (props: any) => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const notify = useNotify();
    const { id } = useParams();
    
    const postUpdate = (data: any) => {
        dataProvider.update('therapeutic_area', { id: id, data, previousData: {} }).then((result: any) => {  
            if (result.data.status === 'error') {
               notify(`Therapeutic Area ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Updated Therapeutic Area Successfully`, { type: 'success' });
                navigate("/therapeutic_area");
           }
        });   
    };

    const UpdateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(!show)
        navigate("/therapeutic_area");
    }

    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} >
                <DialogTitle>
                    Update Therapeutic Area
                </DialogTitle>
                <DialogContent>
                    <Edit title = {"Therapeutic Area"}>
                        <SimpleForm onSubmit={postUpdate} id="post_create_form" sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar />} validate={editvalidateForm}>
                             <TextInput
                                autoFocus
                                source="title"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="Therapeutic Area"
                            />
                            <ReferenceInput label="Speciality" source="specialtyId" reference="speciality">
                                <SelectInput optionText="title" label="Speciality" sx={{ minWidth: 550 }} emptyText='Select Specialty' emptyValue="" defaultValue="specialtyId" />
                            </ReferenceInput>
                        </SimpleForm>
                    </Edit>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
};
export {
    TherapeuticAreaCreate,
    TherapeuticAreaEdit
}
