import * as React from "react";
import { Component } from "react";
import { Grid, Button, Card, CardContent, Box, TextField, MenuItem, CircularProgress, Input, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Title } from 'react-admin';
import './curation.css';
import MainService from './../services/mainService'
import dataProvider from './../dataProvider/DataProvider'
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormatListNumberedRtlRounded } from "@mui/icons-material";
import permission from "../services/permissions";

const uniformResourceUrl = process.env.REACT_APP_CURATION_URL;


export class Curate extends Component<any, any> {
    constructor(props: any) {
        super(props)
        this.state = {
            curationUrl: '',
            title: '',
            description: '',
            specialty: [],
            contentTypes: [],
            coeLocations: [],
            coeSites: [],
            therapeuticArea: [],
            specialtyInput: null,
            contentTypeInput: null,
            coeLocationInput: null,
            coeSiteInput: null,
            therapeuticAreaInput: null,
            cleanUrl: '',
            readableContent: '',
            mozillaReadability: '',
            metaDescription: '',
            showLoader: false,
            curatableDescription: '',
            curatableTitle: '',
            curatableUri: '',
            readableTitle: '',
            readableDescription: '',
            mozillaReadableDescription: '',
            metaData: [],
            showArticleImages: false,
            images: [],
            articleImage: '',
            customImage: '',
            showAlert: false,
            curateSave: false,
            therapeuticFullList: [],
            coeLocationFullList: [],
            isSubmit: false,
            invalidUrl:false,
            isGetcontent:false,
            
            
      
        }
    }
    componentDidMount(): void {
        console.log('-------------',window.location.href.split('?')[1])
        
        this.getSpecialtyData();
        this.getCoeSitesData();
        this.getContentTypeData();
    }
    getSpecialtyData = () => {
        dataProvider.getMany('speciality', {ids:[]}).then((result: any) => {
            this.setState({
                specialty: result.data
            })
        })
    }
    getContentTypeData = () => {
        dataProvider.getMany('mediaTypes', {ids:[]}).then((result: any) => {
            this.setState({
                contentTypes: result.data
            })
        })
    }
    getCoeSitesData = () => {
        const site = window.location.href.split('?')[1];
        dataProvider.getMany('coeSites', {ids:[]}).then((result: any) => {
            if (result?.data) {
                let coeSiteloggedInUserList = [];
                // if (permission.LOGGED_IN_USER_ROLE != permission.ADMIN_ROLE && permission.LOGGED_IN_USER_ROLE != permission.CONTENT_ADMIN_ROLE) {
                if (JSON.parse(localStorage.getItem('auth'))?.userTypeId != process.env.REACT_APP_MICROSITE_ADMIN && JSON.parse(localStorage.getItem('auth'))?.userTypeId != process.env.REACT_APP_CONTENT_ADMINISTRATOR && JSON.parse(localStorage.getItem('auth'))?.userTypeId != process.env.REACT_APP_SUPER_ADMIN) {
                    const usersAllowedList = JSON.parse(localStorage.getItem('auth'))?.coeSites;
                    result.data.map((item: any) => {
                        if (usersAllowedList.includes(item.id)) {
                            coeSiteloggedInUserList.push(item);
                        }
                
                    })
                    let selected = coeSiteloggedInUserList[0].id;
                    if (site !== undefined) {
                       selected =  coeSiteloggedInUserList.filter((obj) => {
                            return obj.name.replace(' ','') === site;
                       })[0]?.id;
                        if (selected == '') {
                           selected = coeSiteloggedInUserList[0].id;
                       }
                    }
                    this.setState({
                        coeSites: coeSiteloggedInUserList,
                        coeSiteInput: selected
                    })
                    this.getCoeSiteLocationsData(selected);
                
                } else {
                    let selected = result.data[0].id;
                    if (site !== undefined) {
                       selected =  result.data.filter((obj) => {
                            return obj.name.replace(' ','') === site;
                       })[0]?.id;
                         if (selected == '') {
                           selected =  result.data[0].id;
                       }
                    }                    
                    this.setState({
                        coeSites: result.data,
                        coeSiteInput: selected                        
                    })
                    this.getCoeSiteLocationsData(selected);
                }
            } else {
                this.setState({
                    coeSites: [],
                })
                
            }

        })
    }
    getTherapeuticAreaData = (id: string) => {
        console.log(id);
        dataProvider.getMany('therapeutic_area',{ids:[id]}).then((result: any) => {
             if(result?.data) {
                    this.setState({
                        therapeuticArea: result.data,
                        });
                }else{
                    this.setState({
                        therapeuticArea: [],
                        });
                }
        })
    }
    getCoeSiteLocationsData = (id:string) => {
        dataProvider.getMany('coeSitesLocation',{ids:[id]}).then((result: any) => {
            if (result?.data) {
                this.setState({
                    coeLocations: result.data
                });
                
            } else {
                this.setState({
                    coeLocations:[]
                })                
            }

        })
    }
   
    validateUrl = (str: any) => {
        let flag: any;
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    }
    onDismiss = () => {
        this.setState({
            showAlert: false,
            showError: false,
            notificationMessage: '',
            showLoader: false
        });
    }
    showArticleImages = () => {
        this.setState({ showArticleImages: !this.state.showArticleImages });
    }
    
    getCuratableContent = () => {

        const apiURL = `${uniformResourceUrl}uniformresource/curatableContent`;
        const pathArray = this.state.curationUrl.split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        const baseUrl = protocol + '//' + host;
        MainService.getDataFromUniformResource(this.state.curationUrl, apiURL).then((response: any) => {
            if (typeof (response?.error) === 'undefined' && !(typeof (response?.terminalResult) !== 'undefined' && typeof (response?.terminalResult.httpStatus) !== 'undefined' && response?.terminalResult.httpStatus === 403)) {
                const imgArray: any = [];
                this.setState({
                    curatableDescription: response?.metaData?.description?response.metaData.description:'',
                    curatableTitle: response?.title,
                    cleanUrl: response?.uri,
                    showLoader: false,
                    title: response?.title,
                    description: response?.metaData?.description?response.metaData.description:'',

                });
                if (response?.metaData) {
                    this.setState({ metaData: response.metaData });
                }
                if (response?.images && response.images.length) {
                    const images: any = [];
                    //removing duplicates
                    (response.images).forEach((el: any) => {
                        if (!images.includes(el)) {
                            images.push(el);
                        }
                    });
                    if (this.state.ogImage) {
                        const tempImg = {
                            src: this.state.ogImage
                        };
                        images.push(tempImg);
                    }
                    images.forEach((image: any) => {
                        if (image.src) {
                            const img = image.src;
                            const validImg = this.validateUrl(img);
                            if (validImg) {
                                if (img.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                                    if (!imgArray.includes(img)) {
                                        imgArray.push(img);
                                    }
                                }
                            } else {
                                let imgPath = '';
                                if (img.charAt(0) === '/') {
                                    imgPath = baseUrl + img;
                                } else {
                                    imgPath = baseUrl + '/' + img;
                                }
                                const validImage = this.validateUrl(imgPath);
                                console.log(baseUrl);
                                if (validImage) {
                                    if (imgPath.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                                        if (!imgArray.includes(imgPath)) {
                                            imgArray.push(imgPath);
                                        }
                                    }
                                }
                            }
                        }
                    });
                    this.setState({ images: imgArray.reverse() });
                } else {
                    this.setState({ images: [] });
                }
            } else {
                let notificationMessage = 'Something went wrong, Please try again later';
                if (response.isInvalidResource) {
                    if (typeof response.remarks === 'undefined') {
                        notificationMessage = 'Please enter a valid URL';
                    } else {
                        notificationMessage = 'Unable to fetch content from this URL';
                    }
                }
                this.setState({
                    showLoader: false,
                    showAlert: true,
                    showError: true,
                    notificationMessage: notificationMessage
                });
            }
        })
    }
    getReadableContent = () => {
        const apiURL = `${uniformResourceUrl}uniformresource/readableContent`;
        MainService.getDataFromUniformResource(this.state.curationUrl, apiURL).then((readableContent: any) => {
            if (readableContent?.mozillaReadability) {
                const content: string = readableContent.mozillaReadability ? (readableContent?.mozillaReadability?.content).replace(/<img[^>]*>/g, "") : "";
                this.setState({
                    readableTitle: readableContent?.mozillaReadability?.title,
                    readableDescription: content,
                    mozillaReadableDescription: readableContent.mozillaReadability,
                    cleanUrl: readableContent.uri,

                });
            }
        })

    }
    curateData = () => {
        this.setState({
            showLoader: true,
            curateSave: false,
            showError: '',
            invalidUrl: false,
            showAlert: false,
            isGetcontent:true,
            isSubmit: false,
       
        });
        const validUrl = this.validateUrl(this.state.curationUrl.trim());
        if (validUrl === false) {
            console.log("here");
            this.setState({
                invalidUrl: true,
                showLoader: false,
            })
        } else {
            this.getCuratableContent();
            this.getReadableContent();
            this.setState({
            title: '',
            description: '',
            contentTypeInput:null,
            specialtyInput:null,
            therapeuticAreaInput:null,
            coeSiteInput:null,
            coeLocationInput: null,
            articleImage: '',
            customImage:''
            })
            this.getCoeSitesData();            
            
        }
        if (this.state.invalidCustomFile===true || this.state.invalidFileSize===true )
        {
            this.setState({
                curateSave: true,
            })
        }

    }
    updateCurationUrl = (e: any) => {
        this.setState({ curationUrl: e.target.value,
        isSubmit:false, });
    }
    replaceWithReadableContent = () => {
        let description: string = '';
        description = this.state.readableDescription;
        this.setState({ description: description });
    }
    appendReadableContent = () => {
        // const readMore = this.getReadMoreUrl(this.state.cleanUrl);
        const readMore = '';
        let description: string = '';
        if (this.state.metaDescription) {
            description = this.state.metaDescription
        }
        if (this.state.readableContent) {
            description = description + '<br/>' + this.state.readableContent + readMore;
        }
        this.setState({ description: description });
    }
    replaceWithReadableContentAndMeta = () => {
        // const readMore = this.getReadMoreUrl(this.state.cleanUrl);
        let description: string = '';
        if (this.state.mozillaReadableDescription) {
            description = `<b>Title: </b>${this.state.title}<br/>`;
            if (this.state.mozillaReadableDescription.excerpt) {
                description = description + `<b>Excerpt: </b>${this.state.mozillaReadableDescription.excerpt}<br/>`;
            }
            if (this.state.mozillaReadableDescription.byline) {
                description = description + `<b>Byline: </b>${this.state.mozillaReadableDescription.byline}<br/>`;
            }
            if (this.state.readableDescription) {
                description = description + this.state.readableDescription;
            }
            // description = description + '<br/><br/>' + readMore;
        }
        this.setState({ description: description });

    }
    clearContent = () => {
        this.setState({ description: '' });
    }
    getReadMoreUrl = (url: string) => {
        let readMoreBaseUrl: any = (url).split('/');
        let readMoreurl = `${readMoreBaseUrl[2]}`;
        readMoreurl = readMoreurl.replace('www.', '');
        const readMore = `<br/><br/><a target="_blank" href=${this.state.cleanUrl}>Read on ${readMoreurl}</a>`;
        return readMore;
    }
    resizeImage = (img: any) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = img;
            image.onload = () => {
                const param = {
                    url: img,
                    width: Number(image.naturalWidth),
                    height: Number(image.naturalHeight),
                }
                MainService.resizeImage(param, uniformResourceUrl + 'api/resizeImage').then((base64Image: any) => {
                    if (base64Image) {
                        resolve(base64Image)
                    } else {
                        resolve(null);
                    }
                });
            }
        });
    }
    setOgImage = async (img: any) => {
        const base64Image = await this.resizeImage(img);
        this.setState({ imgUrl: img,articleImage: base64Image });

    }
    handleCustomImages = async (event: any) => {
        const files = event.target.files;
        const fileInfo: { fileName: any; base64Data: any; }[] = [];
        const allowedTypes = ['.jpeg', '.jpg', '.png'];
        const fileSize = files[0].size;
        this.setState({ invalidCustomFile: false,invalidFileSize:false, invalidCustomFileMessage: '', invalidFileSizeMessage: '',curateSave:false });
        if (fileSize <= 2 * 1000000) {
        let flag = true;
        for (let i = 0; i < files.length; i++) {
            if (flag) {
                const fileName = files[i].name;
                const fileExtension = '.' + fileName.substr(fileName.lastIndexOf('\\') + 1).split('.')[1];
                if (allowedTypes.includes(fileExtension.toLowerCase())) {
                    const base64Data = await this.toBase64(files[i]);
                    this.setState({ customImage: base64Data });
                } else {
                    flag = false;
                }
            }
        }
        if (flag === false) {          

            this.setState({ customInputFiles: [], invalidCustomFile: true, invalidCustomFileMessage: "Only [.jpeg,.jpg,.png] files are allowed",curateSave:true });
            }
            }
        else  {
            if (fileSize > 2000000){
              this.setState({ invalidFileSize: true, invalidFileSizeMessage: 'Image size should be less than 2MB',curateSave:true     });
            }
        }
    }
       
    toBase64 = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    clearFields = () => {
        this.setState({
            title: '',
            curationUrl: '',
            description: '',
            contentTypeInput:null,
            specialtyInput:null,
            therapeuticAreaInput:null,
            coeSiteInput:null,
            coeLocationInput: null,
            articleImage: '',
            customImage:'',
            images:[],
            curateSave: false,
            invalidCustomFileMessage:'',
            invalidFileSizeMessage:'',
           

        })
    }
    submitCuration = () => {
         this.setState({
             curateSave: false,
             showLoader: true,
             isSubmit: true,
             isGetcontent:false,
         })
        const errors = this.validateForm();
        const validUrl = this.validateUrl(this.state.curationUrl.trim())
        if(validUrl=== false)
        {
            this.setState({
                invalidUrl: true,
                showLoader: false,
            })
        }
        else
            this.setState({
            invalidUrl: false ,
            showLoader: true,
        })
        const invalid = Object.keys(errors).some(x => errors[x]);
        if ((!invalid)&& (validUrl)) {      
             window.scrollTo(0, 0);

            const data = {
                'title': this.state.title,
                'description': this.state.description,
                'mediaType': this.state.contentTypeInput,
                'contentUrl': this.state.curationUrl,
                'specialty': this.state.specialtyInput,
                'therapeuticArea': this.state.therapeuticAreaInput,
                'coeSite': this.state.coeSiteInput,
                'coeLocation': this.state.coeLocationInput,
                'curatedBy':JSON.parse(localStorage.getItem('auth')).userId,
                'images': {
                    "articleImage": this.state.articleImage,
                    "customImage": this.state.customImage,
                }


            }
            dataProvider.create('submitCuration', { data }).then((result: any) => {
                if (result.data.status === 'success') {
                    this.setState({
                        notificationMessage: "Content has been added to the curation queue",
                        showAlert: true,
                        curateSave: false,
                        showLoader: false,
                        title: '',
                        curationUrl: '',
                        description: '',
                        contentTypeInput:null,
                        specialtyInput:null,
                        therapeuticAreaInput:null,
                        coeSiteInput:null,
                        coeLocationInput: null,
                        articleImage: '',
                        customImage: '',
                        showError: '',
                        images: [],
                        isSubmit: false,
                    })
                } else {
                    this.setState({
                        notificationMessage: result.data.error,
                        showError: result.data.status,
                        showAlert: true,
                        curateSave: false,
                        showLoader:false
                    })
                }
               
            })
        } else {
                this.setState({
                        notificationMessage: 'Invalid data',
                        showError: 'error',
                        showAlert: true,
                        curateSave: false,
                        showLoader:false
                    })
        }
    }

    validateForm = () => {
    let invalid = false;
    let invalidUrl = false;
    let invalidcoeSiteInput = false;

    if (this.state.curationUrl.trim().length < 1) {
      invalid = true;
      invalidUrl = true;
    }
    if (this.state.coeSiteInput < 1) {
      invalid = true;
      invalidcoeSiteInput = true;
    }

    return {
      title: invalidUrl,
      coeSiteInput: invalidcoeSiteInput
    };
  }

    render() {
        const markError = (field: string) => {  
        const errors = this.validateForm();
        const hasError = errors[field];
        return hasError;
        };
        return (
            <Card>
                <CardContent className="header-card">
                    <Title title='Upload Content' />
                    {/* <Alert severity="error">This is an error alert — check it out!</Alert>
                    <Alert severity="warning">This is a warning alert — check it out!</Alert>
                    <Alert severity="info">This is an info alert — check it out!</Alert>
                    <Alert severity="success">This is a success alert — check it out!</Alert> */}
                    {this.state.showAlert ?
                        <Box component="div" className="curation-alert" mb={1}>
                            <Alert severity={this.state.showError ? "error" : "success"}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={this.onDismiss.bind(this)}
                                    >

                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                            >
                                {this.state.notificationMessage}
                            </Alert>
                        </Box> : null}
                    <Box component="div" className="curated-contents" >
                        <Grid container spacing={1}>
                            <Card className="curation-card" >
                                <CardContent>
                                    <Grid>
                                        <Box ml={4} component="div">
                                            <h3>Add New Content</h3>
                                        </Box>


                                        <Box component="div" className="parse-url-main">
                                            <Grid container>
                                                <Grid item xs={8} ml={4} paddingRight={2}>
                                                    <TextField
                                                        required
                                                        id="url"
                                                        label="Enter URL"
                                                        variant="outlined"
                                                        onChange={this.updateCurationUrl}
                                                        value={this.state.curationUrl}

                                                        fullWidth
                                                    />
                                                     {(this.state.invalidUrl && this.state.isGetcontent) ? <Box component="span" mt={1} className={this.state.invalidUrl ? "curation-validation-error" : "curated-data-hidden"}
                                                    >URL is not valid</Box> : null} 
                                                     {(this.state.isSubmit && this.state.invalidUrl && this.state.curationUrl!='') ? <Box component="span" mt={1} className={this.state.invalidUrl ? "curation-validation-error" : "curated-data-hidden"}
                                                        >URL is not valid</Box> : null} 
                                                    {((this.state.isSubmit) && (this.state.curationUrl==='') ) ? <Box component="span" mt={1} className={markError('title') ? "curation-validation-error" : "curated-data-hidden"}
                                                     >This field cannot be empty </Box> : null}   

                                                    <Box> {
                                                        this.state.showLoader ? <Box component="div" mt={2} className="press-release-loader">
                                                            <CircularProgress />
                                                        </Box> : undefined
                                                    }</Box>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button
                                                        disabled={this.state.curationUrl.length < 1 ? true : false}
                                                        variant="contained" color="primary" size="medium"
                                                        onClick={this.curateData}
                                                    >Get Content from Source
                                                    </Button>

                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={4} ml={4} mr={2}>
                                                    <label>Title</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <TextField label="" id="title"  size="medium" className="form-textfield"
                                                        onChange={(e: any) => this.setState({ title: e.target.value })}
                                                        value={this.state.title} fullWidth />
                                                   
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={3} ml={4} mr={2}>
                                                    <label>Description</label>
                                                </Grid>
                                                <Grid item xs={9}>

                                                    <Grid container>
                                                        <Grid item xs={12} mt={2} mb={1}>
                                                            <Box>
                                                                <CKEditor
                                                                    editor={ClassicEditor}
                                                                    data={this.state.description}
                                                                    onChange={(event, editor) => {
                                                                        let data = editor.getData();
                                                                        data = data.trim();
                                                                        this.setState({
                                                                            description:data
                                                                        })
                                                                    }}

                                                                />

                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                        <Grid item xs={6} mt={1} mr={6}>
                                                            <Box>
                                                                <Button
                                                                    variant="contained" color="primary" size="small" fullWidth onClick={this.replaceWithReadableContent}
                                                                >Replace With Readable Content</Button>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={5} mt={1}>
                                                            <Box>
                                                                <Button
                                                                    variant="contained" color="primary" size="small" fullWidth onClick={this.clearContent}
                                                                >Clear Content</Button>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>


                                            </Grid>
                                        </Box>

                                        <Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={4} ml={4} mr={2}>
                                                    <label>Media Type</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Type"
                                                        value={this.state.contentTypeInput}
                                                        onChange={(e: any) => this.setState({ contentTypeInput: e.target.value })}
                                                        fullWidth
                                                    >
                                                        {
                                                            this.state.contentTypes.map((element: any) => (
                                                                <MenuItem key={element.id} value={element.id}>
                                                                    {element.title}
                                                                </MenuItem>
                                                            ))
                                                        }

                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Box><Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={4} ml={4} mr={2}>
                                                    <label>Speciality</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Speciality"
                                                        value={this.state.specialtyInput}
                                                        onChange={(e: any) => {
                                                            this.setState({ specialtyInput: e.target.value });
                                                            this.getTherapeuticAreaData(e.target.value);
                                                        }}
                                                        fullWidth
                                                    >
                                                        {
                                                            this.state.specialty.map((element: any) => (
                                                                <MenuItem key={element.id} value={element.id}>
                                                                    {element.title}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Box><Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={4} ml={4} mr={2}>
                                                    <label>Therapeutic Area</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="Therapeutic area"
                                                        value={this.state.therapeuticAreaInput}
                                                        onChange={(e: any) => this.setState({ therapeuticAreaInput: e.target.value })}
                                                        fullWidth
                                                    >
                                                        {
                                                            this.state.therapeuticArea.map((element: any) => (
                                                                <MenuItem key={element.id} value={element.id}>
                                                                    {element.title}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Box><Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={4} ml={4} mr={2}>
                                                    <label>CoE Site*</label>
                                                </Grid>
                                                
                                                <Grid item xs={9} mt={2}>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        value={this.state.coeSiteInput}
                                                        
                                                        onChange={(e: any) => {
                                                            this.setState({ coeSiteInput: e.target.value });
                                                            this.getCoeSiteLocationsData(e.target.value);
                                                        }}
                                                        fullWidth
                                                    >
                                                        {
                                                            this.state.coeSites.map((element: any) => (
                                                                <MenuItem key={element.id} value={element.id}>
                                                                    {element.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                        {(this.state.isSubmit) ? <Box component="span" mt={1} className={markError('coeSiteInput') ? "curation-validation-error" : "curated-data-hidden"}
                                                        >This field cannot be empty</Box> : null}  
                                                </Grid>
                                            </Grid>
                                        </Box><Box component="div">
                                            <Grid container>
                                                <Grid item xs={2} mt={4} ml={4} mr={2}>
                                                    <label>CoE Site Location</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <TextField
                                                        id="outlined-select-currency"
                                                        select
                                                        label="location"
                                                        value={this.state.coeLocationInput}
                                                        onChange={(e: any) => this.setState({ coeLocationInput: e.target.value })}
                                                        fullWidth
                                                    >
                                                        {
                                                            this.state.coeLocations.map((element: any) => (
                                                                <MenuItem key={element.id} value={element.id}>
                                                                    {element.location}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {this.state.images.length > 0 ? <Box component="div" mt={2}>
                                            <Grid container>
                                                <Grid item xs={2} mt={2} ml={4} mr={2}>
                                                    <label>Article Images</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <Box component='div' onClick={this.showArticleImages} mb={2}>
                                                        <Box component='div' className="cqa-images-collapse">
                                                            <b>Choose From Article Images</b>
                                                            <Box
                                                                component='span'
                                                                className='assign-to-collapse'
                                                                pl={1}
                                                                mt={2}
                                                            >

                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <div className="clearfix"></div>

                                                    <Box component="div">
                                                        {this.state.images.map((img: any, i: any) => {
                                                            return <Box component="div" key={`img-${i}`} onClick={() => this.setOgImage(img)} className={this.state.imgUrl === img ? "ogImgSelected curation-images-outer" : "curation-images-outer"}>
                                                                <img src={img} alt="" className="curation-images" />
                                                            </Box>
                                                        })}
                                                    </Box>


                                                </Grid>
                                            </Grid>
                                        </Box> : null}
                                        <Box component="div" mt={2}>
                                            <Grid container>
                                                <Grid item xs={2} mt={2} ml={4} mr={2}>
                                                    <label>Custom Image</label>
                                                </Grid>
                                                <Grid item xs={9} mt={2}>
                                                    <Grid item xs={10}>
                                                        <Input onChange={this.handleCustomImages} type="file" id="cqa-custom-image" name="cqa-custom-image" inputProps={{ multiple: false, accept: '.jpeg,.jpg,.png' }} />
                                                        {this.state.invalidCustomFile ? <span className="error-message">{this.state.invalidCustomFileMessage}</span> : <div>Only [.jpeg,.jpg,.png] files are allowed </div>}
                                                        {this.state.invalidFileSize ?
                                                            <span className="error-message">{this.state.invalidFileSizeMessage}</span> 
                                                        :  <div>Image size must be less than 2 mb </div> }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box className="button-save"
                                        // display="flex" justifyContent="space-between"
                                        >
                                            <Grid container>
                                                <Grid item xs={6} mt={2} mr={2}>
                                                    <Box>
                                                        <Button 
                                                            variant="contained" color="primary" size="large" fullWidth onClick={this.clearFields}
                                                        >Clear Fields</Button>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={5} mt={2}>
                                                    <Box>
                                                        <Button
                                                            disabled={this.state.curateSave} variant="contained" color="primary" size="large" fullWidth onClick={this.submitCuration}
                                                        >Save</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        {/* : null} */}
                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>
                    </Box>
                </CardContent>
            </Card>
        )
    }
};

