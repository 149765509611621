import { useState } from 'react';
import { useResourceContext, Button, Confirm, useNotify, useRecordContext, useRefresh } from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import dataProvider from './../dataProvider/DataProvider';

export const DeleteActions = () => {
    const [open, setOpen] = useState(false);
    const notify = useNotify();
    const record = useRecordContext(); 
    const resource = useResourceContext();
    const refresh = useRefresh();
    const id = record.id;
    
    const HandleConfirm = () => {
        
        setOpen(false);
        dataProvider.delete(resource, { id: id }).then((res: any) => { 
            if (res.data.status === 'error') {
               notify(`${record.title || record.name || record.location} ${res.data.error}`, { type: 'error' });
            } else {
                switch (resource) {
                    case 'speciality':
                        notify(`The content in Therapeutic Area which contains this Speciality also got deleted`, { type: 'success' });
                        break;
                    case 'coeSites':
                        notify(`The content in CoE Site Location which contains this CoE Site also got deleted`, { type: 'success' });
                        break;
                    default:
                        notify(`Deleted ${resource} Successfully`, { type: 'success' });
                    
                }
                refresh();
            }
        })
    };
    const handleDialogClose = () => setOpen(false);
    const deleteUser = () => {
        setOpen(true);
    }
    return (
        <><Button  onClick={deleteUser}>
            <DeleteIcon />
        </Button>
            <Confirm
                isOpen={open}
                title="Delete"
                content="Are you sure you want to delete?"
                onConfirm={HandleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
}