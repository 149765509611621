import { useState, useEffect, Fragment } from "react";
import dataProvider from '../dataProvider/DataProvider'
import { Grid, Box, Button, TextField, Card, CardContent, Input, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNotify } from 'react-admin';
import permissions from '../services/permissions';
import { IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Select, Chip } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import '../Curation/curation.css';


const Profile = () => {
    const [show, setStatus] = useState(true);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailId, setEmailId] = useState('');
    const [userType, setUserType] = useState('');
    const [userTypeId, setUserTypeId] = useState('');
    const [coeSiteId, setCoeSiteId] = useState([]);
    const [coeSites, setCoeSites] = useState([]);
    const [pwd, setPwd] = useState('');
    const [confirmpwd, setConfirmPwd] = useState('');
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [showPassword, setShowPassword] = useState("password");
    const [errorMsg, setErrorMsg] = useState(false);
    const [errorMsgFirstName, setErrorMsgFirstName] = useState(false);
    const [errorMsgLastName, setErrorMsgLastName] = useState(false);
    const [hidePwdIcon, setHidePwdIcon] = useState(true);
    const navigate = useNavigate();
    const notify = useNotify();

    useEffect(() => {  
        dataProvider.getOne('users', { id: JSON.parse(localStorage.getItem('auth'))?.userId }).then((result: any) => {
            console.log(result.data)
            setFirstName(result.data.firstName);
            setLastName(result.data.lastName);
            setEmailId(result.data.emailId);
            setUserType(result.data.type);
            setUserTypeId(result.data.typeId);
            setCoeSiteId(result.data.coeSites);
        });
        getCoeSitesData();
    }, []);
   

    const PostEdit = () => {
        
        if (pwd !== confirmpwd) {
            setErrorMsg(true);
        }
        else if (!firstName.trim()) {
            setErrorMsgFirstName(true);
        }
        else if (!lastName.trim()) {
            setErrorMsgLastName(true);
        }
        else {
            setErrorMsg(false);
            const data = {
                firstName: firstName,
                lastName: lastName,
                password: pwd
            }
            dataProvider.update('users', { id: JSON.parse(localStorage.getItem('auth'))?.userId, data, previousData: {} }).then((res: any) => {
                if (res.data.status == 'error') {
                    notify(`User ${res.data.error}`, { type: 'error' });
                } else {
                    localStorage.setItem('user', `${firstName} ${lastName}`);
                    notify(`Updated User Successfully`, { type: 'success' });
                    navigate("/");
                }
            });
        }
    }

    const getCoeSitesData = () => {
        dataProvider.getList('coeSites', {
            pagination: {page: 1, perPage: 1000},
            sort: {field: 'name', order: 'ASC'},
            filter: {},
        }).then((result: any) => {
            if (result?.data) {                
                setCoeSites(result.data);
            }
            else {
                setCoeSites([]);
            }
        })
    }

    const handleClickShowPassword = () => {
        if(showPassword==="password")
        {
            setShowPassword("text")
            setHidePwdIcon(false)
            return;
            
        }
        setShowPassword("password")
        setHidePwdIcon(true)
    }
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    }
    
    const handleCloseClick = () => {
        setStatus(false);
        navigate("/");
    }

    return (
        <Card>
            <CardContent>
                <Box ml={4}><h3>Update Profile</h3></Box>

                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={4} ml={4} mr={2} >
                            <label>Name</label>
                        </Grid>
                        <Grid item xs={8} mt={2}>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <TextField label="" id="title" size="medium" onChange={(e: any) => setFirstName(e.target.value)} value={firstName} fullWidth />
                                    {errorMsgFirstName? <Box component="span" mt={1} className="error-message"> First Name is required</Box> : null}
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>
                                    <TextField label="" id="title" size="medium" className="form-textfield" onChange={(e: any) => setLastName(e.target.value)} value={lastName} fullWidth />
                                    {errorMsgLastName? <Box component="span" mt={1} className="error-message"> Last Name is required</Box> : null}
                            </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                 
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={4} ml={4} mr={2} >
                            <label>Password</label>
                        </Grid>
                        <Grid item xs={8} mt={2}>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
 
                                <FormControl sx={{ width: '34ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword}
                                        value={pwd}
                                        onChange={(e: any) => setPwd(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                            {hidePwdIcon ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                </FormControl>              
                            </Box>
                            <Box flex={1} ml={{ xs: 0, sm: '0.5em' }}>

                                <FormControl sx={{ width: '33.5ch' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword}
                                        value={confirmpwd}
                                        onChange={(e: any) => setConfirmPwd(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                            {hidePwdIcon ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Change Password"
                                    />
                                </FormControl>
                                    {errorMsg? <Box component="span" mt={1} className="error-message">The passwords doesnt match</Box> : null}
                            </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                            
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={4} ml={4} mr={2} >
                            <label>Email</label>
                        </Grid>
                        <Grid item xs={8} mt={2}>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextField label="" id="email" size="medium" className="form-textfield" value={emailId} fullWidth type="email" disabled={true}/>
                            </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={4} ml={4} mr={2} >
                            <label>User Type</label>
                        </Grid>
                        <Grid item xs={8} mt={2}>
                            <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                            <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                <TextField label="" id="type" size="medium" className="form-textfield" value={userType} fullWidth disabled={true}/>
                            </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

                {(userTypeId === permissions.CURATOR_ROLE || userTypeId === permissions.KOL_USER_ROLE) ?
                    (<Box component="div">
                        <Grid container >
                            <Grid item xs={2} mt={4} ml={4} mr={2} >
                                <label>CoE Sites</label>
                            </Grid>
                            <Grid item xs={8} mt={2}>
                                <Box display={{ xs: 'block', sm: 'flex', width: '100%' }}>
                                <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                                    <Select
                                        multiple
                                        value={coeSiteId}
                                        onChange={(e: any) => setCoeSiteId(e.target.value)}
                                        fullWidth
                                        disabled={true}
                                    >
                                    {coeSites.map((element: any) => (
                                            <MenuItem key={element.id} value={element.id}>
                                                {element.name}
                                            </MenuItem>
                                    ))} 
                                    </Select>
                                </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>)
                : null }
                        
                <Box className="profile-update">
                    <Button disabled={disableSaveButton} variant="contained" color="primary" size="large" onClick={PostEdit}>Update</Button> &nbsp;&nbsp;&nbsp;
                    <Button disabled={disableSaveButton} variant="contained" color="primary" size="large" className="curate-pull-left" onClick={handleCloseClick}>Cancel</Button>
                </Box>

            </CardContent>
        </Card>
    )
}
export {
    Profile
}