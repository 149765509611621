import{useEffect} from "react";
import { List, Datagrid, TextField, SearchInput, Filter, EditButton } from 'react-admin';
import checkAuthentication from './../common/Authenticate';
import { useNavigate } from 'react-router-dom';

const PostFilter = (props: JSX.IntrinsicAttributes) => (
    <Filter {...props}>
        <SearchInput source='name' alwaysOn />
    </Filter>
);

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}
const ListActions = () => (
    <div></div>
);
const EditActions = () => (
    <EditButton label="" style={{ float: 'right' }}  />
);

const TypeList = (props: any) => {
    const navigate = useNavigate()
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);    
    return(
        <List
            {...props}
            actions={<ListActions />}
            title={'User Types'}
            exporter={false}
            filters={<PostFilter />}
            filterDefaultValue
            perPage={50}
            pagination={false}
            sort={{ field: 'type', order: 'DESC' }}
        >
            <Datagrid bulkActionButtons={false} >
                <TextField source="type" label="User Type" />
                <EditActions />
            </Datagrid>
        </List>
    )
};

export {
    TypeList
}