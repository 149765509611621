// deno-lint-ignore-file

import { fetchUtils } from 'react-admin';
import permissions from '../services/permissions';
const moment = require('moment');

const httpClient = fetchUtils.fetchJson;
const postgraphileAnonymousUrl = process.env.REACT_APP_GRAPHQL_URL_ANONYMOUS;
const postgraphileUrl = process.env.REACT_APP_GRAPHQL_URL_SHIELDED;
const graphqlProvider = {
    getList: (resource: string, params: any) => {
        let filter = params.filter.name ? (params.filter.name).trim() : "";    
        const { page, perPage } = params.pagination ? params.pagination : 0;
        const offset = (page - 1) * perPage;
        let sortField = params.sort ? params.sort.field : null;
        const sortOrder = params.sort ? params.sort.order : null;
        if (sortField) {
            sortField = (sortField.replace(/([A-Z])/g, '_$1').trim()).toUpperCase();
            sortField = sortField + '_' + sortOrder.toUpperCase();
        }
        let query;
        let responseString = 'users';
        let requestUrl = postgraphileAnonymousUrl;
       
      
        const postQuery = {
            method: 'POST',
            body: '',
            headers: new Headers({ Accept: 'application/json' })
        };
        switch (resource) {
            case 'states':
                responseString = 'states';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                query: `query {
                    states (filter: {statename: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                        nodes {
                        statename
                        id
                        statevalue
                        }
                        totalCount
                    }
                }`
            });
                break;
            case 'providerInfos':
                responseString = 'providerInfos';
                requestUrl = postgraphileAnonymousUrl;
                // postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                let city = "";
                if (params.filter.city != "") {
                    city = `hcpLocality: {equalTo: "${params.filter.city}"}`;
                }
                let providertype = "";
                if (params.filter.providertype != null) {
                    providertype = `hcpDegreeGroupCode: {equalTo: "${params.filter.providertype}"}`;
                }
                query = JSON.stringify({
                query: `query {
                    providerInfos (filter: {firstName: {equalTo: "${params.filter.firstName}"}, lastName: {equalTo: "${params.filter.lastName}"}, hcpState: {equalTo: "${params.filter.state}"}, ${city}, ${providertype}} 
                        offset: ${offset}
                        first: 1
                        orderBy: ${sortField}
                        ) {
                        nodes {
                            cbsaLat
                            cbsaLon
                            cbsaName
                            firstName
                            fullName
                            graduationYear
                            hcoAffiliationId
                            hcoDmcid
                            hcoFlag
                            hcoGraduationYear
                            hcoName
                            hcoStatus
                            hcoSubtype
                            hcpAge
                            hcpClass
                            hcpDegree
                            hcpDegreeCode
                            hcpDegreeGroup
                            hcpDegreeGroupCode
                            hcpDmcid
                            hcpGenderCode
                            hcpGenderIso5218Cd
                            hcpLocality
                            hcpState
                            hcpType
                            isSpecialist
                            lastName
                            medicalSchool
                            medicalSchoolDmcid
                            middleName
                            nationalRank
                            nationalScore
                            networkScore
                            npi
                            postcodeLat
                            postcodeLon
                            practiceGeomQualityId
                            practiceGlid
                            practiceLat
                            practiceLon
                            prefix
                            primarySpecialityCode
                            primarySpecialityDesc
                            primarySpecialityGroup
                            primarySpecialityGroupCode
                            providerId
                            regionalRank
                            suffix
                            veevaId
                        }
                        totalCount
                    }
                }`
            });
                break;
            case 'types':
                responseString = 'userTypes';
                query = JSON.stringify({
                    query: `query {
                userTypes (filter: {type: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                    totalCount
                    nodes {
                    id
                    type
                    createdAt
                    }
                }
                }  `,
                });
                break;
            case 'users':    
                responseString = 'users';
                requestUrl = postgraphileUrl;
                postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                query = JSON.stringify({
                    query: `query {
                users (filter: {firstName: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                    totalCount
                    nodes {
                    id
                    firstName
                    lastName
                    emailId
                    typeId
                    coeSites
                    type
                    status
                    createdAt
                    updatedAt
                    }
                }
                }  `
                });
                break;
            case 'pocnUsers':    
                responseString = 'pocnUsers';
                requestUrl = postgraphileUrl;
                postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                query = JSON.stringify({
                    query: `query {
                pocnUsers (filter: {firstName: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 condition: {userType: "Provider"}
                 ) {
                    totalCount
                    nodes {
                    aboutMe
                    age
                    atnp
                    atpa
                    city
                    connectedPatients
                    connections
                    createdAt
                    createdAtGmt
                    createdAtTz
                    createdBy
                    degree
                    degreeCode
                    degreeGroup
                    degreeGroupCode
                    emailId
                    faxNumber
                    fbProfile
                    firstName
                    followers
                    fullName
                    globalOptOut
                    graduationYear
                    hcoDmcid
                    hcpClass
                    hcpGenderCode
                    hcpType
                    id
                    isSpecialist
                    lastName
                    linkedInProfile
                    medicalSchool
                    medicalSchoolDmcid
                    middleName
                    mobilePhoneNumber
                    npi
                    originalSource
                    phoneNumber
                    pocnAmbassador
                    pocnMember
                    pocnMentee
                    pocnMentor
                    prefix
                    primarySpecialtyCode
                    primarySpecialtyDesc
                    primarySpecialtyGroup
                    primarySpecialtyGroupCode
                    profileTagLine
                    providerId
                    rxAuthority
                    state
                    suffix
                    twitterProfile
                    userId
                    userType
                    veevaId
                    websiteUrl
                    }
                }
                }  `
                });
                break;
            case 'pocnNonProviders':    
                responseString = 'pocnUsers';
                requestUrl = postgraphileUrl;
                postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                query = JSON.stringify({
                    query: `query {
                pocnUsers (filter: {firstName: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 condition: {userType: ""}
                 ) {
                    totalCount
                    nodes {
                    emailId
                    id
                    firstName
                    fullName
                    lastName
                    npi
                    providerId
                    createdAt
                    createdBy
                    mobilePhoneNumber
                    phoneNumber
                    pocnMember
                    prefix
                    primarySpecialtyCode
                    state
                    suffix
                    city
                    age
                    graduationYear
                    hcpGenderCode
                    medicalSchool
                    degree
                    degreeCode
                    degreeGroup
                    linkedInProfile
                    twitterProfile
                    userType
                    userId
                    }
                }
                }  `
                });
                break;
            case 'speciality':
                responseString = 'specialties';
                query = JSON.stringify({
                    query: `query {
                specialties (filter: {title: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                    totalCount
                    nodes {
                    createdAt
                    id
                    title
                    }
                }
                }  `
                });
                break;
            case 'therapeutic_area':
                responseString = 'therapeuticAreas';
                let specialtyFilter = (params.filter.specialtyId!=null) ? `"${params.filter.specialtyId}"` : '';
                if (params.filter.specialtyId!=null) {
                   specialtyFilter=`specialtyId: {equalTo: ${specialtyFilter}}`
                }
                query = JSON.stringify({
                    query: `query {
                 therapeuticAreas (filter: {title: {includesInsensitive: "${filter}"},${specialtyFilter}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                    totalCount
                    nodes {
                        id
                     title
                    specialty
                    specialtyId
                    }
                }
                }  `
                });
                break;
            case 'coeSites':
                responseString = 'coeSites';
                query = JSON.stringify({
                    query: `query {
                 coeSites (filter: {name: {includesInsensitive: "${filter}"}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                    totalCount
                    nodes {
                    createdAt
                    id
                    name
                    url
                    }
                }
                }  `
                });
                break;
            case 'mediaTypes':
                console.log('media types');
                responseString = 'contentTypes';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                    contentTypes (filter: {title: {includesInsensitive: "${filter}"}} 
                    offset: ${offset}
                    first: ${perPage}
                    orderBy: ${sortField}
                    ){
                        nodes {
                        createdAt
                        id
                        title
                        }
                        totalCount
                    }
                    }  `
                });
                break;
            case 'viewCuration':
                responseString = 'curations';
                requestUrl = postgraphileAnonymousUrl;

                let curationstatus = "";
                if (params.filter.status != "" && params.filter.status!= undefined) {
                    curationstatus = `status: {equalTo: "${params.filter.status}"}`;
                }
                let curationDate = "";
                if ( (params.filter.endDate != "" && params.filter.endDate != undefined)) {
                    curationDate = `createdAt: { greaterThanOrEqualTo: "${params.filter.startDate}", distinctFrom: "${params.filter.endDate}"}`
                }
                query = JSON.stringify({
                    query: `query {
                  curations (filter: {title: {includesInsensitive: "${filter}"}, ${curationDate}, ${curationstatus} } 
                  offset: ${offset}
                  first: ${perPage}
                  orderBy: ${sortField}
                  ){
                    nodes {
                    coeSiteName
                    contentUrl
                    createdAt
                    createdByName
                    description
                    id
                    mediaType
                    publish
                    siteLocation
                    specialty
                    status
                    therapeuticArea
                    title
                    therapeuticAreaId
                    coeSiteId
                    specialtyId
                    siteLocationId
                    mediaTypeId
                    }
                    totalCount
                }
                }  `
                });
                break;
            case 'publishedCurations':
                requestUrl = postgraphileAnonymousUrl;
                responseString = 'publishedCurations';
                query = JSON.stringify({
                    query: `query {
                    publishedCurations (filter: {title: {includesInsensitive: "${filter}"}, publishedAt: { greaterThanOrEqualTo: "${params.filter.startDate}", distinctFrom: "${params.filter.endDate}"}, } 
                    offset: ${offset}
                    first: ${perPage}
                    orderBy: ${sortField}
                    ){
                        nodes {
                        publishedAt
                        id
                        mediaType
                        iid
                        siteLocation
                        specialty
                        status
                        therapeuticArea
                        title
                        createdAt
                        contentUrl
                        coeSiteName
                        }
                        totalCount
                    }
                }  `
                });
                break;
            case 'coeSitesLocation':
                let coeSiteIdFilter = (params.filter.coeSiteId) ? `"${params.filter.coeSiteId}"` : '';
                if (params.filter.coeSiteId) {
                   coeSiteIdFilter=`coeSiteId: {equalTo: ${coeSiteIdFilter}}`
                }
                
                responseString = 'coeLocations';
                query = JSON.stringify({
                    query: `query {
                 coeLocations (filter: {location: {includesInsensitive: "${filter}"},${coeSiteIdFilter}} 
                 offset: ${offset}
                 first: ${perPage}
                 orderBy: ${sortField}
                 ) {
                    totalCount
                    nodes {                    
                    location,
                    coeSite,
                    coeSiteId,
                    id
                    }
                }
                }  `
                });
                break;
            case 'userCoeSites': 
                requestUrl = postgraphileUrl;
                postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                responseString = 'userCoeSites';
                query = JSON.stringify({
                    query: `query {
                        userCoeSites(filter: {iid: {equalTo: "${params.filter.userId}"}}) {
                            nodes {
                            coeSites
                            firstName
                            id
                            iid
                            }
                        }
                        }`
                });
                break;                
            default:
                requestUrl = postgraphileAnonymousUrl;
                const curation_filters = resource.split('/');
                const site = curation_filters[2].replace(" ", '')
                const status = curation_filters[3];
                filter = params.filter.title ? (params.filter.title).trim() : "";
                let filterString = `filter: {
                        status: { equalTo: "${status}" }
                        searchFieldName: { equalTo: "${site.toLowerCase()}" }
                        title: {includesInsensitive: "${filter}"}
                        }`;
                if (status === 'all') {
                    filterString = `filter: {                       
                        searchFieldName: { equalTo: "${site.toLowerCase()}" }
                        title: {includesInsensitive: "${filter}"}
                    }`
                };
                // if (status === 'published') {
                //     filterString = `filter: {
                //         status: { equalTo: "approved" }
                //         publish: { equalTo: true }
                //         searchFieldName: { equalTo: "${site.toLowerCase( )}" }
                //         }`;
                // };
                responseString = 'curations';
                query = JSON.stringify({
                    query: `query{
                    curations (${filterString}
                        offset: ${offset}
                        first: ${perPage}
                        orderBy: ${sortField})  {
                        nodes {
                        id
                        title
                        therapeuticAreaId
                        therapeuticArea
                        status
                        specialtyId
                        specialty
                        siteLocationId
                        siteLocation
                        publish
                        mediaTypeId
                        mediaType
                        description
                        createdByName
                        createdBy
                        coeSiteName
                        contentUrl
                        coeSiteId
                        approvedByName
                        approvedBy
                        createdAt
                        }
                        totalCount
                    }
                    }
                    `
                })

        }

        postQuery.body = query


        return httpClient(requestUrl, postQuery).then(
            ({ headers, json }) =>
                new Promise((resolveData, reject) => {
                    resolveData({
                        data: json.data[responseString]['nodes'],
                        total: json.data[responseString].totalCount
                    });
                }));
    },
    create: (resource: string, params: any) => {
        let mutation = '';
        let mutationResponse = 'addCoeSite';
        let responseString = 'masterResponse';
        if (resource === 'pocnSignupUserIam') { 
            console.log(params.data.specialty)
            let npi = "";
            if (params.data.npi != "" && params.data.npi != undefined) {
                npi = `npi: "${params.data.npi}"`;
            }
            let specialty = "";
            if (params.data.specialty != null) {
                specialty = `primarySpeciality: "${params.data.specialty}"`;
            }
            let leadSource = "";
            if (params.data.leadSource != "") {
                leadSource = `leadSource: "${params.data.leadSource}"`;
            }
            mutation = JSON.stringify({
                query:  `mutation {
                    pocnSignupUserIam(
                            input: {
                            signupUserInput: {
                                email: "${params.data.emailId}"
                                firstname: "${params.data.firstName}"
                                lastname: "${params.data.lastName}"
                                password: "${params.data.password}"
                                ${npi}
                                ${specialty}
                                graduationYear: ${params.data.graduationYear}
                            }
                            signupUserLogInput: {
                                ${leadSource}
                                userType: "Provider"
                                optStatus:"1"
                                channel: ""
                                ipAddress: ""
                                medium: ""
                                regProgram: ""
                                utmMedium: ""
                                utmSource: ""
                            }
                            notes: "${params.data.notes}"
                            }
                        ) {
                            signupUserIamResult {
                                data
                                error
                                message
                                status
                            }
                        }
                    }  `
            });

            const postQuery = {
                method: 'POST',
                body: mutation,
                // headers: new Headers({ Accept: 'application/json' })
            };

            return httpClient(postgraphileAnonymousUrl, postQuery).then(
                ({ headers, json }) =>
                    new Promise((resolveData, reject) => {
                        resolveData({
                            data: json.data.pocnSignupUserIam.signupUserIamResult
                        });
                    }))
        }

        if (resource === 'pocnRegisterUser') { 
            mutation = JSON.stringify({
                query:  `mutation {
                    pocnRegisterUser(
                        input: {
                        signupUserInput: {
                            email: "${params.data.emailId}"
                            firstname: "${params.data.firstName}"
                            lastname: "${params.data.lastName}"
                            notes: "${params.data.notes}"
                            password: "${params.data.password}"
                        }
                        }
                    ) {
                        registerUserResult {
                        data
                        error
                        message
                        status
                        }
                    }
                    }  `
            });

            const postQuery = {
                method: 'POST',
                body: mutation,
                // headers: new Headers({ Accept: 'application/json' })
            };

            return httpClient(postgraphileAnonymousUrl, postQuery).then(
                ({ headers, json }) =>
                    new Promise((resolveData, reject) => {
                        resolveData({
                            data: json.data.pocnRegisterUser.registerUserResult
                        });
                    }))
        }

        switch (resource) {
            case 'coeAdd':
                mutationResponse = 'addCoeSite';
                mutation = JSON.stringify({
                    query: `mutation {
                    addCoeSite(input: {siteName: "${params.data.name}", siteUrl: "${params.data.url}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `
                });
                break;
            case 'addCoeLocation':
                mutationResponse = 'addCoeLocation';
                mutation = JSON.stringify({
                    query: `mutation MyMutation {
                    addCoeLocation(input: {coeLocationName: "${params.data.location}", coeSiteId: "${params.data.coeSiteId}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `
                });
                break;
            case 'addContentTypes':
                mutationResponse = 'addContentType';
                mutation = JSON.stringify({
                    query: `mutation MyMutation {
                    addContentType(input: { cntTitle: "${params.data.title}"}){
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `
                });
                break;
            case 'addTherapeuticArea':
                mutationResponse = 'addTherapeuticArea';
                mutation = JSON.stringify({
                    query: `mutation MyMutation {
                    addTherapeuticArea(input: {thSpecialty: "${params.data.id}", thTitle: "${params.data.title}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `
                });
                break;
            case 'addSpeciality':
                mutationResponse = 'addSpecialty';
                mutation = JSON.stringify({
                    query: `mutation MyMutation {
                        addSpecialty(input: {spTitle: "${params.data.title}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `
                });
                break;
            case 'addUserType':
                mutationResponse = 'addUserType';
                mutation = JSON.stringify({
                    query: `mutation MyMutation {
                        addUserType(input: {userType: "${params.data.type}"}) {
                            masterResponse {
                            error
                            status
                            statusmessage
                            }
                        }
                    }  `
                });
                break;
            // deno-lint-ignore no-case-declarations
            case 'createNewUser':
                let relation = '';
                 if (params.data.name) {
                    relation = `userCoeRelation: ${JSON.stringify(params.data.name)}`;
                } else {
                    relation = `userCoeRelation:[]`;
                }
                mutationResponse = 'createNewUser';
                responseString = 'userResponse';
                mutation = JSON.stringify({
                    query: `mutation MyMutation {
                        createNewUser(
                            input: {
                            coeUserInput: {
                                emailId: "${params.data.emailId}"
                                firstName: "${params.data.firstName}"
                                lastName: "${params.data.lastName}"
                                password: "${params.data.password}"
                                type: "${params.data.id}"
                                ${relation}
                            }
                            }
                        ) {
                            userResponse {
                            status
                            error
                            statusmessage
                            }
                        }
                        }  `,
                    variables: {}
                });
                break;
            case 'submitCuration':
                mutationResponse = 'contentCuration';
                responseString = 'curationResponse'
                mutation = JSON.stringify({
                    query: `mutation{
                contentCuration(
                    input: {
                curationInput: {
                    title: "${params.data.title}"
                    therapeuticArea: "${params.data.therapeuticArea}"
                    specialty: "${params.data.specialty}"
                    mediaType: "${params.data.mediaType}"
                    description: "${btoa(unescape(encodeURIComponent(params.data.description)))}"
                    createdBy: "${params.data.curatedBy}"
                    contentUrl: "${params.data.contentUrl}"
                    coeSite:"${params.data.coeSite}"
                    coeLocation:"${params.data.coeLocation}"
                    image: { articleImage: "${params.data.images.articleImage}", customImage: "${params.data.images.customImage}" }
                }
                }
            ) {
                clientMutationId
                curationResponse {
                error
                status
                statusmessage
                }
            }
            }
            `
                })

        }
        const postQuery = {
            method: 'POST',
            body: mutation,
            headers: new Headers({ Accept: 'application/json' })
        };
        // postQuery.headers.set('Authorization', `Bearer ${localStorage.getItem('authToken')}`);

        postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
        return httpClient(postgraphileUrl, postQuery).then(
            ({ headers, json }) =>
                new Promise((resolveData, reject) => {
                    resolveData({
                        data: json.data[mutationResponse][responseString]

                    });
                }))

    },
    getOne: (resource: string, params: any) => {
        let query = '';
        let responseString = '';
        let requestUrl = postgraphileAnonymousUrl;
        const postQuery = {
            method: 'POST',
            body: '',
            headers: new Headers({ Accept: 'application/json' })
        };
        switch (resource) {
            case 'speciality':
                responseString = 'specialties';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        specialties(filter: { id: { equalTo: "${params.id}" } }) {
                            nodes {
                            id
                            title
                            }
                        }
                        } `,
                    variables: {}
                });                
                break;
            case 'viewCuration':
                responseString = 'curations';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        curations (filter: { id: { equalTo: "${params.id}" } }) {
                            nodes {
                            description
                            status
                            approvedBy
                            approvedByName
                            coeSiteId
                            coeSiteName
                            contentUrl
                            createdAt
                            createdAtTz
                            createdBy
                            createdByName
                            id
                            mediaType
                            mediaTypeId
                            publish
                            siteLocation
                            siteLocationId
                            specialty
                            therapeuticArea
                            therapeuticAreaId
                            title
                            specialtyId
                            status
                            publish
                            }
                        }
                        } `
                });                
                break;
            case 'getArticleImage':
                responseString = 'curationImagesLists';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                            curationImagesLists(condition: {id: "${params.id}"}) {
                                nodes {
                                    articleImage
                                    contentUrl
                                    customImage
                                    articleImageId
                                    customImageId
                                    id
                                    title
                                }
                            }
                        } `,
                    variables: {}
                });                
                break;
            case 'coeSites':
                responseString = 'coeSites';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        coeSites(filter: { id: { equalTo: "${params.id}" } }) {
                            nodes {
                            id
                            name
                            url
                            }
                        }
                        } `
                });                
                break;
            case 'coeSitesLocation':
                responseString = 'coeLocations';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        coeLocations(filter: { id: { equalTo: "${params.id}" } }) {
                            nodes {                        
                            id
                            location
                            coeSiteId
                            }
                        }
                        } `
                });
                break;
            case 'types':
                responseString = 'userTypes';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        userTypes(filter: { id: { equalTo: "${params.id}" } }) {
                            nodes {
                            id
                            type
                            }
                        }
                        } `
                });
                break;
            case 'therapeutic_area':
                responseString = 'therapeuticAreas';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        therapeuticAreas (filter: { id: { equalTo: "${params.id}" } }){
                            nodes {
                            id
                            specialty
                            specialtyId
                            title
                            }
                        }
                        } `,
                    variables: {}
                });                
                break;
            case 'mediaTypes':
                console.log('ddddd');
                responseString = 'contentTypes';
                requestUrl = postgraphileAnonymousUrl;
                query = JSON.stringify({
                    query: `query {
                        contentTypes (filter: { id: { equalTo: "${params.id}" } }){
                            nodes {
                            createdAt
                            id                        
                            title
                            }
                        }
                        } `,
                    variables: {}
                });
                break;
            case 'pocnUsers':
                responseString = 'pocnUsers';
                requestUrl = postgraphileUrl;
                postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                query = JSON.stringify({
                    query: `query {
                        pocnUsers (filter: { id: { equalTo: ${params.id} } }) {
                            nodes {
                            aboutMe
                            age
                            atnp
                            atpa
                            city
                            connectedPatients
                            connections
                            createdAt
                            createdAtGmt
                            createdAtTz
                            createdBy
                            degree
                            degreeCode
                            degreeGroup
                            degreeGroupCode
                            emailId
                            faxNumber
                            fbProfile
                            firstName
                            followers
                            fullName
                            globalOptOut
                            graduationYear
                            hcoDmcid
                            hcpClass
                            hcpGenderCode
                            hcpType
                            id
                            isSpecialist
                            lastName
                            linkedInProfile
                            medicalSchool
                            medicalSchoolDmcid
                            middleName
                            mobilePhoneNumber
                            npi
                            originalSource
                            phoneNumber
                            pocnAmbassador
                            pocnMember
                            pocnMentee
                            pocnMentor
                            prefix
                            primarySpecialtyCode
                            primarySpecialtyDesc
                            primarySpecialtyGroup
                            primarySpecialtyGroupCode
                            profileTagLine
                            providerId
                            rxAuthority
                            state
                            suffix
                            twitterProfile
                            userId
                            userType
                            veevaId
                            websiteUrl
                            }
                        }
                        } `
                });
                break;   
            case 'users':
                responseString = 'users';
                requestUrl = postgraphileUrl;
                postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
                query = JSON.stringify({
                    query: `query {
                        users (filter: { id: { equalTo: "${params.id}" } }) {
                            nodes {
                            createdAt
                            createdAtGmt
                            createdAtTz
                            displayName
                            emailId
                            firstName
                            id
                            lastName
                            status
                            typeId
                            coeSites
                            type
                            updatedAt
                            updatedAtGmt
                            updatedAtTz
                            }
                        }
                        } `
                });
                break;

        }
        postQuery.body = query;
        // postQuery.headers.set('Authorization', `Bearer ${localStorage.getItem('authToken')}`);

        return httpClient(requestUrl, postQuery).then(
            ({ headers, json }) =>
                new Promise((resolveData, reject) => {
                    resolveData({
                        data: json.data[responseString]['nodes'][0],
                });
            }))        
        

    },
    // deno-lint-ignore no-explicit-any

    update: (resource: string, params: any) => {
    let mutation = '';
    let mutationResponse = 'masterResponse';
    let responseString = 'addSpecialty';
    switch (resource) {
        case 'speciality':
            responseString = 'addSpecialty';
            mutation = JSON.stringify({
                query: `mutation  {
                    addSpecialty(input: { spId: "${params.data.id}", spTitle: "${params.data.title}"}) {
                    masterResponse {
                    status
                    error
                    statusmessage
                    }
                }
                    }  `,
            });
        break;
        case 'coeSitesLocation':
            responseString = 'addCoeLocation';
            mutation = JSON.stringify({
                query: `mutation  {
                    addCoeLocation(input: {coeLocationId: "${params.id}", coeLocationName: "${params.data.location}", coeSiteId: "${params.data.coeSiteId}"}) {
                    masterResponse {
                    status
                    statusmessage
                    error
                    }
                }
                }  `,
                });
                break;
            case 'users':
                responseString = 'updateCoeUser';
            mutationResponse = 'userResponse';
            console.log(params.data.status)
                let pwd = "";
                if (params.data.password != "") {
                    pwd = `password: "${params.data.password}"`;
                }
                let coesite = "";
                if (params.data.coeSites != "" && params.data.coeSites != undefined) {
                    coesite = `userCoeRelation: ${JSON.stringify(params.data.coeSites)}`;
                }
                let status = "";
                if (params.data.status != undefined) {
                    status = `status: ${params.data.status}`;
                }
            console.log(status)
                let type = "";
                if (params.data.typeId != "" && params.data.typeId != undefined) {
                    type = `type: "${params.data.typeId}"`;
                }
                mutation = JSON.stringify({
                    query: `mutation  {
                    updateCoeUser(input: {coeUserInput: {firstName: "${params.data.firstName}", 
                        lastName: "${params.data.lastName}",
                        ${type},
                        ${coesite}, 
                        ${status},
                        ${pwd}}, updateUserId: "${params.id}"})
                    {
                    userResponse {
                    error
                    status
                    statusmessage
                    }
                }
                }  `,
            });
            break;
        case 'mediaTypes':
            responseString = 'addContentType';
            mutation = JSON.stringify({
                query: `mutation  {
                    addContentType(input: {cntId: "${params.data.id}", cntTitle: "${params.data.title}"}) {
                    masterResponse {
                    status
                    error
                    statusmessage
                    }
                }
                }  `,
            });
            break;
        case 'types':
            responseString = 'addUserType';
            mutation = JSON.stringify({
                query: `mutation  {
                    addUserType(input: {typeId: "${params.data.id}", userType: "${params.data.type}"}) {
                    masterResponse {
                    status
                    error
                    statusmessage
                    }
                }
                }  `,
            });
            break;
        case 'therapeutic_area':
            responseString = 'addTherapeuticArea';
            mutation = JSON.stringify({
                query: `mutation  {
                 addTherapeuticArea(input: {thId: "${params.id}", thSpecialty: "${params.data.specialtyId}", thTitle: "${params.data.title}"}) {
                    masterResponse {
                    status
                    error
                    statusmessage
                    }
                }
                }  `,
            });
            break;
        case 'coeSites':
            responseString = 'addCoeSite';
            mutation = JSON.stringify({
                query: `mutation  {
                 addCoeSite(input: {siteId: "${params.data.id}", siteName: "${params.data.name}", siteUrl: "${params.data.url}"})
                 {
                    masterResponse {
                    error
                    status
                    statusmessage
                    }
                }
                }  `,
            });
            break;
        case 'updateCuration':
            responseString = 'updateCuration';
            mutationResponse = 'curationResponse';
            mutation = JSON.stringify({
                query: `mutation  {
                 updateCuration(
                    input: {curationInputId: "${params.id}", 
                    curationInput: {coeLocation: "${params.data.coeLocation}", 
                    coeSite: "${params.data.coeSite}",  
                    description: "${params.data.description}", 
                    mediaType: "${params.data.mediaType}", 
                    specialty: "${params.data.specialty}", 
                    therapeuticArea: "${params.data.therapeuticArea}", 
                    title: "${params.data.title}",
                    status: "${params.data.status}",
                    updatedBy: "${JSON.parse(localStorage.getItem('auth'))?.userId}",
                    contentUrl:"${params.data.contentUrl}",
                    publish:${params.data.publish}}}
                ) {
                    curationResponse {
                    status
                    statusmessage
                    error
                    }
                }
                }  `,
            });
            break;
        case 'updateImage':
            responseString = 'updateCurationImage';
            mutationResponse = 'curationResponse';
            mutation = JSON.stringify({
                query: `mutation{
                    updateCurationImage(
                        input: { curationInputId: "${params.id}",curationInput: { articleImage: "${params.data.articleImage}", customImage: "${params.data.customImage}" } }
                    ) {
                        curationResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }
                    `
                });
        }
        const postQuery = {
            method: 'POST',
            body: mutation,
            headers: new Headers({ Accept: 'application/json' })
        };
        // postQuery.headers.set('Authorization', `Bearer ${localStorage.getItem('authToken')}`);
        postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
        return httpClient(postgraphileUrl, postQuery).then(
            ({ headers, json }) =>
                new Promise((resolveData, reject) => {
                    let response = json.data[responseString][mutationResponse];
                    response.id = params.data.id;
                    resolveData({
                        data: response
                    });
                }))
    },


  
    getMany: (resource: string, params: any) => {
        let query = '';
        let responseString;
        let requestUrl = postgraphileAnonymousUrl;
        const postQuery = {
            method: 'POST',
            body: '',
            headers: new Headers({ Accept: 'application/json' })
        };
        switch (resource) {
            case 'status':
                responseString = 'curationStatuses';
                query = JSON.stringify({
                    query: `query {
                        curationStatuses {
                            nodes {
                            id
                            status
                            displayStatus
                            }
                        }
                        }
                        `
                });
                break;
            case 'types':
                responseString = 'userTypes';
                query = JSON.stringify({
                    query: `query {
                userTypes  {                    
                    nodes {
                    id
                    type
                    }
                }
                }  `,
                });
                break;
            case 'coeSites':
                responseString = 'coeSites';
                query= JSON.stringify({
                    query: `query {
                    coeSites {
                    nodes {
                    createdAt
                    id
                    name
                    url
                    }
                }
                }  `
                });
                break;
            case 'speciality':
                responseString = 'specialties';
                query = JSON.stringify({
                query: `query {
                    specialties  {
                        nodes {
                        createdAt
                        id
                        title
                        }
                }
                }  `
                });
                break;
            case 'pocnSpeciality':
                responseString = 'userSpecialties';
                query = JSON.stringify({
                query: `query {
                    userSpecialties  {
                        nodes {
                        mappedValue
                        mappedKey
                        id
                        slug
                        }
                }
                }  `
                });
                break;
            case 'states':
                responseString = 'states';
                query = JSON.stringify({
                query: `query {
                    states {
                        nodes {
                        statename
                        id
                        statevalue
                        }
                        totalCount
                    }
                }`
            });
                break;
            case 'therapeutic_area':
                responseString = 'therapeuticAreas';
                let areaFilter = '';
                if (params.ids[0]!=null) {
                    areaFilter = `filter: { specialtyId: { in: "${params.ids}" } }`;
                } else {
                    areaFilter = `filter: { specialtyId: { in: [] } }`;
                }
                query = JSON.stringify({
                    query: `query {
                 therapeuticAreas(${areaFilter}) {
                    totalCount
                    nodes {
                     id
                     title
                     specialty
                     specialtyId
                    }
                }
                }  `
                });
                break;
            case 'mediaTypes':
                responseString = 'contentTypes';
                query = JSON.stringify({
                    query: `query {
                    contentTypes{
                        nodes {
                        createdAt
                        id
                        title
                        }
                        totalCount
                    }
                    }  `
                });
                break;
            case 'coeSitesLocation': 
                responseString = 'coeLocations';
                let coeSiteLocationFilter;
                console.log('coeSiteLocationFilter',params.ids)
                if (params.ids[0] != null) {
                    coeSiteLocationFilter = `filter: { coeSiteId: { in: "${params.ids}" } }`;
                } else {
                    coeSiteLocationFilter = `filter: { coeSiteId: { in:[] } }`;
                }
                query = JSON.stringify({
                    query: `query {
                 coeLocations(${coeSiteLocationFilter}) {
                    totalCount
                    nodes {                    
                    location,
                    coeSite,
                    coeSiteId,
                    id
                    }
                }
                }  `
                });
                break;            
            
        }

        postQuery.body = query;

        return httpClient(requestUrl, postQuery).then(
            ({ headers, json }) =>
                new Promise((resolveData, reject) => {
                    resolveData({
                        data: json.data[responseString]['nodes']
                    });
                }))
    },

    delete: (resource: string, params: any) => {
    let mutation = '';
    let mutationResponse = 'curationResponse';
        let responseString = 'deleteCuration';
        const userId = permissions.LOGGED_IN_USER_ID;
        switch (resource) {
            case 'deleteCurationImage':
                responseString = 'deleteCurationImage';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteCurationImage(input: {imgId: ${Number(params.id)}}) {
                        curationResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });                
                break;
            case 'deleteCuration':
                responseString = 'deleteCuration';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteCuration(input: {curationInputId: "${params.id}"}) {
                        curationResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
            case 'users':
                responseString = 'deleteUser';
                mutationResponse = 'userResponse';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteUser(input: { coeUserInputId: "${params.id}" }) {
                        userResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
            case 'mediaTypes':
                responseString = 'deleteMediaTypes';
                mutationResponse = 'masterResponse';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteMediaTypes(input: {mediaTypeId: "${params.id}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
            case 'therapeutic_area':
                responseString = 'deleteTherapeuticArea';
                mutationResponse = 'masterResponse';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteTherapeuticArea(input: {therapeuticAreaId: "${params.id}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
            case 'coeSites':
                responseString = 'deleteCoeSites';
                mutationResponse = 'masterResponse';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteCoeSites(input: {coeSiteId: "${params.id}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
            case 'coeSitesLocation':
                responseString = 'deleteCoeLocation';
                mutationResponse = 'masterResponse';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteCoeLocation(input: {coeLocationId: "${params.id}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
            case 'speciality':
                responseString = 'deleteSpeciality';
                mutationResponse = 'masterResponse';
                mutation = JSON.stringify({
                    query: `mutation  {
                    deleteSpeciality(input: {specialtyId: "${params.id}"}) {
                        masterResponse {
                        error
                        status
                        statusmessage
                        }
                    }
                    }  `,
                });
                break;
        }
        const postQuery = {
            method: 'POST',
            body: mutation,
            headers: new Headers({ Accept: 'application/json' })
        };
        // postQuery.headers.set('Authorization', `Bearer ${localStorage.getItem('authToken')}`);
        postQuery.headers.set('Authorization', `Bearer ${JSON.parse(localStorage.getItem('jwtToken'))?.value}`);
        return httpClient(postgraphileUrl, postQuery).then(
            ({ headers, json }) =>
                new Promise((resolveData, reject) => {
                    let response = json.data[responseString][mutationResponse];
                    resolveData({
                        data: response
                    });
                }))
    }

}
export default graphqlProvider;
