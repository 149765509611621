import React, { FC, useEffect, useState } from 'react';
import {  useAuthenticated } from 'react-admin';
import { Grid, Button, Card, CardContent, Box, CircularProgress } from '@mui/material';
import {
    useParams,
} from "react-router-dom";
import dataProvider from './../dataProvider/DataProvider';
import { useNavigate } from "react-router-dom";
import './curation.css';
const showdown = require('showdown');
const converter = new showdown.Converter();


const ViewArticle: FC = () => {
    useAuthenticated();
    const { id } = useParams();
    const [showLoader, setShowLoader] = useState(true);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [mediaType, setMediaType] = useState(null);
    const [specialty, setSpecialty] = useState(null);
    const [therapeuticArea, setTherapeuticArea] = useState(null);
    const [coeSiteTitle, setCoeSiteTitle] = useState('');
    const [siteLocation, setSiteLocation] = useState(null);
    const [submittedBy, setSubmittedBy] = useState('');
    const [curateStatus, setCurateStatus] = useState('');
    const [articleImage, setArticleImage] = useState(null);
    const [customImage, setCustomImage] = useState(null);  

     useEffect(() => {
         getCuratedData();
         getArticleImage();
     }, []);
    
const getCuratedData = () => {
        dataProvider.getOne('viewCuration', { id: id }).then((result: any) => {
            if (result.data) {
                const description = decodeURIComponent(escape(atob(result.data.description)));
                const htmlDescription = converter.makeHtml(description);
                setTitle(result.data.title);
                setUrl(result.data.contentUrl);
                setDescription(htmlDescription);
                setMediaType(result.data.mediaType);
                setSpecialty(result.data.specialty);
                setTherapeuticArea(result.data.therapeuticArea);               
                setCoeSiteTitle(result.data.coeSiteName);
                setSiteLocation(result.data.siteLocation);
                setCurateStatus(result.data.status);
                setSubmittedBy(result.data.createdByName);
            }
            setShowLoader(false);
        })
}
    const Capitalize=(str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const getArticleImage = () => {
        dataProvider.getOne('getArticleImage', { id: id }).then((result: any) => {
          
            if (result?.data) {
                if (result.data.articleImage != null) {
                    setArticleImage(atob(result.data.articleImage));  
                }
                 if (result.data.customImage != null) {
                     setCustomImage(atob(result.data.customImage));  
                }

            }
        })
    }
    const navigate = useNavigate();
    const handleCloseClick = () => {
        navigate(`/curated/${coeSiteTitle.replace(" ", '')}/all`);
    }

    return (
        
        <Card>
            
            <CardContent className="edit-curation-card">
                {
                    showLoader ? <Box component="div" className="press-release-loader">
                        <CircularProgress />
                    </Box> : 
                
                <Box ml={4} component="div">
                    <h3 >{title}</h3>
                </Box>
                }   
             
               <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={4} ml={4} mr={10} >
                            <label>URL</label>
                        </Grid>
                        <Grid item xs={8} mt={2} className="article-view-badge">
                            {url}
                        </Grid>
                    </Grid>
                </Box> 
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>Description</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-scroll-badge">
                           <Box component="div" dangerouslySetInnerHTML={{ __html: description }}></Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>Media Type</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {mediaType}
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div"> 
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>Speciality</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {specialty}
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>Therapeutic Area</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {therapeuticArea}
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>CoE Site</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {coeSiteTitle}
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>CoE Site Location</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {siteLocation}
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} >
                            <label>Status</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {Capitalize(curateStatus)}
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container >
                        <Grid item xs={2} mt={6} ml={4} mr={10} mb={2} >
                            <label>Created By</label>
                        </Grid>
                        <Grid item xs={8} mt={4} className="article-view-badge">
                            {submittedBy}
                        </Grid>
                    </Grid>
                </Box>
               {articleImage||customImage ?(
                    <Box component="div" >
                        <Grid container >
                            <Grid item xs={2} mt={6} ml={4} mr={10} mb={2} >
                                <label>Image</label>
                            </Grid>
                            <Grid item xs={8} mt={4} className="article-view-badge">
                            
                                {(articleImage) ? (
                                    <Box component="div" className='curation-image-box'>
                                        <Box component="div" mt={1} className='curation-images-outer'>
                                            <img src={articleImage} alt="Article Image" className="curation-images" />
                                        </Box>
                                        <Box component="div" className='curation-image-title'>
                                            Article Image
                                        </Box>                                     

                                    </Box>) : null}
                                   
                            {(customImage) ? (
                                    <Box component="div" className='curation-image-box'>
                                        <Box component="div" mt={1} className='curation-images-outer'>
                                            <img src={customImage} alt="Article Image" className="curation-images" />
                                        </Box>
                                        <Box component="div" className='curation-image-title'>
                                            Custom Image
                                        </Box>                                     

                                    </Box>) : null}
                                
                            </Grid>
                        </Grid>
                    </Box>)
                : null}
           
                <Box className="cancel-button">
                    <Button
                        variant="contained" color="primary" size="large" className="curate-cancel-right"
                        onClick={handleCloseClick}
                    >Back</Button>                    
                </Box>

            </CardContent>
        </Card>

    );
}
export default ViewArticle;