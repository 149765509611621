import { Fragment, useState } from "react";
import { Create,Edit, TextInput, required, SimpleForm, Toolbar, SaveButton, useNotify } from 'react-admin';
import dataProvider from '../dataProvider/DataProvider';
import { Button, Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useNavigate,useParams } from 'react-router-dom';

export const validateForm = (
    values: Record<string, any>
): Record<string, any> => {
    const errors = {} as any;
    if (!values.title.trim()) {
        errors.title = 'The Media Type is required';
    }
    return errors;
};
type contentTypes = {
    title: string;
};

const ContentCreate = (props: contentTypes) => {
    const [show, setStatus] = useState(true);
    const navigate = useNavigate();
    const notify = useNotify();

    const postSave = (data: any) => {  
            dataProvider.create('addContentTypes', { data }).then((result: any) => {
                if (result.data.status == 'error') {
                    notify(`Media Type ${result.data.error}`, { type: 'error' });
                } else {
                    notify(`Created Media Type Successfully`, { type: 'success' });
                    navigate("/mediaTypes");
                }
            });
    };
    const CreateToolbar = () => {
        return (
            <Toolbar>
                <SaveButton
                    label="Save"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };

    const handleCloseClick = () => {
        setStatus(!show);
        navigate("/mediaTypes");
    }

    return (
        <Fragment>
            <Dialog fullWidth open={show} onClose={handleCloseClick} >                
                <DialogTitle>
                    Create New Media Type
                </DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm id="post_create_form" onSubmit={postSave} sx={{ maxWidth: 600 }} toolbar={<CreateToolbar />} validate = {validateForm} >
                            <TextInput
                                autoFocus
                                source="title"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="Media Type"
                            />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
        </Fragment>
    )
};
const ContentEdit = (props: any) => {
    const [show, setStatus] = useState(true)
    const navigate = useNavigate();
    const { id } = useParams();
    const notify = useNotify();    
    const postUpdate = (data: any) => {
        dataProvider.update('mediaTypes', { id: Number(id), data, previousData: {} }).then((result: any) => {  
            if (result.data.status == 'error') {
               notify( `Media Type ${result.data.error}`, { type: 'error' });
            } else {
                notify(`Updated Media Type Successfully`, { type: 'success' });
                navigate("/mediaTypes");
           }
        });   
    };

    const UpdateToolbar = () => {
         return (
            <Toolbar>
                <SaveButton
                    label="Update"
                />
                <Button onClick={handleCloseClick} className="align-right">
                    Cancel
                </Button>
            </Toolbar>
        );
    };
    const handleCloseClick = () => {
        setStatus(!show)
        navigate("/mediaTypes");
    }

    return (
        <Fragment>

            <Dialog fullWidth open={show} onClose={handleCloseClick} >
                <DialogTitle>
                    Update Media Type
                </DialogTitle>
                <DialogContent>
                    <Edit title={"Media Type"}>
                        <SimpleForm onSubmit={postUpdate} id="post_create_form" sx={{ maxWidth: 600 }} toolbar={<UpdateToolbar />} validate={validateForm}>
                             <TextInput
                                autoFocus
                                source="title"
                                sx={{ minWidth: 550 }}
                                validate={required()}
                                label="Media Type"
                            />
                        </SimpleForm>
                    </Edit>
                </DialogContent>

            </Dialog>
        </Fragment>
    )
};

export {
    ContentCreate,
    ContentEdit
}