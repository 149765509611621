import React, { FC } from 'react';

import { Box } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useRecordContext } from 'react-admin';
import './../common/style.css';

interface Props {
  label: string;  
}

const ViewField: FC<Props> = () => {
  const record = useRecordContext();    

  let urlPath = '#/view/' + record.id;
 
  return (
    <Box component='div'>
      <a href={urlPath} rel='noopener noreferrer'>
        <VisibilityOutlinedIcon className="svg_icons"/>
      </a>
    </Box>
  ) 
};

export default ViewField;
