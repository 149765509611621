import React, { FC, useEffect, useState } from 'react';
import {  useAuthenticated, Confirm,useNotify,Title } from 'react-admin';
import { Grid, Button, Card, CardContent, Box, TextField, MenuItem,CircularProgress, Input, Alert, Checkbox, IconButton } from '@mui/material';
import {
    useParams,
} from "react-router-dom";
import dataProvider from './../dataProvider/DataProvider';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CloseIcon from '@mui/icons-material/Close';
import UserPermission from './../services/permissions';
import { useNavigate } from "react-router-dom";
import MainService from './../services/mainService';
import './curation.css';
import { TramRounded } from '@mui/icons-material';

const UpdateCuratedArticle: FC = () => {
    useAuthenticated();
    const { id } = useParams();
    const [open, setOpen] = useState(false);
    const [openArticleDelete, setArticleDeleteOpen] = useState(false);
    const [openCustomDelete, setCustomDeleteOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');
    const [mediaType, setMediaType] = useState(null);
    const [specialty, setSpecialty] = useState(null);
    const [therapeuticArea, setTherapeuticArea] = useState(null);
    const [coeSiteName, setCoeSiteName] = useState('');
    const [coeSiteTitle, setCoeSiteTitle] = useState('');
    const [siteLocation, setSiteLocation] = useState(null);
    const [coeSites, setCoeSites] = useState([]);
    const [coeLocations, setCoeLocations] = useState([]);
    const [therapeuticAreaList, setTherapeuticAreaList] = useState([]);
    const [specialtyList, setSpecialtyList] = useState([]);
    const [contentTypes, setContentTypes] = useState([]);
    const [curateStatus, setCurateStatus] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(false);
    const [articleImage, setArticleImage] = useState(null);
    const [customImage, setCustomImage] = useState(null);    
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);
    const [publish, setPublish] = useState(false);
    const [disableDeleteButton, setDisableDeleteButton] = useState(false);
    const [submittedBy, setSubmittedBy] = useState('');
    const [imageType,setImageType]= useState('');
    const [curationUpdateState, setUpdateCurationState] = useState({
        showLoader: false,
        curatableDescription: '',
        curatableTitle:'',
        cleanUrl: '',
        title:'',
        description: '',
        metaData:null,
        ogImage: null,
        images:[],
        showAlert: true,
        showError: true,
        notificationMessage: '',
        readableTitle: '',
        readableDescription: '',
        mozillaReadableDescription: '',
        enableReadable: true,
        isArticleImage: false,
        imgUrl: '',
        articleImage:null,
        customImage: null,
        isCustomImage:false,
        invalidCustomFile: false,
        invalidCustomFileMessage: '',
        articleImageId: 0,
        customImageId: 0,
        statusList: [],
        invalidFileSize: false,
        invalidFileSizeMessage: ''
    });
    const [permission, setPermission] = useState({
        enableSave: false,
        enablePublish: false,
        enableDelete: false,
        publishDisabled:false
    })
    const [disableField, setDisableField] = useState({
        titleDisabled: false,
        descriptionDisabled: false,
        coeSiteDisabled: false,
        specialtyDisabled: false,
        therapeuticDisabled: false,
        locationDisabled: false,
        mediaTypeDisabled: false,
        contentUrlDisabled: false,
        statusDisabled: false,
        publishDisabled: true
    }); 
    const navigate = useNavigate();
    const notify = useNotify();
    const uniformResourceUrl = process.env.REACT_APP_CURATION_URL;

    useEffect(() => {
        getCuratedData();
        getContentTypeData();
        getSpecialtyData();
        getArticleImage();
        getCurationStatus();
    }, []);

    const getCuratedData = () => {
        dataProvider.getOne('viewCuration', { id: id }).then((result: any) => {
            if (result.data) {
                // const description = atob(result.data.description);
                const description = decodeURIComponent(escape(atob(result.data.description)));
                setTitle(result.data.title);
                setUrl(result.data.contentUrl);
                setDescription(description);
                setMediaType(result.data.mediaTypeId);
                setSpecialty(result.data.specialtyId);
                getTherapeuticAreaData(result.data.specialtyId);
                setTherapeuticArea(result.data.therapeuticAreaId);               
                setCoeSiteName(result.data.coeSiteId);
                setCoeSiteTitle(result.data.coeSiteName);
                getCoeSiteLocationsData(result.data.coeSiteId);
                setSiteLocation(result.data.siteLocationId);
                setCurateStatus(result.data.status);
                setPublish(result.data.publish);
                setSubmittedBy(result.data.createdBy);
                getCoeSitesData(result.data.createdBy);
                validatePermissions(result.data.status,result.data.createdBy);
            }
        })
    }

    const getCoeSitesData = (userId:string) => {
        dataProvider.getList('coeSites', {
            pagination: {
                page: 1, perPage: 1000
            },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then((result: any) => {
            if (result?.data) {
                // setCoeSites(result.data);
                getUserCoeSites(userId,result.data)
            } else {
                setCoeSites([]);
            }
        })
    }
    const getUserCoeSites = (userId:string,coeSitesFullList:any) => {
        dataProvider.getList('userCoeSites', {
            pagination: {
                page: 1, perPage: 1000
            },
            sort: { field: 'name', order: 'ASC' },
            filter: {userId:userId},
        }).then((result: any) => {
            let coeSiteloggedInUserList = [];
            if (result?.data) {
                const usersAllowedList = result.data[0].coeSites;
                // console.log('list',usersAllowedList);
                // console.log(coeSitesFullList);
                coeSitesFullList.map((item: any) => {
                    if (usersAllowedList.includes(item.id)) {
                        coeSiteloggedInUserList.push(item);
                    }                
                })
                setCoeSites(coeSiteloggedInUserList);
            } else {
                setCoeSites([]);
            }
        })
    }    
    const getContentTypeData = () => {
        dataProvider.getMany('mediaTypes', {ids:[]}).then((result: any) => {
            if (result?.data) {
                setContentTypes(result.data);
            } else {
                setContentTypes([]);
            }
        })
    }

    const getSpecialtyData = () => {
        dataProvider.getMany('speciality', {ids:[]}).then((result: any) => {
            if (result?.data) {
                setSpecialtyList(result.data);
            } else {
                setSpecialtyList([]);
            }
        })
    }

    const getTherapeuticAreaData = (id:string) => {
        dataProvider.getMany('therapeutic_area', { ids: [id]}).then((result: any) => {
            if (result?.data) {
                setTherapeuticAreaList(result.data);
            } else {
                setTherapeuticAreaList([]);
            }
        })
    }

    const getCoeSiteLocationsData = (id:string) => {
        dataProvider.getMany('coeSitesLocation', {ids:[id]}).then((result: any) => {
            if (result?.data) {
                setCoeLocations(result.data);
            } else {
                setCoeLocations([]);
            }
        })
    } 
    const getCurationStatus = () => {
        dataProvider.getMany('status', { ids: [] }).then((result: any) => {
            if (result.data) {
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                    statusList: result.data
                }));
            }
        });
    }
    const getArticleImage = () => {
        dataProvider.getOne('getArticleImage', { id: id }).then((result: any) => {
            if (result?.data) {
                if (result.data.articleImage != null) {
                 setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                     isArticleImage: true,
                    articleImageId:result.data.articleImageId
                }));
                setArticleImage(atob(result.data.articleImage));                   
                }
                 if (result.data.customImage != null) {
                 setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                     isCustomImage: true,
                     customImageId:result.data.customImageId
                }));
                setCustomImage(atob(result.data.customImage));                   
                }

            }
        })
    }

    const validateForm = () => {
        let invalidUrl = false;
        let validCoeSiteName = false;
        if (url.trim().length < 1) {
            invalidUrl = true;
        }
        if (coeSiteName.length < 1) {
            validCoeSiteName = true;
        }

            return {
            url: invalidUrl,
            coeSiteName: validCoeSiteName,
            };
 
    }
    const updateCuration = () => {
        setIsSubmit(true);
        setDisableSaveButton(true);
        const errors: {            
            url: boolean;
            coeSiteName: boolean;
        } = validateForm();
        const invalid = Object.keys(errors).some(x => errors[x]);
        if (!invalid) {
            if (publish===true && curateStatus !== process.env.REACT_APP_CURATION_APPROVED_STATUS) {
                setPublish(false)
            }
            const postData = {
                coeLocation: siteLocation,
                coeSite: coeSiteName,                
                description: btoa(unescape(encodeURIComponent(description))),
                mediaType: mediaType,
                specialty: specialty,
                therapeuticArea: therapeuticArea,
                title: title,
                curationInputId: id,
                status: curateStatus,
                publish: publish,
                contentUrl:url
            }
            dataProvider.update('updateCuration', {
                id: id, data: postData, previousData: {}
            }).then((result: any) => {
                let notificationMsg = 'Updated Successfully';
                if (result?.data?.status == "error") {
                    notificationMsg = 'Something went wrong, Contact Administrator';
                    setShowError(true);
                } else {
                    setShowError(false);
                    getCuratedData();
                }
                setNotificationMessage(notificationMsg)
                setShowAlert(true);
                setIsSubmit(false);
                setDisableSaveButton(false);
                window.scrollTo(0, 0);
            });
            
            let postImage: any = {
                articleImage: '',
                customImage:''
            };
            if (curationUpdateState.articleImage || curationUpdateState.articleImage != null) {
                postImage.articleImage = curationUpdateState.articleImage;
            }
            if (curationUpdateState.customImage || curationUpdateState.customImage != null) {
                postImage.customImage = curationUpdateState.customImage;
            }
            
            if (postImage.articleImage || postImage.customImage) {
                dataProvider.update('updateImage', {
                    id: id, data: postImage, previousData: {}
                }).then((result: any) => {
                    console.log('successfully updated Image')
                });
                
            }

        } else {
            setDisableSaveButton(false);
        }
    }
    
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        setOpen(false);
        dataProvider.delete('deleteCuration', { id }).then((res: any) => {

            let notificationMsg = 'Deleted the Article from Content Queue';
            if (res?.data?.status === 'error') {
                notificationMsg = 'Something went wrong';
                setShowError(true);
            } else {
                setShowError(false);
                navigate(`/curated/${coeSiteTitle.replace(" ", '')}/all`)                
                 notify(notificationMsg, { type: 'success' });
            }
            setNotificationMessage(notificationMsg);
            setShowAlert(true);
        });
    };
    const deleteCuration = () => {
        setOpen(true);
        setDisableDeleteButton(true); 
    }
    
    const disableFields = () => {
        setDisableField(disableField => ({
            ...disableField,
            titleDisabled: true,
            descriptionDisabled: true,
            coeSiteDisabled: true,
            specialtyDisabled: true,
            therapeuticDisabled: true,
            locationDisabled: true,
            mediaTypeDisabled: true,
            contentUrlDisabled: true,   
            statusDisabled:true
        }));
    }

    const closeAlert = () => {
        setShowAlert(false)
    }
    const getCuratableContent = () => {
        const apiURL = `${uniformResourceUrl}uniformresource/curatableContent`;
        const pathArray = url.split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        const baseUrl = protocol + '//' + host;
        MainService.getDataFromUniformResource(url, apiURL).then((response: any) => {
            if (typeof (response?.error) === 'undefined' && !(typeof (response?.terminalResult) !== 'undefined' && typeof (response?.terminalResult.httpStatus) !== 'undefined' && response?.terminalResult.httpStatus === 403)) {
                const imgArray: any = [];
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                    curatableDescription: response?.metaData?.description?response.metaData.description:'',
                    curatableTitle: response?.title,
                    cleanUrl: response?.uri,
                    showLoader: false,
                }));
                setTitle(response?.title);
                setDescription(response?.metaData?.description ? response.metaData.description : '');
                if (response?.metaData) {
                    setUpdateCurationState((curationUpdateState) => ({
                        ...curationUpdateState,
                        metaData: response.metaData
                    }));
                }
                if (response?.images && response.images.length) {
                    const images: any = [];
                    //removing duplicates
                    (response.images).forEach((el: any) => {
                        if (!images.includes(el)) {
                            images.push(el);
                        }
                    });
                    if (curationUpdateState.ogImage) {
                        const tempImg = {
                            src: curationUpdateState.ogImage
                        };
                        images.push(tempImg);
                    }
                    images.forEach((image: any) => {
                        if (image.src) {
                            const img = image.src;
                            const validImg = validateUrl(img);
                            if (validImg) {
                                if (img.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                                    if (!imgArray.includes(img)) {
                                        imgArray.push(img);
                                    }
                                }
                            } else {
                                let imgPath = '';
                                if (img.charAt(0) === '/') {
                                    imgPath = baseUrl + img;
                                } else {
                                    imgPath = baseUrl + '/' + img;
                                }
                                const validImage = validateUrl(imgPath);
                                if (validImage) {
                                    if (imgPath.match(/\.(jpeg|jpg|gif|png)$/) != null) {
                                        if (!imgArray.includes(imgPath)) {
                                            imgArray.push(imgPath);
                                        }
                                    }
                                }
                            }
                        }
                    });
                    setUpdateCurationState((curationUpdateState) => ({
                        ...curationUpdateState,
                         images: imgArray.reverse()
                    }));
                } else {
                    setUpdateCurationState((curationUpdateState) => ({
                        ...curationUpdateState,
                         images: [] 
                    }));
                }
            } else {
                let notificationMessage = 'Something went wrong, Please try again later';
                if (response.isInvalidResource) {
                    if (typeof response.remarks === 'undefined') {
                        notificationMessage = 'Please enter a valid URL';
                    } else {
                        notificationMessage = 'Unable to fetch content from this URL';
                    }
                }
                setShowAlert(true);
                setNotificationMessage(notificationMessage);
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                    showLoader: false,
                    enableReadable: true,
                }));  
                setShowError(true);              

            }
        })        
        
    }
    const validateUrl = (str: any) => {
        let flag: any;
        const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        if (regexp.test(str)) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    }    
    const getReadableContent = () => {
        const apiURL = `${uniformResourceUrl}uniformresource/readableContent`;
        MainService.getDataFromUniformResource(url, apiURL).then((readableContent: any) => {
            if (readableContent?.mozillaReadability) {
                const content: string = readableContent.mozillaReadability ? (readableContent?.mozillaReadability?.content).replace(/<img[^>]*>/g, "") : "";
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                        readableTitle: readableContent?.mozillaReadability?.title,
                        readableDescription: content,
                        mozillaReadableDescription: readableContent?.mozillaReadability,
                        cleanUrl:readableContent.uri,
                }));  

            }
        })          
        
    }    
    const curateData = () => {
        setUpdateCurationState((curationUpdateState) => ({
            ...curationUpdateState,
            enableReadable: false,
            showLoader:true
        }));
        setShowError(false);
        setShowAlert(false);
        setNotificationMessage('');
        
        getCuratableContent();
        getReadableContent();
        
    }
    const replaceWithReadableContent = () => {
        let description: string = '';
        description = curationUpdateState.readableDescription;
        setDescription(description);
        
    }
    const clearContent = () => {
        setDescription('')
    }    
    const validatePermissions = (status: string, createdBy: string) => {
        const userRole = JSON.parse(localStorage.getItem('auth'))?.userTypeId;
        const userId = JSON.parse(localStorage.getItem('auth'))?.userId;
        const userAdminRole = process.env.REACT_APP_MICROSITE_ADMIN;
        const superAdminRole = process.env.REACT_APP_SUPER_ADMIN;
        const userCuratorRole = process.env.REACT_APP_CURATOR;
        const userContentAdminRole = process.env.REACT_APP_CONTENT_ADMINISTRATOR;
        let enableSave = false;
        let enableDelete = false;
        let disableStatus = false;

        if (status === process.env.REACT_APP_CURATION_DEFAULT_STATUS) {
            if (userId === createdBy) {
                enableSave = true;
                enableDelete = true;
            }
        }        
        switch (userRole) {
            case userAdminRole:                
                enableSave = true;
                enableDelete = true;
                break;
            case superAdminRole:                
                enableSave = true;
                enableDelete = true;
                break;            
            case userCuratorRole:
                enableSave = true;  
                enableDelete = true;        
                break;
            case userContentAdminRole:
                // disableFields();                             
                enableSave = true;
                enableDelete = true;        
                break;
            default:
                disableStatus = true;
                if (status === process.env.REACT_APP_CURATION_DEFAULT_STATUS) {
                    if (userId === createdBy) {
                        enableSave = true;
                        enableDelete = true;
                    }
                }
        }
        setPermission(permission => ({
            ...permission,
            enableSave: enableSave,
            enableDelete: enableDelete,
        }));
        setDisableField(disableField => ({
            ...disableField,
            statusDisabled: disableStatus,
        }));

    }
    const resizeImage = (img: any) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = img;
            image.onload = () => {
                const param = {
                    url: img,
                    width: Number(image.naturalWidth),
                    height: Number(image.naturalHeight),
                }
                MainService.resizeImage(param, uniformResourceUrl + 'api/resizeImage').then((base64Image: any) => {
                    if (base64Image) {
                        resolve(base64Image)
                    } else {
                        resolve(null);
                    }
                });
            }
        });
    }
    const setOgImage = async (img: any) => {
        const base64Image = await resizeImage(img);
        setArticleImage(base64Image);
        setUpdateCurationState((curationUpdateState) => ({
            ...curationUpdateState,
            imgUrl: img,
            isArticleImage:true,
            articleImage:base64Image
        }));

    }
    const handleCustomImages = async (event: any) => {
        const files = event.target.files;
        const fileInfo: { fileName: any; base64Data: any; }[] = [];
        const allowedTypes = ['.jpeg', '.jpg', '.png'];
        const fileSize = files[0].size;
        if (fileSize <= 2 * 1000000) {
            let flag = true;
            for (let i = 0; i < files.length; i++) {
                if (flag) {
                    const fileName = files[i].name;
                    const fileExtension = '.' + fileName.substr(fileName.lastIndexOf('\\') + 1).split('.')[1];
                    if (allowedTypes.includes(fileExtension.toLowerCase())) {
                        const base64Data = await toBase64(files[i]);
                        setCustomImage(base64Data);
                        setUpdateCurationState((curationUpdateState) => ({
                            ...curationUpdateState,
                            customImage: base64Data,
                            isCustomImage: true
                        }));
                    } else {
                        flag = false;
                    }
                }
            }
            if (flag === false) {
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                    invalidCustomFile: true,
                    invalidCustomFileMessage: "Only [.jpeg,.jpg,.png] files are allowed",
                }));
            }
        }
        else {
            setUpdateCurationState((curationUpdateState) => ({
                ...curationUpdateState,
                invalidFileSize: true,
                invalidFileSizeMessage: 'image size should be less than 2 mb'
            }));
        }
    }
    const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const deleteImage = () => {
        setArticleDeleteOpen(false);
        setCustomDeleteOpen(false);
        let imgId = 0;
        if (imageType === 'article') {
           imgId= curationUpdateState.articleImageId
        } else {
            imgId= curationUpdateState.customImageId
        }
        dataProvider.delete('deleteCurationImage', { id: imgId }).then((result:any) => {
            if (imageType === 'article') {
                setUpdateCurationState((curationUpdateState) => ({
                    ...curationUpdateState,
                    isArticleImage: false,
                    articleImage:null
                }));
            } else {
            setUpdateCurationState((curationUpdateState) => ({
                ...curationUpdateState,
                isCustomImage: false,
                customImage:null
                 }));
                
            }
        });
       
 
    }
    const handleDeleteImageClose = () => {
        setArticleDeleteOpen(false); 
        setCustomDeleteOpen(false); 
    } 
    // const handleStatus = (e:boolean) => {
    //     if (e === true) {
    //         setCurateStatus('approved');
    //     }
    // }
    // const handleStatusChange = (e: string) => {
    //     console.log(e);
    //     if (e !== 'approved') {
    //         setPublish(false);
    //         setPermission(permission => ({
    //             ...permission,
    //             enablePublish: false,
    //         }));
     
    //     } else {
    //         setPermission(permission => ({
    //             ...permission,
    //             enablePublish: true,
    //             publishDisabled: false
    //         }));            
            
    //     }
    // }
    return (
        <Card>
            <Title title='Update Uploaded Content' />
            {showAlert ?
                <Box component="div" className="curation-alert" mb={1}>
                    <Alert severity={showError ? "error" : "success"}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={closeAlert}
                            >

                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                    >
                        {notificationMessage}
                    </Alert>
                </Box> : null}
            <CardContent className="edit-curation-card">
                <Box component="div" className="parse-url-main">
                    <Grid container>
                        <Grid item xs={8} ml={4} paddingRight={2}>
                            <TextField
                                required
                                id="url"
                                label="Enter URL"
                                variant="outlined"
                                value={url}
                                disabled={true}
                                fullWidth
                            />
                        

                            <Box> {
                                curationUpdateState.showLoader ? <Box component="div" mt={2} className="press-release-loader">
                                    <CircularProgress />
                                </Box> : undefined
                            }</Box>
                        </Grid>
                        <Grid item xs={3}>
                            <Button
                                disabled={url.length < 1 ? true : false}
                                variant="contained" color="primary" size="medium"
                                onClick={curateData}
                            >Get Content from Source
                            </Button>

                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>Title</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField label="" id="title" size="medium" className="form-textfield"
                                onChange={(e: any) => setTitle(e.target.value)}
                                value={title} fullWidth />
                            
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={3} ml={4} mr={2}>
                            <label>Description</label>
                        </Grid>
                        <Grid item xs={9}>

                            <Grid container>
                                <Grid item xs={12} mt={2} mb={1}>
                                    <Box>
                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={description}
                                        onChange={(event, editor) => {
                                            let data = editor.getData();
                                            data = data.trim();
                                            setDescription(data);
                                        }}
                                    />

                                    </Box>
                                </Grid>
                            </Grid>

                            <Grid container>
                                <Grid item xs={6} mt={1} mr={6}>
                                    <Box>
                                        <Button
                                            variant="contained" color="primary" size="small" fullWidth onClick={replaceWithReadableContent} disabled={curationUpdateState.enableReadable}
                                        >Replace With Readable Content</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={5} mt={1}>
                                    <Box>
                                        <Button
                                            variant="contained" color="primary" size="small" fullWidth onClick={clearContent}
                                        >Clear Content</Button>
                                    </Box>
                                </Grid>
                            </Grid>

                        </Grid>


                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>Media Type</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                value={mediaType}
                                onChange={(e: any) => setMediaType(e.target.value)}
                                fullWidth
                            >
                            {
                                contentTypes.map((element: any) => (
                                    <MenuItem key={element.id} value={element.id}>
                                        {element.title}
                                    </MenuItem>
                                ))
                            }

                            </TextField>
                        </Grid>
                    </Grid>
                </Box> 
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>Speciality</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                // label="Speciality"
                                value={specialty}
                                onChange={(e: any) => {
                                    setSpecialty(e.target.value);
                                    getTherapeuticAreaData(e.target.value)
                                }}
                                fullWidth
                            >
                                {
                                    specialtyList.map((element: any) => (
                                        <MenuItem key={element.id} value={element.id}>
                                            {element.title}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>Therapeutic Area</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                // label="Therapeutic area"
                                value={therapeuticArea}
                                onChange={(e: any) => setTherapeuticArea(e.target.value)}
                                fullWidth
                            >
                                {
                                    therapeuticAreaList.map((element: any) => (
                                        <MenuItem key={element.id} value={element.id}>
                                            {element.title}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>                
 
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>CoE Site</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                value={coeSiteName}
                                
                                onChange={(e: any) => {
                                        setCoeSiteName(e.target.value);
                                        getCoeSiteLocationsData(e.target.value)
                                    }}
                                fullWidth
                                disabled={disableField.coeSiteDisabled}
                            >
                                {
                                    coeSites.map((element: any) => (
                                        <MenuItem key={element.id} value={element.id}>
                                            {element.name}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
 
                        </Grid>
                    </Grid>
                </Box>
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>CoE Site Location</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                value={siteLocation}
                                onChange={(e: any) => setSiteLocation(e.target.value)}
                                fullWidth
                                disabled={disableField.locationDisabled}
                            >
                                {
                                    coeLocations.map((element: any) => (
                                        <MenuItem key={element.id} value={element.id}>
                                            {element.location}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
                {(curationUpdateState.isArticleImage)?
                    (<Box component="div">
                        <Grid container>
                            <Grid item xs={2} mt={4} ml={4} mr={2}>
                                <label>Article Image</label>
                            </Grid>
                            <Grid item xs={9} mt={2}>
                                <Box component="div" mt={1} className='curation-images-outer curation-image'>
                                    <img src={articleImage} alt="Article Image" className="curation-images" />
                                </Box>
                                <Box className='button-delete'>
                                    <Button variant="outlined" color="primary" size="small" onClick={() => { setArticleDeleteOpen(true);setImageType('article') } }>delete</Button>
                                    <Confirm
                                        isOpen={openArticleDelete}
                                        // loading={loading}
                                        title="Delete"
                                        content="Are you sure you want to delete this Image? "
                                        onConfirm={deleteImage}
                                        onClose={handleDeleteImageClose}
                                    />
                                </Box>
                            </Grid>
                            
                        </Grid>
                    </Box>):null
                }
                {(curationUpdateState.isCustomImage)?
                    (<Box component="div">
                        <Grid container>
                            <Grid item xs={2} mt={4} ml={4} mr={2}>
                                <label>Custom Image</label>
                            </Grid>
                            <Grid item xs={9} mt={2}>
                                <Box component="div" mt={1} className='curation-images-outer curation-image'>
                                    <img src={customImage} alt="Article Image" className="curation-images" />
                                </Box>
                                <Box className='button-delete'>
                                    <Button variant="outlined" color="primary" size="small" onClick={() => { setCustomDeleteOpen(true); setImageType('custom') }} >
                                        delete
                                    </Button>
                                    <Confirm
                                        isOpen={openCustomDelete}
                                        title="Delete"
                                        content="Are you sure you want to delete this Image? "
                                        onConfirm={deleteImage}
                                        onClose={handleDeleteImageClose}
                                    />
                                </Box>
                            </Grid>
                            
                        </Grid>
                    </Box>):null
                }
                <Box component="div">
                    <Grid container>
                        <Grid item xs={2} mt={4} ml={4} mr={2}>
                            <label>Status</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <TextField
                                id="outlined-select-currency"
                                select
                                value={curateStatus}
                                onChange={(e: any) => {
                                    setCurateStatus(e.target.value)
                                    // handleStatusChange(e.target.value);
                                }}
                                fullWidth
                                disabled={disableField.statusDisabled}
                            >
                                {
                                   
                                    curationUpdateState.statusList.map((element: any) => (
                                        <MenuItem key={element.status} value={element.status}>
                                            {element.displayStatus}
                                        </MenuItem>
                                    ))
                                }

                                {/* <MenuItem key="status-approved" value="approved">Approved</MenuItem>
                                <MenuItem key="status-submitted" value="submitted">Submitted</MenuItem>
                                <MenuItem key="status-rejected" value="rejected">Rejected</MenuItem>
                                <MenuItem key="status-standby" value="standby">Standby</MenuItem> */}
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
                {curationUpdateState.images.length > 0 ? <Box component="div" mt={2}>
                    <Grid container>
                        <Grid item xs={2} mt={2} ml={4} mr={2}>
                            <label>Article Images</label>
                        </Grid>
                        <Grid item xs={9} mt={2}>
                            <Box component='div'  mb={2}>
                                <Box component='div' className="cqa-images-collapse">
                                    <b>If you Want to Update Image Choose From Article Images</b>
                                    <Box
                                        component='span'
                                        className='assign-to-collapse'
                                        pl={1}
                                        mt={2}
                                    >

                                    </Box>
                                </Box>
                            </Box>
                            <div className="clearfix"></div>

                            <Box component="div">
                                {curationUpdateState.images.map((img: any, i: any) => {
                                    return <Box component="div" key={`img-${i}`} onClick={() => setOgImage(img)} className={curationUpdateState.imgUrl === img ? "ogImgSelected curation-images-outer" : "curation-images-outer"}>
                                        <img src={img} alt="" className="curation-images" />
                                    </Box>
                                })}
                            </Box>


                        </Grid>
                    </Grid>
                </Box> : null}
                <Box component="div" mt={2}>
                        <Grid container>
                            <Grid item xs={2} mt={2} ml={4} mr={2}>
                                <label>Custom Image</label>
                            </Grid>
                            <Grid item xs={9} mt={2}>
                                <Grid item xs={10}>
                                    <Input onChange={handleCustomImages} type="file" id="cqa-custom-image" name="cqa-custom-image" inputProps={{ multiple: false, accept: '.jpeg,.jpg,.png' }} />
                                {curationUpdateState.invalidCustomFile ? <span className="cqa-validation-error">{curationUpdateState.invalidCustomFileMessage}</span> : null}
                                {curationUpdateState.invalidFileSize ?
                                                            <div className="error-message">{curationUpdateState.invalidFileSizeMessage}</div> 
                                                        :  <div>Image size must be less than 2 mb </div> }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                {/* {(permission.enablePublish)?
                (<Box component="div" className="curation-content">
                    <b>Publish</b>
                    <Checkbox
		    	        disabled={permission.publishDisabled}
                        checked={publish}
                            onChange={(e: any) => {
                                setPublish(e.target.checked);
                                // handleStatus(e.target.checked);
                            }}
                        color="primary"
                        sx={{ minWidth: 150 }}
                        inputProps={{ 'aria-label': 'Publish' }}
                    />
                </Box>):null} */}
                
                   <Box className="button-save">
                        <Grid container>
                        {(permission.enableDelete) ? (<Grid item xs={5} mt={2} mr={2}>
                            <Box>
                                <Button
                                    variant="contained" color="primary" size="large" className="curate-pull-right"
                                    onClick={deleteCuration}
                                >Delete</Button>
                                <Confirm
                                    isOpen={open}
                                    // loading={loading}
                                    title="Delete"
                                    content="Are you sure you want to delete? Once deleted you cannot restore"
                                    onConfirm={handleConfirm}
                                    onClose={handleDialogClose}
                                />
                            </Box>
                        </Grid>) : null}
                        {(permission.enableSave) ? (<Grid item xs={5} mt={2}>
                            <Box>
                                <Button disabled={disableSaveButton}
                                    variant="contained" color="primary" size="large" className="curate-pull-right"
                                    onClick={updateCuration}
                                >Update</Button>
                            </Box>
                        </Grid>) : null}
                        </Grid>
                    </Box>

            </CardContent>
        </Card>
    );
}
export default UpdateCuratedArticle;