import React, { FC } from 'react';
import { UrlField } from 'react-admin';
import { Link, Box } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useRecordContext } from 'react-admin';
import './style.css'

interface Props {
  label: string;  
}

const CustomUrlField: FC<Props> = () => {
  const record = useRecordContext();
  let displayUrl = (record?.contentUrl.length > 50) ? record.contentUrl?.substring(0, 50) + '...' : record?.contentUrl;
  let urlPath = record?.contentUrl;
  return (
    <Box component='div'>
      <a href={urlPath} title={urlPath} rel='noopener noreferrer' target='blank' className='curation-link'>
        {displayUrl}
      </a>
    </Box>
  ) 
};


export default CustomUrlField;
