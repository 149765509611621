import * as React from "react";
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { List, Datagrid, TextField, SearchInput, Filter, FunctionField, useResourceContext,CreateButton } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import CustomViewField from '../common/CustomViewField';
import CustomUrlField from '../common/CustomLinkField';
import CustomDateField from '../common/CustomDateField';
import ViewField from '../common/ViewField';
import './../common/style.css';
const moment = require('moment');


const PostFilter = (props: JSX.IntrinsicAttributes) => (
    <Filter {...props}>
        <SearchInput source='title' alwaysOn />
    </Filter>
);
export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
}

const ListActions = () => {
    const navigate = useNavigate();
    const resource = useResourceContext();
    const resource_filters = resource.split('/');
    const coe_site = resource_filters[2]
    const to_url='/curation?'+coe_site
    return(
    <Button variant="contained" color="success" onClick={() => { navigate(to_url); }} sx={{  mb: '10px'}}>
        Upload Content
    </Button>
    )
}
const CustomStatusField =(record: { label: string; }) => {
    return (
            <FunctionField render={(record: { status: string; }) => record.status.replace(/\b(\w)/g, s => s.toUpperCase())} />
    );
}
const CustomPublishField = (record: { label: string;}) => {
    return (
        <div>
            <FunctionField render={(record: { publish: boolean; }) => `${record.publish === true ? "Published" : ""}`} /></div>
    );
}

const Empty = (props) => {
    const resource = useResourceContext();
    const resource_filters = resource.split('/');
    const coe_site = resource_filters[2]
    const to_url='/curation?'+coe_site
    return (
 
        <Box textAlign="center" m={30} >
            <Typography variant="h4" paragraph>
                No content available
                for {resource_filters[2]}
            </Typography>
        <CreateButton label="Upload Content" to={to_url} />
        </Box>
    );
}

const CuratedContentList = (props: any) => (
    <List
        {...props}
        empty={<Empty/>}
        title={props.resource}
        exporter={false}
        actions={<ListActions />}
        filters={<PostFilter />}
        filterDefaultValue
        perPage={50}
        pagination={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
    >
        <Datagrid bulkActionButtons={false} className="custom-table" >
            <ViewField label=""/>
            <TextField source="title" label="Title" style={{ width: 500 }}/>
            {/* <TextField source="contentUrl" label="Content Url" /> */}
            <CustomUrlField label="Content URL"/>
            <TextField source="mediaType" label="Media Type" />
            <TextField source="specialty" label="Specialty" />
            <TextField source="therapeuticArea" label="Therapeutic Area" />
            <TextField source="siteLocation" label="CoE Site Location" />
            <CustomDateField source="createdAt" label="Created At" sortBy='createdAt'/>
            <TextField source="createdByName" label="Submitted By" />
            <CustomStatusField label="Status" />
            {/* <CustomPublishField label="Publish" /> */}
            
            {/* <CustomViewField label='View' field="view" /> */}
            <CustomViewField label='Edit' field="edit"/>
            

        </Datagrid>
    </List>
);
const ContentList = (props: any) => (
    <List
        {...props}
        empty={<Empty/>}
        title={props.resource}
        exporter={false}
        actions={<ListActions />}
        filters={<PostFilter />}
        filterDefaultValue
        perPage={50}
        pagination={false}
        sort={{ field: 'createdAt', order: 'DESC' }}
    >
        <Datagrid bulkActionButtons={false} className="custom-table">
            <ViewField label=""/>
            <TextField source="title" label="Title" />
            {/* <TextField source="contentUrl" label="Content Url" /> */}
            <CustomUrlField label="Content URL"/>
            <TextField source="mediaType" label="Media Type" />
            <TextField source="specialty" label="Specialty" />
            <TextField source="therapeuticArea" label="Therapeutic Area" />
            <TextField source="siteLocation" label="CoE Site Location" />
            <CustomDateField source="createdAt" label="Created At" sortBy='createdAt'/>
            <TextField source="createdByName" label="Submitted By" />
            {/* <CustomViewField label='View' field="view" /> */}
            <CustomViewField label='Edit' field="edit"/>
            
        </Datagrid>
    </List>
);

export {
    CuratedContentList,
    ContentList
}