import React, { FC, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {  useAuthenticated, useNotify } from 'react-admin';
import { Grid, Button, Card, CardContent, Box, CircularProgress, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useParams } from "react-router-dom";
import dataProvider from './../dataProvider/DataProvider';
import { useNavigate } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import './../Curation/curation.css';

const moment = require('moment');

const UserDetailPage: FC = () => {
    useAuthenticated();
    const { id } = useParams();
    const notify = useNotify();
    const [showLoader, setShowLoader] = useState(true);
    const [npi, setNpi] = useState('');
    const [lastName, setLastName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [age, setAge] = useState('');
    const [address, setAddress] = useState('');
    const [emailId, setEmailId] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [createdBy, setCreatedBy] = useState('');
    const [prefix, setPrefix] = useState(null);
    const [suffix, setSuffix] = useState(null);
    const [state, setState] = useState('');
    const [member, setMember] = useState('');
    const [city, setCity] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [workPhone, setWorkPhone] = useState('');
    const [mobilePhone, setMobilePhone] = useState('');
    const [twitterProfile, setTwitterProfile] = useState('');
    const [linkedInProfile, setLinkedInProfile] = useState('');
    const [fbProfile, setFbProfile] = useState('');
    const [webSite, setWebSite] = useState('');
    const [graduationYear, setGraduationYear] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [degree, setDegree] = useState('');
    const [userId, setUserId] = useState('');
    const [connections, setConnections] = useState('');
    const [addictionMedicine, setAddictionMedicine] = useState('');
    const [bio, setBio] = useState('');
    const [value, setValue] = React.useState('profile');
    const [tabName, setTabName] = React.useState('profileinfo'); 
    const [selectedRadio, setSelectedRadio] = useState('male');
    const [addEducate, setAddEducation] = useState({ val: [] });
    const [addLicen, setAddLicen] = useState({ val: [] });
    const [addExper, setAddExper] = useState({ val: [] });
    const [addContact, setAddContact] = useState({ val: [] });
    const [addProf, setAddProf] = useState({ val: [] });
    
     function createEducation() {
        return addEducate.val.map((el, i) =>
          <div key={i}>
                <Box mt={4}>     
                                <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="College"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Degree"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={5} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Grafuation Year"
                                        value={graduationYear}
                                        onChange={(e: any) => setGraduationYear(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Specialty"
                                        value={specialty}
                                        onChange={(e: any) => setSpecialty(e.target.value)}
                                    />
                                </Grid>
                                    </Grid>
                                    <Button className="section-add-button" onClick={removeEduClick.bind(i)}><CancelIcon className="section-add-icon" /></Button>
                                    </Box>
          </div>
        );
     }
    
    function createContact() {
        return addContact.val.map((el, i) =>
          <div key={i}>
                <Box mt={4}>     
                                <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="address"
                                        variant='outlined'
                                        fullWidth
                                        label="Address Line 1"
                                        value={address}
                                        onChange={(e: any) => setAddress(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="address"
                                        variant='outlined'
                                        fullWidth
                                        label="Address Line 2"
                                        value={address}
                                        onChange={(e: any) => setAddress(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="city"
                                        variant='outlined'
                                        fullWidth
                                        label="City"
                                        value={city}
                                        onChange={(e: any) => setCity(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="state"
                                        variant='outlined'
                                        fullWidth
                                        label="State"
                                        value={state}
                                        onChange={(e: any) => setState(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                          <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="zipCode"
                                        variant='outlined'
                                        fullWidth
                                        label="Zip Code"
                                        value={zipCode}
                                        onChange={(e: any) => setZipCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="timeZone"
                                        variant='outlined'
                                        fullWidth
                                        label="Time Zone"
                                        value={timeZone}
                                        onChange={(e: any) => setTimeZone(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="workPhone"
                                        variant='outlined'
                                        fullWidth
                                        label="Work Phone"
                                        value={workPhone}
                                        onChange={(e: any) => setWorkPhone(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="mobilePhone"
                                        variant='outlined'
                                        fullWidth
                                        label="Mobile Phone"
                                        value={mobilePhone}
                                        onChange={(e: any) => setMobilePhone(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                                    <Button className="section-add-button" onClick={removeContactClick.bind(i)}><CancelIcon className="section-add-icon" /></Button>
                                    </Box>
          </div>
        );
     }
    
    function createLicense() {
        return addLicen.val.map((el, i) =>
          <div key={i}>
                <Box mt={4}>
                                    <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Certification Name"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Specialty"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={5} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Institution Name"
                                        value={graduationYear}
                                        onChange={(e: any) => setGraduationYear(e.target.value)}
                                    />
                                </Grid>
                                    </Grid>
                                    <Button className="section-add-button" onClick={removeLicClick.bind(i)}><CancelIcon className="section-add-icon" /></Button>
                                </Box>
          </div>
        );
    }

    function createExper() {
        return addExper.val.map((el, i) =>
          <div key={i}>
                <Box mt={4}>
                                    <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Employer"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Title"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        variant='outlined'
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        variant='outlined'
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Location"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Box ml={4} mr={4} component="div">
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Experience Description"
                                        multiline
                                        rows={4}
                                        value={bio}
                                        onChange={(e: any) => setBio(e.target.value)}
                                    />
                                    </Box>
                                    <Button className="section-add-button" onClick={removeExpClick}><CancelIcon className="section-add-icon" /></Button>
                                    </Box>
          </div>
        );
    }
    
    function createProf() {
        return addProf.val.map((el, i) =>
          <div key={i}>
                <Box mt={4}>
                     <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Job Title"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Current Organization / Affiliation"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Box ml={4} mr={4} component="div">
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="About Me"
                                        multiline
                                        rows={4}
                                        value={bio}
                                        onChange={(e: any) => setBio(e.target.value)}
                                    />
                            </Box>
                                    <Button className="section-add-button" onClick={removeProfClick}><CancelIcon className="section-add-icon" /></Button>
                                    </Box>
          </div>
        );
     }
    
    function handleEduChanges(event) {
        const eduvals = [...addEducate.val];
        const licvals = [...addLicen.val];
        const expvals = [...addExper.val];
        const contvals = [...addContact.val];
        const profvals = [...addProf.val];
        eduvals[this] = event.target.value;
        licvals[this] = event.target.value;
        expvals[this] = event.target.value;
        contvals[this] = event.target.value;
        profvals[this] = event.target.value;
        setAddEducation({ val: eduvals });
        setAddLicen({ val: licvals });
        setAddExper({ val: expvals });
        setAddContact({ val: contvals });
        setAddProf({ val: profvals });
    }

    const removeEduClick = (event) => {
      const vals = [...addEducate.val];
      const index = Number(event.target.name);
      vals.splice(index, 1);
      setAddEducation({ val: vals });
    }

    const removeLicClick = (event) => {
      const vals = [...addLicen.val];
      const index = Number(event.target.name);
      vals.splice(index, 1);
      setAddLicen({ val: vals });
    }

    const removeExpClick = (event) => {
      const vals = [...addExper.val];
      const index = Number(event.target.name);
      vals.splice(index, 1);
      setAddExper({ val: vals });
    }
    
    const removeContactClick = (event) => {
      const vals = [...addContact.val];
      const index = Number(event.target.name);
      vals.splice(index, 1);
      setAddContact({ val: vals });
    }
    
    const removeProfClick = (event) => {
      const vals = [...addProf.val];
      const index = Number(event.target.name);
      vals.splice(index, 1);
      setAddProf({ val: vals });
    }

  const handleRadio = event => {
    setSelectedRadio(event.target.value);
  };

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    };

    const handleTabChange = (event: React.SyntheticEvent, newVal: string) => {
    setTabName(newVal);
    };

     useEffect(() => {
         getUserData();
     }, []);
    
    const getUserData = () => {
        dataProvider.getOne('pocnUsers', { id: id }).then((result: any) => {
            if (result.data) {
                
                setNpi(result.data.npi);
                setFirstName(result.data.firstName);
                setLastName(result.data.lastName);
                setMiddleName(result.data.middleName);
                setEmailId(result.data.emailId);
                setProviderId(result.data.providerId);
                setCreatedAt(result.data.createdAt);               
                setCreatedBy(result.data.createdBy);
                setPrefix(result.data.prefix);
                setSuffix(result.data.suffix);
                setMember(result.data.pocnMember);
                setState(result.data.state);
                setCity(result.data.city);
                setTwitterProfile(result.data.twitterProfile);
                setLinkedInProfile(result.data.linkedInProfile);
                setFbProfile(result.data.fbProfile);
                setMobilePhone(result.data.mobilePhoneNumber);
                setAddictionMedicine(result.data.addictionMedicine);
                setZipCode(result.data.zipCode);
                setBio(result.data.aboutMe);
                setDegree(result.data.degree);
                setUserId(result.data.userId);
                setConnections(result.data.connections);
                setAge(result.data.age);
                setWebSite(result.data.websiteUrl);
                setGraduationYear(result.data.graduationYear);
                setSpecialty(result.data.primarySpecialtyGroup);
            }
            setShowLoader(false);
        })
}

    const navigate = useNavigate();
    const handleCloseClick = () => {
        navigate(`/pocnUsers`);
    }

    const handleSaveClick = () => {
            const data = {
                firstName: firstName,
                lastName: lastName,
                npi: npi,
                emailId: emailId,
                // specialty: specialty,
                // leadSource: leadSource,
                // notes: notes,
                // graduationYear: graduationYear
            }

            dataProvider.create('', { data }).then((res: any) => {
                
                if (res.data.status === 'Error') {
                    notify(`User ${res.data.message}`, { type: 'error' });
                } else {
                    notify(`Created POCN Provider Successfully`, { type: 'success' });
                    navigate("/pocnUsers");
                }
            });
        
    }

    return (
        
        <Card>
            <CardContent>
        
                <Box sx={{ width: '100%' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <Tab value="profile" label="Profile" />
                        <Tab value="connections" label="Connections" />
                        <Tab value="videos" label="Videos" />
                        <Tab value="groups" label="Groups" />
                    </Tabs>
                    {value === 'profile' ?
                        <Tabs
                            value={tabName}
                            onChange={handleTabChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="profileinfo" label="Profile Information" />
                            <Tab value="bookmark" label="Bookmark" />
                            <Tab value="messages" label="Messages" />
                            <Tab value="notifications" label="Notifications" />
                            <Tab value="enghistory" label="Engagement History" />
                        </Tabs>
                        : null}
                    {( tabName === 'profileinfo' && value === 'profile') ?
                        <Box component="div">
                            <Grid container > 
                                <Grid item xs={1} mr={4} ml={2}>
                                
                                   <Box className="img-profile" >
                                {/* <img src="profileimg.jpg" /> */}
                                <img className="img-profile" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBgkIBwgKCgkLDRYPDQwMDRsUFRAWIB0iIiAdHx8kKDQsJCYxJx8fLT0tMTU3Ojo6Iys/RD84QzQ5OjcBCgoKDQwNGg8PGjclHyU3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3Nzc3N//AABEIAJEAkQMBIgACEQEDEQH/xAAbAAEAAwEBAQEAAAAAAAAAAAAABAUGAwIBB//EADUQAAICAQIDBAcHBQEAAAAAAAABAgMEBRESITEGQVFxEyIyUmGBwSMzQmKRobEUQ3LR8OH/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8A/cQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD5KSim5NJLq2RdQz6cGvise8n7MF1Zls3UL82Tdsmob8oLov8AYF9l69i0txpTumvd5L9Ssu17Mn92oVeS3/kqgBLlqmdLrk2Ly2QjqedF7/1VnzZEAFnVrubB+vKFi/NHb+CyxO0FFj2yIOl+K9ZGaAG7rshbFSrkpRfRp7nsxGJl34lnFRPh8Y9z80afTNUqzo8PsXLrBvr8UBYAAAAAAAAAAARNSzoYOO5y5zfKEfFkqTUYuUnslzbMZqWY83KlY9+BcoLwQHHIvsyLpW3S4py/Y5gAAAAB7rptt+6qsn/jFs+zx7q1vZTZFeLgwOYAAH2E5VzU65OMk9013HwAa3SNRjnVcMtldD2l4rxRYmGxcieNkQur9qL6eK70bXHuhfTC2t7xmt0B0AAAAAAABU9osr0OGqovaVr2+XeZcte0dvHnqHdXFL5vmVQAAAFzL7TdJhCKsy48U3zUH0XmQdDx1fm8UvZrXF8+40gBJJJJcl3Dz5oACtz9KqvTnRFV2rol0kZ6UZQk4zW0k9mvA2ZQ9oMdRtrvj/c5S80BUgAAaDsxktwsxpP2fWgvh3mfJujW+h1Kh+9Lgfz/AOQGxAAAAAAABjNXlxankP8ANt+xEJerLbUslP3yIAAAFz2ba4sjx2j9S7MvpOSsbMjxvaE/Vk/D4moAAAAVfaLb+kr8XYtv0ZaGe17JVuRGmL3VXXzArAAAOmPLhyKpLqpxa/U5nuhb31pdXNL9wN2AAAAAAADKdoquDUZT7rIqX0+hWGl7S4zsxYXx61Pn5MzQAAAC207V/QxVOVu4LlGa5tfAj4Ol35SU/u63+J9/ku8tFouL6Phbsc/f4vp0Am1ZFNyTquhLykerLqq1vZZCH+Ukils0KxP7O6DX5k0z5DQrW/XurS+CbA65+srhlXib7v8AuPu8ik8zRQ0XEVbjJ2Sk/wAfFtt8iuzdIuoTnU/S1rwXrL5AVwAAEvSKvTalRHwlxP5cyIXvZjH3lbkyXJepH6/QDQgAAAAAAA8W1xtrlXNbxktmjF5uNPEyp0z7vZfiu5m3K/V9PWdT6uyuhzhLx+AGRLjSNMViWRkx9X8EH3/FkTTsGV+b6K2LUa+dif8ABp+nJLbyAAAAAAAAAqdW0xWRlfjx+06yivxeXxKE2hndbxPQ5Csri+G3uXvf+gQKap32xqrW8pPZI2mHjxxcaumHSK6+L8SBoemvFh6a9fbSXJe4v9lsAAAAAAAAAAAHOVMHJySSm+r26nJpx6kk+NJrZgRgdZVe6zw4SXcB5A2fgNn4AAelCT7j2qveYHNLd7I6KmLcXOKbT3W/czoopdEfQGwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/9k=" />
                                {/* <AccountCircleIcon className="article-profile-icon"/> */}
                        </Box>
                                </Grid>
                                
                                <Grid item xs={9} ml={10} mt={3} >
                                    <Box><b>NPI : {npi}</b></Box>
                                            <Box mt={2}>
                                                <label className="pocn-text">#Followers : 500</label>
                                                <label className="pocn-text">#Connections : 240</label>
                                                <label className="pocn-text">#Profile Views : 1234</label>
                                    </Box>
                                    <Box mt={2}>
                                                <label className="pocn-text">#Comments on your Posts : 178</label>
                                                <label className="pocn-text">POCN Community Engagement Score : 6</label>
                                            </Box>
                                </Grid>
                            </Grid>

                        <Box ml={4} component="div">
                                <h4>User Information</h4>
                        </Box>
                            <Grid container >
                                
                                <Grid item xs={5} mb={2} mr={8} ml={4} mt={2}>
                                
                                    <TextField
                                    id="firstName"
                                    variant='outlined'
                                    fullWidth
                                    label='First Name'
                                    value={firstName}
                                    onChange={(e: any) => setFirstName(e.target.value)}
                                />
                                </Grid>
                                
                                <Grid item xs={5} mb={2} ml={4} mt={2} >
                                    <TextField
                                    id="suffix"
                                    variant='outlined'
                                    fullWidth
                                    label="Suffix"
                                    value={suffix}
                                    onChange={(e: any) => setSuffix(e.target.value)}
                                />
                                </Grid>
                            </Grid>
                            
                             <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                    id="middleName"
                                    variant='outlined'
                                    fullWidth
                                    label="Middle Name"
                                    value={middleName}
                                    onChange={(e: any) => setMiddleName(e.target.value)}
                                />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} mt={2}>
                                    {/* <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="female" control={<Radio />} label="Female" />
                                        <FormControlLabel value="male" control={<Radio />} label="Male" />
                                    </RadioGroup> */}
                                    <input
                                        type="radio"
                                        id="male"
                                        name="choose"
                                        value="male"
                                        checked={selectedRadio === 'male'}
                                        onChange={handleRadio}
                                    />
                                        <label htmlFor="male"  className='select-radio'>Male</label>
                                    <input
                                        type="radio"
                                        id="female"
                                        name="choose"
                                        value="female"
                                        onChange={handleRadio}
                                        checked={selectedRadio === 'female'}
                                    />
                                        <label htmlFor="female">Female</label>
                                </Grid>
                            </Grid>
                            
                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="lastName"
                                        variant='outlined'
                                        fullWidth
                                        label="Last Name"
                                        value={lastName}
                                        onChange={(e: any) => setLastName(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="age"
                                        variant='outlined'
                                        fullWidth
                                        label="Age"
                                        value={age}
                                        onChange={(e: any) => setAge(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            
                             <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="emailId"
                                        variant='outlined'
                                        fullWidth
                                        label="Email"
                                        value={emailId}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={true} 
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="npi"
                                        variant='outlined'
                                        fullWidth
                                        label="NPI#"
                                        value={npi}
                                        // onChange={(e: any) => setTitle(e.target.value)}
                                        disabled={true} 
                                    />
                                </Grid>
                            </Grid>
                                
                        <Box ml={4} component="div">
                            <h4>Contact Information</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="address"
                                        variant='outlined'
                                        fullWidth
                                        label="Address Line 1"
                                        value={address}
                                        onChange={(e: any) => setAddress(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="address"
                                        variant='outlined'
                                        fullWidth
                                        label="Address Line 2"
                                        value={address}
                                        onChange={(e: any) => setAddress(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="city"
                                        variant='outlined'
                                        fullWidth
                                        label="City"
                                        value={city}
                                        onChange={(e: any) => setCity(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="state"
                                        variant='outlined'
                                        fullWidth
                                        label="State"
                                        value={state}
                                        onChange={(e: any) => setState(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                          <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="zipCode"
                                        variant='outlined'
                                        fullWidth
                                        label="Zip Code"
                                        value={zipCode}
                                        onChange={(e: any) => setZipCode(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="timeZone"
                                        variant='outlined'
                                        fullWidth
                                        label="Time Zone"
                                        value={timeZone}
                                        onChange={(e: any) => setTimeZone(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="workPhone"
                                        variant='outlined'
                                        fullWidth
                                        label="Work Phone"
                                        value={workPhone}
                                        onChange={(e: any) => setWorkPhone(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="mobilePhone"
                                        variant='outlined'
                                        fullWidth
                                        label="Mobile Phone"
                                        value={mobilePhone}
                                        onChange={(e: any) => setMobilePhone(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {createContact()}
                                <Button className="section-add-button" onClick={() => { setAddContact({ val: [...addContact.val, '']}) }}><AddCircleIcon className="section-add-icon" /></Button>  

                            
                            <Box ml={4} mt={6} component="div">
                            <h4>Professional Profile</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Job Title"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Current Organization / Affiliation"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Box ml={4} mr={4} component="div">
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="About Me"
                                        multiline
                                        rows={4}
                                        value={bio}
                                        onChange={(e: any) => setBio(e.target.value)}
                                    />
                            </Box>
                            {createProf()}
                                <Button className="section-add-button" onClick={() => { setAddProf({ val: [...addProf.val, '']}) }}><AddCircleIcon className="section-add-icon" /></Button> 
                            
                           <Box ml={4} component="div">
                                <h4>Education Section</h4>
                            </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="College"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Degree"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={5} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Grafuation Year"
                                        value={graduationYear}
                                        onChange={(e: any) => setGraduationYear(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Specialty"
                                        value={specialty}
                                        onChange={(e: any) => setSpecialty(e.target.value)}
                                    />
                                </Grid>
                                </Grid>
                                {createEducation()}
                                <Button className="section-add-button" onClick={() => { setAddEducation({ val: [...addEducate.val, '']}) }}><AddCircleIcon className="section-add-icon" /></Button>                           
                            
                        <Box ml={4} mt={6} component="div">
                                <h4>Licenses / Certifications Section</h4>
                            </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Certification Name"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Specialty"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={5} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Institution Name"
                                        value={graduationYear}
                                        onChange={(e: any) => setGraduationYear(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            {createLicense()}
                            <Button className="section-add-button" onClick={() => { setAddLicen({ val: [...addLicen.val, '']}) }}><AddCircleIcon className="section-add-icon"/></Button>

                        <Box ml={4} mt={6} component="div">
                            <h4>Experience Section</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Employer"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Title"
                                        value={addictionMedicine}
                                        onChange={(e: any) => setAddictionMedicine(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>                                  
                                    <TextField
                                        id="date"
                                        label="Start Date"
                                        variant='outlined'
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="date"
                                        label="End Date"
                                        variant='outlined'
                                        fullWidth
                                        type="date"
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Location"
                                        value={degree}
                                        onChange={(e: any) => setDegree(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Box ml={4} mr={4} component="div">
                                    <TextField
                                        id="title"
                                        variant='outlined'
                                        fullWidth
                                        label="Experience Description"
                                        multiline
                                        rows={4}
                                        value={bio}
                                        onChange={(e: any) => setBio(e.target.value)}
                                    />
                            </Box>
                            {createExper()}
                            <Button className="section-add-button" onClick={() => { setAddExper({ val: [...addExper.val, '']}) }}><AddCircleIcon className="section-add-icon"/></Button>
                            
                            <Box ml={4} mt={6} component="div">
                            <h4>Social Profile</h4>
                        </Box>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="fbProfile"
                                        variant='outlined'
                                        fullWidth 
                                        label="Facebook"
                                        value={fbProfile}
                                        onChange={(e: any) => setFbProfile(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="twitterProfile"
                                        variant='outlined'
                                        fullWidth
                                        label="Twitter"
                                        value={twitterProfile}
                                        onChange={(e: any) => setTwitterProfile(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={5} mb={2} mr={8} ml={4}>
                                    <TextField
                                        id="linkedInProfile"
                                        variant='outlined'
                                        fullWidth
                                        label="LinkedIn"
                                        value={linkedInProfile}
                                        onChange={(e: any) => setLinkedInProfile(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={5} mb={2} ml={4} >
                                    <TextField
                                        id="webSite"
                                        variant='outlined'
                                        fullWidth
                                        label="Website Url"
                                        value={webSite}
                                        onChange={(e: any) => setWebSite(e.target.value)}
                                    />
                                </Grid>
                            </Grid>

                            {/* <Box ml={4} component="div">
                                <h4>Resume</h4>
                            </Box> */}
                            <Grid container >
                                <Grid item xs={1} mb={2} mt={2} ml={4} mr={4}>
                                    <h4>Resume</h4>
                                </Grid>
                                <Grid item xs={4} mb={2} mt={2}>
                                    <TextField type="file" />
                                </Grid>
                                </Grid>
                            

                        <Box>
                            <Grid container>
                                <Grid item xs={1} mt={2} mr={4} ml={4}>
                                    <Button variant="contained" color="primary" size="large" className="curate-cancel-right" onClick={handleCloseClick}>Back</Button>  
                                </Grid>
                                <Grid item xs={2} mt={2}>
                                    <Button variant="contained" color="primary" size="large" className="curate-update-right" onClick={handleCloseClick}>Update</Button>
                                </Grid>
                            </Grid>
                        </Box>
                     
                    </Box>  
                : null} 

                </Box>
            </CardContent>
        </Card>
    );
}
export default UserDetailPage;