import{useEffect} from "react";
import { List, Datagrid, TextField, EmailField, SearchInput, Filter, CreateButton, FunctionField, EditButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import checkAuthentication from './../common/Authenticate';
import { useNavigate } from 'react-router-dom';
import CustomDateField from '../common/CustomDateField';
import Button from '@mui/material/Button';
import { Card, CardContent, CircularProgress, Table, TableHead, TableRow, TableBody, TableCell, Box } from '@mui/material';


const PostFilter = (props: JSX.IntrinsicAttributes) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);

const ListActions = () => {
    const navigate = useNavigate();
    return(
    <Button variant="contained"  onClick={() => { navigate('/pocnNonProviders/create'); }} sx={{  mb: '10px'}}>
        New Non Providers
    </Button>
    )
}

const PocnVideoList = (props: any) => {
    const navigate = useNavigate()
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);
    return (
        // <List
        //     {...props}
        //     actions={<ListActions />}
        //     title={'POCN Users'}
        //     filters={<PostFilter />}
        //     perPage={50}
        //     pagination={false}
        //     sort={{ field: 'npi', order: 'DESC' }}
        //     exporter={false}
        // >
        //     <Datagrid bulkActionButtons={false} rowClick="edit">
    
        //         <TextField source="firstName" label="First Name" />
        //         <TextField source="lastName" label="Last Name"/>
        //         {/* <TextField source="providerId" label="Provider Id" />
        //         <TextField source="npi" label="NPI Number" />
        //         <TextField source="userId" label="POCN User Id" /> */}
        //         <EmailField source="emailId" />
        //         {/* <TextField source="type" label="Type" /> */}
        //         <CustomDateField source="createdAt" label="Registered Date" sortBy='createdAt' />

        //     </Datagrid>
        // </List>
<Card>
        <Box mb={2}>
                        <Table sx={{ padding: 2 }} size="small">
                            <TableHead className="header-curation">
                                <TableRow>
                                    <TableCell><b>First Name</b></TableCell>
                            <TableCell><b>Last Name</b></TableCell>
                            <TableCell><b>Provider Id</b></TableCell>
                            <TableCell><b>NPI Number</b></TableCell>
                            <TableCell><b>POCN User Id</b></TableCell>
                            <TableCell><b>Email Id</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Registered Date</b></TableCell>
                                </TableRow>
                            </TableHead>
                           
                            <TableBody>
                                {/* {content.map(e => ( */}
                                <TableRow onClick={() => { navigate('/pocnVideos_all/create'); }}>
                                    <TableCell>CAROL</TableCell>
                            <TableCell>LEVI</TableCell>
                            <TableCell>123456</TableCell>
                            <TableCell>9874</TableCell>
                            <TableCell>852</TableCell>
                            <TableCell>carol@gmail.com</TableCell>
                            <TableCell>Video</TableCell>
                            <TableCell>2 days ago</TableCell>
                                </TableRow>
                                {/* ))} */}
                            </TableBody>
                            
                        </Table>
            </Box>
            </Card>
    )
};



export {
    PocnVideoList,
}