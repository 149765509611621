import {  useEffect } from "react";
import { List, Datagrid, TextField, SearchInput, Filter, EditButton, CreateButton, DeleteButton } from 'react-admin';
import { DeleteActions } from '../common/DeleteField';
import checkAuthentication from './../common/Authenticate'
import { useNavigate } from 'react-router-dom';

const PostFilter = (props: JSX.IntrinsicAttributes) => (
  <Filter {...props}>
    <SearchInput source='name' alwaysOn />
  </Filter>
);

export interface SimpleDialogProps {
    open: boolean;
    selectedValue: string;
    onClose: (value: string) => void;
} 
const ListActions = () => (
    <CreateButton label="Add Media Type"/>
);
const EditActions = () => (
    <EditButton label="" style={{ float: 'right' }}/>
);

const ContentList = (props: any) => {
    const navigate = useNavigate();
    useEffect(() => {        
        const auth = checkAuthentication();
        if (auth === false) {
            navigate("/")
        }
    },[]);
    return(
        <List
            {...props}
            title={'Media Types'}
            actions={<ListActions />}
            filters={<PostFilter />}
            perPage={50}
            pagination={false}
            sort={{ field: 'title', order: 'DESC' }}
            exporter={false}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="title" label="Media Type" />
                <EditActions />
                <DeleteActions />
            </Datagrid>
        </List>
    )
};

export {

    ContentList,
}