import { Admin, Resource, CustomRoutes,Authenticated } from 'react-admin';
import { Login, Layout } from './layout';
import users from './users';
import pocnUsers from './pocnUsers';
import pocnNonProvider from './pocnNonProvider';
import { useState, useEffect } from 'react';
import authProvider from './layout/authProvider';
import dataprovider from './dataProvider/DataProvider';
import { Route } from 'react-router';
import types from './types';
import COE from './coeSite';
import Curation from './Curation';
import Speciality from './speciality';
import TherapeuticArea from './therapeuticArea';
import coeLocation from './coeLocation';
import contentType from './contentType';
import pocnVideos from './pocnVideos';
import { Curate } from './Curation/Curate';
import { CuratedContentList, ContentList } from './Curation/CuratedContentList';
import dataProvider from './dataProvider/DataProvider';
import {Dashboard} from './layout/Dashboard';
import ViewArticle from './Curation/ViewArticle';
import UpdateCuratedArticle from './Curation/updateCuration';
import { Profile } from './layout/Profile';
import UserDetailPage from './pocnUsers/UserDetailPage';


const App = () => {
  const [resourceNames, setResourceName] = useState([]);
  useEffect(() => {
    // let subMenu = ['all', 'submitted', 'approved', 'rejected', 'standby', 'published'];
    let subMenu = ['all'];
    dataProvider.getMany('status', { ids: [] }).then((result: any) => {
    if (result.data) {
      result.data.map((statusData: any) => {
        subMenu.push(statusData.status)
      })
   
    dataProvider.getMany('coeSites', {ids:[]}).then((response: any) => {
      if (response?.data) {       
        let userType: string = JSON.parse(localStorage.getItem('auth'))?.userTypeId;
        if (userType === process.env.REACT_APP_KOL_USER || userType === process.env.REACT_APP_CURATOR) {
            let resources = [];
            response.data.map((coesite: { name: string; id: number }, index: any) => {
                if ((JSON.parse(localStorage.getItem('auth')).coeSites).includes(coesite.id)) {
                  let name = '/curated/' + coesite.name.replace(" ", '');
                  for (const menu of subMenu) {
                    let menuItem = name + '/' + menu;
                    resources.push(menuItem);
                  }
                }
            })
            setResourceName(resources);
        } else {
          let resources = [];
          response.data.map((data: any) => {
            let name = '/curated/' + data.name.replace(" ", '');
            for (const menu of subMenu) {
              let menuItem = name + '/' + menu;
              resources.push(menuItem)
            }
          })
          setResourceName(resources);
        }
      }
    });
       } //status data checking end
    });

  }, []);



  return (<Admin
    title="POCN Admin App"
    dashboard={Dashboard}
    dataProvider={dataprovider}
    layout={Layout}
    authProvider={authProvider}
    loginPage={Login}
  >

    <CustomRoutes>
      <Route path='/curation' element={<Authenticated><Curate /></Authenticated>} key={`route-curation`} />,
      <Route path='/view/:id' element={<Authenticated><ViewArticle /></Authenticated>} key={`route-curation-view`} />
      <Route path='/pocnUser/:id' element={<Authenticated><UserDetailPage /></Authenticated>} key={`route-pocn-user`} />
      <Route path='/updateCuration/:id' element={<Authenticated><UpdateCuratedArticle /></Authenticated>} key={`route-curation-edit`} />
      <Route path='/configuration' element={<Authenticated><Profile /></Authenticated>} key={`route-profile`} />
    </CustomRoutes>
    {resourceNames.map((data: string) => {
      const curation_filters = data.split('/');
      if (curation_filters[3] === 'all') {
        return (<Resource name={data} options={{ label: curation_filters[2] }} list={CuratedContentList} key={`resource-${data}`} />)
      } else {
        return (<Resource name={data} options={{ label: curation_filters[2] }} list={ContentList} key={`resource-${data}`} />)
      }

    })
    }

    <Resource name="users" {...users} key="resource-users" />
    <Resource name="pocnUsers" {...pocnUsers} key="resource-pocn_providers" />
    <Resource name="pocnNonProviders" {...pocnNonProvider} key="resource-pocn_non_providers" />
    <Resource name="types" {...types} key="resource-types" />
    <Resource name="coeSites" {...COE} key="resource-coeSites" />
    <Resource name="curation" {...Curation} key="resource-curation" />
    <Resource name="speciality" {...Speciality} key="resource-speciality" />
    <Resource name="therapeutic_area" {...TherapeuticArea} key="resource-therapeutic_area" />
    <Resource name="coeSitesLocation" {...coeLocation} options={{ label: 'CoE Site Location' }} key="resource-coeSitesLocation" />
    <Resource name="mediaTypes" {...contentType} key="resource-mediaTypes" />
    <Resource name="pocnVideos_all" {...pocnVideos} key="resource-pocn_Videos-all" />

  </Admin>)
}

export default App;