
import { DataProvider } from "react-admin";
import graphqlProvider from './GraphqlProvider';
import getJwtToken from '../services/getToken';


const dataProvider = {
  getList: (resource: string, params: any) => {
    getJwtToken();
    return graphqlProvider.getList(resource, params);
  },
  getOne: (resource: string, params: any) => {
    return graphqlProvider.getOne(resource, params);
  },
  getMany: (resource: string, params: any) => {    
    return graphqlProvider.getMany(resource, params);
  },
  getManyReference: (resource: string, params: any) => Promise.resolve({ data: [{ id: 1, toto: "tata" }] }),
  create: (resource: string, params: any) => {
    return graphqlProvider.create(resource, params);
  },
  update: (resource: string, params: any) => {
    return graphqlProvider.update(resource, params);
  },
  updateMany: (resource: string, params: any) => Promise.resolve({ data: [1] }),
  delete: (resource: string, params: any) => {
    return graphqlProvider.delete(resource, params);
  },
  deleteMany: (resource: string, params: any) => Promise.resolve({ data: [1] }),
} as DataProvider;
export default dataProvider;
