import React from 'react';
import PropTypes from 'prop-types';
import { useRecordContext } from 'react-admin';
const moment = require('moment');

const CustomDateField = ({ source, utc = false }) => {
     const record = useRecordContext();
    return (
        <span>
            {utc ? moment(moment(record[source], "YYYY-MM-DD HH:mm Z").local().format()).fromNow() :
                moment(moment.utc(record[source]).local().format()).fromNow()}
        </span>
    );
}

CustomDateField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  sortBy: PropTypes.string,
};

export default CustomDateField;
