import UserIcon from '@mui/icons-material/People';
import { SpcialtyList } from './SpcialtyList';
import { ListCreate, ListEdit } from './specialtyCreate'

const resource = {
    list: SpcialtyList,
    create: ListCreate,
    edit: ListEdit,
    icon: UserIcon,
};

export default resource;
