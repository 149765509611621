import * as React from 'react';

const permission = {
    "LOGGED_IN_USER_ROLE": JSON.parse(localStorage.getItem('auth'))?.userTypeId,
    "LOGGED_IN_USER_ID" : JSON.parse(localStorage.getItem('auth'))?.userId,
    "ADMIN_ROLE" : process.env.REACT_APP_MICROSITE_ADMIN,
    "CURATOR_ROLE": process.env.REACT_APP_CURATOR,
    "KOL_USER_ROLE": process.env.REACT_APP_KOL_USER,
    "POCN_ADMIN_ROLE": process.env.REACT_APP_POCN_ADMIN,
    "SUPER_ADMIN_ROLE": process.env.REACT_APP_SUPER_ADMIN,
    "CONTENT_ADMIN_ROLE": process.env.REACT_APP_CONTENT_ADMINISTRATOR,
    "COE_SITES": JSON.parse(localStorage.getItem('auth'))?.coeSites,
    "CURATION_DEFAULT_STATUS": process.env.REACT_APP_CURATION_DEFAULT_STATUS,
    "CURATION_APPROVED_STATUS": process.env.REACT_APP_CURATION_APPROVED_STATUS,
    "SELECTED_VALUE": process.env.REACT_APP_SELECTED_VALUE
    
};
export default permission;


